const AdminServices = {
    fetchAdmin: () => fetchAdmin(),
    createAdmin: (email, data) => createAdmin(email, data),
    modifyAdmin: (email, data) => modifyAdmin(email, data),
    deleteAdmin: (email) => deleteAdmin(email)
}
export default AdminServices
const API_PREFIX = '/yanjing/api';
const generalOption = (action, data) => {
    return {
        method: action,
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(data)
    }
};
const fetchAdmin = () => {
    let requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    return fetch(API_PREFIX + '/admin/', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return  response.json()
        })
}
const createAdmin = (email, data) => {
    let requestOptions = generalOption('POST', data);
    return fetch(API_PREFIX + '/admin/' + email, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
        })
}

const modifyAdmin = (email, data) => {
    let requestOptions = generalOption('PUT', data);
    return fetch(API_PREFIX + '/admin/' + email, requestOptions)
        .then(response => {
            if(!response.ok) {
                return Promise.reject(response);
            }
        })
}

const deleteAdmin = (email) => {
    let requestOptions = {
        method: 'PUT',
        credentials: 'include'
    }
    return fetch(API_PREFIX + '/admin/delete/' + email, requestOptions)
        .then(response => {
            if(!response.ok) {
                return Promise.reject(response);
            }
        })
}
