import actions from '../../constant/actions'

const initialTableState = {
    data: []
}

const custom_table = (state = initialTableState, action) => {
    switch (action.type) {
        case actions.UPDATE_CUSTOM_POI:
            return {
                ...state,
                data: action.payload
            }
        default:
            return state;
    }
}

export default custom_table
