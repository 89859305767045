import { connect } from 'react-redux'
import { createBuilding, modifyBuilding, deleteBuilding } from '../../../actions/POI'
import BuildingTable from '../../../components/POI/Building/BuildingTable'

const mapStateToProps = (state) => {
    const _countryOption = [
        {
            text: '台灣',
            value: 'TW'
        }
    ]
    const _data = []

    state.info.building_data.map((building, index) => {
        let tokens = building.location_id.split(" ")
        let data = {
            id: building.id,
            name: building.name,
            address: building.address,
            lat: building.lat,
            lng: building.lng,
            price: building.price,
            country: tokens[0],
            city: tokens[1],
            district: tokens[2],
            detail: building.address.split(" ")[3] // The last token of the address
        }
        _data.push(data)
        return true
    })

    return {
        data: _data,
        // Used To Filter Out The District
        location: state.info.location_data,
        countryOption: _countryOption,
    }
}

const mapDispatchToProps = (dispatch) => ({
    createBuilding: (data) => {
        dispatch(createBuilding(data))
    },
    modifyBuilding: (id, data) => {
        dispatch(modifyBuilding(id, data))
    },
    deleteBuilding: (id) => {
        dispatch(deleteBuilding(id))
    }
})


export const BuildingTableContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BuildingTable)

export default BuildingTableContainer