import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducer from './reducers';
import { adminActions } from './actions/App'

const loggerMiddleware = createLogger();



// Check for token expiry and refresh token if needed
function jwtMiddleware({ dispatch, getState }) {
    return next => action => {
        if (typeof action === 'function') {
            const tokenExpiryUTC = getState().admin.tokenExpiry
            const currentTimeUTC = Math.floor(Date.now() / 1000);
            if (tokenExpiryUTC - currentTimeUTC < 30) {
                // Check if currently is requesting token and wait for new access token before processing the next request
                if (!getState().admin.isFetchingToken) {
                    const requestingToken = async () => {
                        await adminActions.requestToken(dispatch);
                    }
                    requestingToken().then(() => next(action))
                } else {
                    return getState().admin.tokenPromise.then(() => next(action))
                }
            } else {
                return next(action)
            }
        } else {
            return next(action)
        }
    }
}

export const store = createStore(
    reducer,
    applyMiddleware(
        jwtMiddleware,
        thunkMiddleware,
        loggerMiddleware
    )
);

