import moment from 'moment'
import { time, report_type, targeting_type_header_accessor, time_header_accessor } from '../constant/filters'


const plus_statistic = (result, data) => {
    result['Android']['behavior']['stay_time'] += data['Android']['behavior']['stay_time']
    result['Android']['behavior']['frequency'] += data['Android']['behavior']['frequency']
    result['Android']['behavior']['bounce_rate'] += data['Android']['behavior']['bounce_rate']
    result['Android']['visitors']['new'] += data['Android']['visitors']['new']
    result['Android']['visitors']['return'] += data['Android']['visitors']['return']
    result['Android']['visitors']['total'] += data['Android']['visitors']['total']
    result['iOS']['behavior']['stay_time'] += data['iOS']['behavior']['stay_time']
    result['iOS']['behavior']['frequency'] += data['iOS']['behavior']['frequency']
    result['iOS']['behavior']['bounce_rate'] += data['iOS']['behavior']['bounce_rate']
    result['iOS']['visitors']['new'] += data['iOS']['visitors']['new']
    result['iOS']['visitors']['return'] += data['iOS']['visitors']['return']
    result['iOS']['visitors']['total'] += data['iOS']['visitors']['total']
    return result
}
const timeToDivider = {}
timeToDivider[time.DATE] = {1:1,7:1,30:1,90:1,180:1,365:1}
timeToDivider[time.HOUR] = {1:1,7:7,30:30,90:90,180:180,365:365}
timeToDivider[time.DAY] = {1:1,7:1,30:1,90:3,180:6,365:12}
timeToDivider[time.WEEK] = {1:1,7:1,30:4,90:12,180:25,365:52}
timeToDivider[time.MONTH] = {1:1,7:1,30:1,90:1,180:1,365:1}

export const prettyNumber = (number) => {
    const num = Number(number);
    // 沒小數點(整數): 每三位數","
    // 有小數點: 留後兩位, 每三位數","
    const tmp = num % 1 === 0 ? num.toString() : num.toFixed(2);
    return tmp.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default class DataBuilder {
    constructor({targetingType, time, days, specificTarget , device, distance, locationTable, rawData}) {
        this.data = rawData
        this.targetingType = targetingType
        this.time = time
        this.specificTarget = specificTarget
        this.device = device
        this.distance = distance
        this.locationTable = locationTable
        if (days in timeToDivider[time]) {
            this.divider = timeToDivider[time][days]
        } else { // Year to Date
            switch (time) {
                case time.HOUR:
                    this.divider = days
                    break;
                case time.DAY:
                    this.divider = days/30
                    break;
                case time.WEEK:
                    this.divider = days/7
                    break;
                case time.DATE:
                case time.MONTH:
                default:
                    this.divider = 1;
                    break;
                }
        }
        if (targetingType !== undefined) {
            this.target_accessor = targeting_type_header_accessor[this.targetingType].accessor
        }
        if (time !== undefined)
            this.time_accessor = time_header_accessor[this.time].accessor

    }
    groupByTime({data, momentKeyword = undefined}) {
        data = JSON.parse(JSON.stringify(data))
        const result = {}
        if (momentKeyword !== undefined) {       // Merged by momnet keyword
            for (let datetime in data) {
                let time_data = data[datetime]
                const time = moment(datetime).format(momentKeyword)
                if (result[time] === undefined) {   // initialize
                    result[time] = { ...time_data }
                } else {
                    for (let target in data[datetime]) {
                        if (target in result[time]) {
                            plus_statistic(result[time][target], data[datetime][target])
                        } else {
                            result[time][target] = data[datetime][target]
                        }
                    }
                }
            }
        } else {    // Merged into one category `all`
            result['all'] = {}
            for (let datetime in data) {
                for (let target in data[datetime]) {
                    if (target in result['all']) {
                        plus_statistic(result['all'][target], data[datetime][target])
                    } else {
                        result['all'][target] = data[datetime][target]
                    }
                }
            }
        }
        return result
    }
    groupByTarget({data, targetingType = undefined, reportType = undefined}) {
        data = JSON.parse(JSON.stringify(data))
        const result = {}
        switch(reportType) {
            case report_type.DEVICE_TIME: // 產生 Device/Time report ，在相同時間下，不考慮 targetingType，將所有 targetingType 放到 all attribute 中
                for (let time in data) {
                    const geo_data = data[time]
                    let temp_data = {}
                    for (let key in geo_data) {
                        if (temp_data['all'] === undefined) {
                            temp_data['all'] = {
                                Android: { ...geo_data[key]['Android']['behavior'], ...geo_data[key]['Android']['visitors'] },
                                iOS: { ...geo_data[key]['iOS']['behavior'], ...geo_data[key]['iOS']['visitors'] },
                            }
                        }
                        else {
                            const android =  { ...geo_data[key]['Android']['behavior'], ...geo_data[key]['Android']['visitors'] }
                            const ios = { ...geo_data[key]['iOS']['behavior'], ...geo_data[key]['iOS']['visitors'] }
                            for (let attr in android) {
                                temp_data['all']['Android'][attr] += android[attr]
                                temp_data['all']['iOS'][attr] += ios[attr]
                            }
                        }
                        result[time] = temp_data
                    }
                }
                break;
            case report_type.GEO_TIME: // 產生 Geo/Time report ，在相同時間下，依照參數 tagetingType 進行合併
                for (let time in data) {
                    const geo_data = data[time]
                    let temp_data = {}
                    for (let key in geo_data) { // key => [TW 21 9]
                        const geo = key.substr(1, key.length-2).split(' ')[targetingType]
                        if (temp_data[geo] === undefined) {
                            temp_data[geo] = {
                                Android: { ...geo_data[key]['Android']['behavior'], ...geo_data[key]['Android']['visitors'] },
                                iOS: { ...geo_data[key]['iOS']['behavior'], ...geo_data[key]['iOS']['visitors'] },
                            }
                        } else {
                            const android =  { ...geo_data[key]['Android']['behavior'], ...geo_data[key]['Android']['visitors'] }
                            const ios = { ...geo_data[key]['iOS']['behavior'], ...geo_data[key]['iOS']['visitors'] }
                            for (let attr in android) {
                                temp_data[geo]['Android'][attr] += android[attr]
                                temp_data[geo]['iOS'][attr] += ios[attr]
                            }
                        }
                    }
                    result[time] = temp_data
                }
                break;
            case report_type.DEVICE_GEO: // 產生 Device/Geo report ，只有一個 global 時間 `all`，依參數 targetingType 進行合併(未用到)
                const geo_data = data['all']
                let temp_data = {}
                for (let key in geo_data) {
                    if (key === 'divider') continue
                    const geo = key.substr(1, key.length-2).split(' ')[targetingType]
                    if (temp_data[geo] === undefined) {
                        temp_data[geo] = {
                            Android: { ...geo_data[key]['Android']['behavior'], ...geo_data[key]['Android']['visitors'] },
                            iOS: { ...geo_data[key]['iOS']['behavior'], ...geo_data[key]['iOS']['visitors'] },
                            divider: 1
                        }
                    } else {
                        const android =  { ...geo_data[key]['Android']['behavior'], ...geo_data[key]['Android']['visitors'] }
                        const ios = { ...geo_data[key]['iOS']['behavior'], ...geo_data[key]['iOS']['visitors'] }
                        for (let attr in android) {
                            temp_data[geo]['Android'][attr] += android[attr]
                            temp_data[geo]['iOS'][attr] += ios[attr]
                        }
                        temp_data[geo]['divider'] += 1
                    }
                }
                result['all'] = temp_data
                break
            case report_type.POI_TIME:  // 產生 POI/TIME report
                for (let time in data) {
                    let poi_data = data[time]
                    let temp_data = {}
                    for (let poi in poi_data) {
                        if (temp_data[poi] === undefined) {
                            temp_data[poi] = {
                                Android: { ...poi_data[poi]['Android']['behavior'], ...poi_data[poi]['Android']['visitors'] },
                                iOS: { ...poi_data[poi]['iOS']['behavior'], ...poi_data[poi]['iOS']['visitors'] },
                            }
                        } else {
                            const android =  { ...poi_data[poi]['Android']['behavior'], ...poi_data[poi]['Android']['visitors'] }
                            const ios = { ...poi_data[poi]['iOS']['behavior'], ...poi_data[poi]['iOS']['visitors'] }
                            for (let attr in android) {
                                temp_data[poi]['Android'][attr] += android[attr]
                                temp_data[poi]['iOS'][attr] += ios[attr]
                            }
                        }
                    }
                    result[time] = temp_data
                }
                break
            default:
                console.log(reportType)
                break
        }
        return result
    }
    filterBySpecificTarget({data, specificTarget}) {
        if (specificTarget.length === 0) return data
        const result = {}
        for (let time in data) {
            const target_data = data[time]
            let temp_data = {}
            for (let key in target_data) {
                if (specificTarget.includes(key)) {
                    temp_data[key] = target_data[key]
                }
            }
            result[time] = temp_data
        }
        return result
    }
    filterByDevice({data}) {
        const result = {}
        for (let time in data) {
            const target_data = data[time]
            let temp_data = {}
            for (let key in target_data) {
                temp_data[key] = {}
                const android = target_data[key]['Android']
                const ios = target_data[key]['iOS']
                const divider = target_data[key]['divider']
                temp_data[key]['divider'] = divider
                temp_data[key]['Android'] = android
                temp_data[key]['iOS'] = ios
                temp_data[key]['All'] = {
                        bounce_rate: android['bounce_rate'] + ios['bounce_rate'],
                        frequency: android['frequency'] + ios['frequency'],
                        stay_time: android['stay_time'] + ios['stay_time'],
                        new: android['new'] + ios['new'],
                        return: android['return'] + ios['return'],
                        total: android['total'] + ios['total']
                }
            }
            result[time] = temp_data
        }
        return result
    }
}
