// This is the reducer supporting the Components `Filter`
import actions from '../../constant/actions'
import { targeting_type, date_range, device, time, distance } from '../../constant/filters'
import moment from 'moment'

// Generate StartDate and EndDate by the filter
const generateDate = (filter) => {
    let startDate;
    let endDate;
    let days;
    switch (filter) {
        case date_range.YESTERDAY:
            startDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
            endDate = startDate
            days = 1;
            break;
        case date_range.LASTWEEK:
        default:
            // startDate = moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')
            // endDate = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
            startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
            endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
            days = 7;
            break;
        case date_range.LAST30DAYS:
            startDate = moment().subtract(31, 'days').format('YYYY-MM-DD');
            endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
            days = 31;
            break;
        case date_range.LASTMONTH:
            // startDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
            // endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
            startDate = moment().subtract(31, 'days').format('YYYY-MM-DD');
            endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
            days = 31;
            break;
        case date_range.LAST3MONTHS:
            // startDate = moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD')
            // endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
            startDate = moment().subtract(90, 'days').format('YYYY-MM-DD');
            endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
            days = 90;
            break;
        case date_range.LAST6MONTHS:
            // startDate = moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD')
            // endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
            startDate = moment().subtract(180, 'days').format('YYYY-MM-DD');
            endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
            days = 180;
            break;
        case date_range.YEARTODATE:
            startDate = moment().startOf('year').format('YYYY-MM-DD')
            endDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
            days = moment().format('DDD')
            break;
        case date_range.LASTYEAR:
            // startDate = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD')
            // endDate = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD')
            startDate = moment().subtract(365, 'days').format('YYYY-MM-DD');
            endDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
            days = 365;
            break;
    }
    return {
        startDate: startDate,
        endDate: endDate,
        days: days
    }
}


// Initiallize the filtering data
const result = generateDate(date_range.LASTMONTH)

const initialAdvancedFilter = {
    specificTarget: [],
    distance: distance.CLEAR,
    time: time.DATE,
    device: device.ALL,
}

const initialFilter = {
    dateRange: undefined,
    startDate: result.startDate,
    endDate: result.endDate,
    days: result.days,
    isSimilarBrandOpen: false,
    targetingType: targeting_type.CITY,
    advancedFilter: initialAdvancedFilter,
    apikey: '',
    geoList: [],
    businessList: [],
    selected_brand: '',
    selected_category: ''
};

const filter = (state = initialFilter, action) => {
    let result
    switch (action.type) {
        case actions.ADMIN_LOGIN_SUCCESS:
            return {
                ...state,
                apikey: action.user.apikey
            };
        case actions.FILTER_DATE_RANGE:
            result = generateDate(action.filter)
            return {
                ...state,
                startDate: result.startDate,
                endDate: result.endDate,
                days: result.days,
                dateRange: action.filter
            };
        case actions.TOGGLE_SIMILAR_BRAND:
            return {
                ...state,
                isSimilarBrandOpen: !state.isSimilarBrandOpen
            }
        case actions.SET_ADVANCED_FILTER:
            return {
                ...state,
                advancedFilter: action.filter
            }
        case actions.SET_FILTER_API_KEY:
            return {
                ...state,
                apikey: action.filter
            }
        case actions.SWITCH_TARGETING_TYPE:
            return {
                ...state,
                targetingType: action.filter,
                advancedFilter: {
                    specificTarget: [],
                    distance: distance.CLEAR,
                    time: time.DATE,
                    device: device.ALL,
                }
            }
        case actions.RESET_FILTER:
            result = generateDate(date_range.LASTWEEK)
            return {
                ...state,
                ...initialFilter,
                apikey: action.filter
            }
        case actions.UPDATE_GEO_LIST:
            return {
                ...state,
                geoList: action.payload
            }
        case actions.UPDATE_BUSINESS_LIST:
            return {
                ...state,
                businessList: action.payload
            }
        case actions.UPDATE_TARGETING_POI_SELECTED_BRAND:
            return {
                ...state,
                selected_brand: action.filter
            }
        case actions.UPDATE_TARGETING_POI_SELECTED_CATEGORY:
            return {
                ...state,
                selected_category: action.filter
            }
        default:
            return state;
    }
}

export default filter
