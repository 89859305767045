import actions from '../constant/actions';

const initialState = {
    isModalOpen: false,
    isPickOpen: false,
    selectIndex: undefined,
    selectBusiness: {
        name: '',
        brand_id: '',
        address: '',
        website: '',
        phone: '',
    },
    poi_list: [],
    original_list: []
};

export function business(state = initialState, action) {
    switch (action.type) {
        case actions.BUSINESS_SELECT:
            return {
                ...state,
                selectIndex: action.index,
                selectBusiness: action.payload
            }
        case actions.BUSINESS_MODAL_OPEN:
            return {
                ...state,
                isModalOpen: true
            }
        case actions.BUSINESS_MODAL_CLOSE:
            return {
                ...state,
                isModalOpen: false
            }
        case actions.BUSINESS_TOGGLE_PICKPOI:
            return {
                ...state,
                isPickOpen: !state.isPickOpen
            }
        case actions.BUSINESS_MODIFY_PICKPOI:
            return {
                ...state,
                poi_list: action.payload
            }
        case actions.BUSINESS_UPDATE_PICKPOI:
            return {
                ...state,
                original_list: state.poi_list,
                poi_list: action.payload
            }
        default:
            return state;
    }
}

export default business;
