import React, { Component } from 'react'
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { Button, Modal, Form, Header, Input, Dropdown, Label } from 'semantic-ui-react';
import "semantic-ui-css/semantic.min.css"
import _ from 'lodash'
import Pagination from "../../Pagination";
import "../../../public/styles/Pagination.css"
import POIBtnGroup from '../POIBtnGroup';


class BrandTable extends Component {
    constructor(props) {
        super(props)

        this.selectedBrand = {}

        this.isModalOpen = false;
        this.state = {
            category_ids: [],
            description: '',
            name: '',
        }

        // The Data Manipulation Function
        this.checkAction = this.checkAction.bind(this)
        this.Create = this.Create.bind(this)
        this.Modify = this.Modify.bind(this)
        this.Delete = this.Delete.bind(this)

        // The Handling Function
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
    }

    checkAction(action) {
        switch (action) {
            case 'Create':
                this.Create();
                break;
            case 'Modify':
                this.Modify();
                break;
            case 'Delete':
                this.Delete();
                break;
            default:
                return
        }
    }
    Create() {
        this.selectedBrand = {}
        this.toggleModal();
    }
    Modify() {
        if (this.selectedBrand.id == null) {    // Check Selected
            return
        }
        this.toggleModal();
        const { category_ids, description, name } = this.selectedBrand
        this.setState({
            category_ids, description, name
        })
    }
    Delete() {
        if (this.selectedBrand.id == null) {    // Check Selected
            return
        }
        this.props.deleteBrand(this.selectedBrand.id)
        this.selectedBrand = {}
    }
    toggleModal() {
        this.isModalOpen = !this.isModalOpen
        this.forceUpdate()
    }
    handleDropdownChange(data) {
        const { name, value } = data;
        this.setState({ [name]: value });
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    handleSubmit(e) {
        e.preventDefault();
        let category_ids = this.state.category_ids;
        let category_str = '';

        if (this.state.name === '' || category_ids.length === 0) {
            return;
        }
        for (let index in category_ids) {
            category_str += category_ids[index] + ' '
        }
        this.setState({ category_ids: category_str.slice(0, -1) }, () => {
            if (this.selectedBrand.id == null)  // Create
                this.props.createBrand(this.state)
            else    // Modify
                this.props.modifyBrand(this.selectedBrand.id, this.state)
            this.handleClose()
        })
    }
    handleClose() {
        this.state = {
            category_ids: [],
            description: '',
            name: ''
        }
        this.toggleModal()
    }
    toggleTableClick = (state, rowInfo) => {
        if (rowInfo !== undefined) {
            if (rowInfo.index === this.selectedBrand.index) {
                return {
                    onClick: () => {
                        this.selectedBrand = {};
                        this.forceUpdate()
                    }, style: {
                        background: '#EFEFEF'
                    }
                }
            } else {
                return {
                    onClick: () => {
                        this.selectedBrand = this.props.data[rowInfo.index];
                        this.selectedBrand.index = rowInfo.index
                        this.forceUpdate()
                    }, style: {
                        background: 'white'
                    }
                }
            }
        } else {
            return {}
        }
    }
    render() {
        const { data, categoryOption } = this.props
        // data manipulation btn group style
        let dataManipulation_btnGroup = this.selectedBrand.id == null ? false : true;

        return (
            <div style={{ marginRight: "51px" }}>
                <POIBtnGroup haveSelected={dataManipulation_btnGroup} checkAction={this.checkAction} />
                <Modal
                    open={this.isModalOpen}
                    onClose={this.handleClose}
                    size='large'
                >
                    <Modal.Content>
                        <Form>
                            <Header size='large'>Brand</Header>
                            <Form.Field>
                                <Input placeholder='Brand Name*' type='text' name='name' defaultValue={this.selectedBrand.name || ''} onChange={this.handleChange} /><br />
                            </Form.Field>
                            <Form.Field>
                                <Input placeholder='Brand description' type='text' defaultValue={this.selectedBrand.description || ''} name='description' onChange={this.handleChange} /><br />
                            </Form.Field>
                            <Header size='large'>Category</Header>
                            <Form.Field>
                                <Label>Category*</Label>
                                <Dropdown
                                    placeholder='Select a Category'
                                    multiple search selection
                                    options={categoryOption}
                                    name="category_ids"
                                    defaultValue={this.selectedBrand.category_ids || ''}
                                    onChange={(event, data) => this.handleDropdownChange(data)} />
                            </Form.Field>
                            <Button type="button" onClick={this.toggleModal}>Close</Button>
                            <Button primary type="button" onClick={this.handleSubmit} >Save</Button>
                        </Form>
                    </Modal.Content>
                </Modal>
                <ReactTable
                    getTrProps={this.toggleTableClick}
                    PaginationComponent={Pagination}
                    data={data}
                    filterable
                    columns={[
                        {
                            Header: "Brand Name",
                            accessor: "name",
                            filterMethod: (filter, row) =>
                                _.includes(String(row[filter.id]).toLowerCase(), filter.value.toLowerCase())
                        },
                        {
                            Header: "Category",
                            accessor: "category",
                            filterMethod: (filter, row) =>
                                _.includes(String(row[filter.id]).toLowerCase(), filter.value.toLowerCase())
                        }
                    ]}
                    defaultPageSize={25}
                    className="-highlight"
                />
            </div>
        )
    }
}
export default BrandTable
