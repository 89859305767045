// This is the Reducer Combiner

import { combineReducers } from 'redux'
import info from './POI/info'
import filter from './Report/filter'
import custom_table from './Report/custom'
import targeting_table from './Report/targeting_table'
import report_overview from './Report/overview'
import app from './App'
import admin from './admin'
import business from './business'
import data from './data'

const reducer = combineReducers({
    info,
    filter,
    custom_table,
    targeting_table,
    report_overview,
    app,
    admin,
    business,
    data
})

export default reducer
