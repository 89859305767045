import DataBuilder from './DataBuilder'
import { time, report_type } from '../constant/filters'
import moment from 'moment'

const timeToMomentKeyword = {}
timeToMomentKeyword[time.DATE] = 'YYYY-MM-DD'
timeToMomentKeyword[time.MONTH] = 'MM'
timeToMomentKeyword[time.WEEK] = 'dddd'
timeToMomentKeyword[time.DAY] = 'Do'
timeToMomentKeyword[time.HOUR] = 'HH:00'

export default class GeoDataBuilder extends DataBuilder {
    constructor(props) {
        super(props)
        // table
        this.generateTableData = this.generateTableData.bind(this)
        // piechart
        this.generateTargetingData = this.generateTargetingData.bind(this)
        this.generateTimeData = this.generateTimeData.bind(this)
        // trendchart
        this.generateTrendChartData = this.generateTrendChartData.bind(this)
    }
    generateTrendChartData(days) {
        const pre_data1 = this.groupByTime({data:this.data, momentKeyword:timeToMomentKeyword[time.DATE]})
        const pre_data2 = this.groupByTarget({data:pre_data1, reportType:report_type.DEVICE_TIME})
        const result = []
        for (let i = 0; i < days; i++) {
            const date = moment().subtract(days-i, 'days').format('YYYY-MM-DD')
            if (pre_data2[date] === undefined)
                result.push({name: date, visitors: 0})
            else {
                const android_visitors = pre_data2[date]['all']['Android']['total']
                const ios_visitors = pre_data2[date]['all']['iOS']['total']
                result.push({ name: date, visitors:android_visitors + ios_visitors })
            }
        }
        return result
    }
    generateTargetingData() {
        const pre_data1 = this.groupByTime({data:this.data})
        const pre_data2 = this.groupByTarget({data:pre_data1, targetingType:this.targetingType, reportType:report_type.GEO_TIME})
        const result = []
        const geo_data = pre_data2['all']
        for(let geo in geo_data) {
            const android_visitors = geo_data[geo]['Android']['total']
            const ios_visitors = geo_data[geo]['iOS']['total']
            result.push({name: this.locationTable[geo], value: android_visitors + ios_visitors })
        }
        return result
    }
    generateTimeData() {
        if (this.time === time.DATE) return []
        const pre_data1 = this.groupByTime({data:this.data, momentKeyword:timeToMomentKeyword[this.time]})
        const pre_data2 = this.groupByTarget({data:pre_data1, reportType:report_type.DEVICE_TIME})
        const result = []
        for (let time in pre_data2) {
            const time_data = pre_data2[time]
            const android_visitors =  time_data['all']['Android']['total']
            const ios_visitors = time_data['all']['iOS']['total']
            result.push({name: time, value: android_visitors + ios_visitors})
        }
        return result
    }
    generateTableData() {
        const pre_data1 = this.groupByTime({data:this.data, momentKeyword:timeToMomentKeyword[this.time]})
        const pre_data2 = this.groupByTarget({data:pre_data1, targetingType:this.targetingType, reportType:report_type.GEO_TIME})
        const pre_data3 = this.filterBySpecificTarget({data:pre_data2, specificTarget:this.specificTarget})
        const pre_data4 = this.filterByDevice({data:pre_data3})
        const result = []
        for (let time in pre_data4) {
            const geo_data = pre_data4[time]
            for (let geo in geo_data) {
                let temp_data = {
                    totalVisitors:geo_data[geo][this.device]['total'],
                    newVisitors:geo_data[geo][this.device]['new'],
                    returningVisitors: geo_data[geo][this.device]['return'],
                    avgFrequency: geo_data[geo][this.device]['frequency'] / this.divider,
                    bouncingRate: geo_data[geo][this.device]['bounce_rate'] / this.divider,
                    avgStayTime: geo_data[geo][this.device]['stay_time'] / this.divider
                }
                temp_data[this.target_accessor] = this.locationTable[geo]
                temp_data[this.time_accessor] = time
                result.push(temp_data)
            }
        }
        return result
    }
}
