const BusinessServices = {
    fetchBusiness: () => fetchBusiness(),
    fetchPickedPOIList: (apikey) => fetchPickedPOIList(apikey),
    createBusiness: (email, data) => createBusiness(email, data),
    modifyBusiness: (email, data) => modifyBusiness(email, data),
    deleteBusiness: (email) => deleteBusiness(email),
    setPickPOIList: (apikey, poi_list) => setPickPOIList(apikey, poi_list)
}
export default BusinessServices
const API_PREFIX = '/yanjing/api';
const generalOption = (action, data) => {
    return {
        method: action,
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data),
        credentials: 'include'
    }
};
const fetchPickedPOIList = (apikey) => {
    let requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    return fetch(API_PREFIX + '/business/pick_poi/' + apikey, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response)
            }
            return response.json()
        })
}

const setPickPOIList = (apikey, poi_list) => {
    let requestOptions = generalOption('POST', poi_list)
    return fetch(API_PREFIX + '/business/pick_poi/' + apikey, requestOptions)
        .then(response => {
            if(!response.ok) {
                return Promise.reject(response)
            }
            return response.json()
        })
}

const fetchBusiness = () => {
    let requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    return fetch(API_PREFIX + '/business/', requestOptions)
        .then(response => {
            if(!response.ok) {
                return Promise.reject(response)
            }
            return response.json()
        })
}

const createBusiness = (data) => {
    let requestOptions = generalOption('POST', data);
    return fetch(API_PREFIX + '/business/', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
        })
}

const modifyBusiness = (apikey, data) => {
    let requestOptions = generalOption('PUT', data);
    return fetch(API_PREFIX + '/business/' + apikey, requestOptions)
        .then(response => {
            if(!response.ok) {
                return Promise.reject(response);
            }
        })
}

const deleteBusiness = (apikey) => {
    let requestOptions = {
        method: 'PUT',
        credentials: 'include'
    }
    return fetch(API_PREFIX + '/business/delete/' + apikey, requestOptions)
        .then(response => {
            if(!response.ok) {
                return Promise.reject(response);
            }
        })
}
