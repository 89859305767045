// import * as Cookies from 'js-cookie';
// import fetch from 'isomorphic-fetch'
import Cookies from 'universal-cookie'

export const LoginServices = {
    login,
    logout,
    requestToken,
    rememberedAdmin
};
export default LoginServices
const API_PREFIX = '/yanjing/api';

function login(username, password) {
    const requestOption = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ username, password })
    };

    /* Directly call api */
    return fetch(API_PREFIX + '/auth/login', requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response.json();
        })
        .then(user => {
            return user;
        });
}

function rememberedAdmin() {
    const requestOption = {
        method: 'POST',
        credentials: 'include'
    }
    return fetch(API_PREFIX + '/auth/refresh/remembered_admin', requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response.json();
        }).then(user => {
            return user;
        })
}

function logout() {
    const requestOption = {
        method: 'GET',
        credentials: 'include'
    };
    return fetch(API_PREFIX + '/auth/logout', requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
        }).then(
            new Cookies().remove('admin_remembered')
        )
}

function requestToken() {
    const requestOption = {
        method: 'POST',
        credentials: 'include'
    };
    return fetch(API_PREFIX + '/auth/refresh', requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response.json();
        })
}
