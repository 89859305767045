import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Header, Button, Icon, Grid } from 'semantic-ui-react'
import ReactTable from 'react-table'
import selectTableHOC from 'react-table/lib/hoc/selectTable'
import _ from 'lodash'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-table/react-table.css'
import 'semantic-ui-css/semantic.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { businessActions } from '../../actions/Business'
import BusinessBtnGroup from './BusinessBtnGroup'
import { BusinessModal } from './BusinessModal'
import { PickPOIContainer } from './PickPOIModal'

const SelectTable = selectTableHOC(ReactTable)

class Business extends Component {
    constructor(props) {
        super(props)
        this.props.fetchBusiness()
        this.state = {
            isBusinessModalOpen: false
        }
        this.checkAction = this.checkAction.bind(this)
        this.Create = this.Create.bind(this)
        this.Modify = this.Modify.bind(this)
        this.Delete = this.Delete.bind(this)
        this.Select = this.Select.bind(this)

        //pick poi
        this.clickPickPOI = this.clickPickPOI.bind(this)
        //business modal
        this.toggleBusinessModal = this.toggleBusinessModal.bind(this)
        //table props
        this.toggleSelection = this.toggleSelection.bind(this)
        this.selectAll = this.selectAll.bind(this)
        this.toggleAll = this.toggleAll.bind(this)
        this.isSelected = this.isSelected.bind(this)
    }
    checkAction(action) {
        switch(action) {
            case 'Create':
                this.Create()
                break
            case 'Modify':
                this.Modify()
                break
            case 'Delete':
                this.Delete()
                break
            default:
                break
        }
    }
    Create() {
        this.props.selectBusiness(undefined, {})
        this.toggleBusinessModal()
    }
    Modify() {
        if (this.props.selectIndex === undefined) return
        this.toggleBusinessModal()
    }
    Delete() {
        const { current } = this.props
        if (this.props.selectIndex === undefined) return
        this.props.deleteBusiness(current.apikey)
    }
    Select(state, rowInfo) {
        const { data } = this.props
        this.props.selectBusiness(rowInfo.index, data[rowInfo.index])
    }
    toggleBusinessModal() {
        this.setState({isBusinessModalOpen: !this.state.isBusinessModalOpen})
    }
    // SelectTable function
    toggleSelection(key, shift, row) {
        const { selectIndex } = this.props
        if (key === selectIndex) {
            this.props.selectBusiness(undefined, {})
        }
        else {
            this.Select(this.state, row)
        }
    }
    clickPickPOI(e, rowIndex, original) {
        this.props.selectBusiness(rowIndex, original)
        this.props.openPickModal(original.apikey)
    }
    isSelected(rowInfo) {
        const { selectIndex } = this.props
        if (rowInfo === undefined)
            return false
        if (rowInfo.index === selectIndex && selectIndex !== undefined) {
            return true
        }
        return false
    }
    toggleAll() {
        return
    }
    selectAll() {
        this.toggleAll()
    }
    render() {
        const { data } = this.props
        const { toggleSelection, toggleAll, isSelected, selectAll } = this;
        const selectTableProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "radio",
            getTrProps: (state, rowInfo) => {
                const selected = this.isSelected(rowInfo)
                return {
                    style: {
                        backgroundColor: selected ? 'grey' : 'inherit'
                    },
                    onClick: (e, handleOriginal) => {
                        this.Select(state, rowInfo);
                        if (handleOriginal) {
                            handleOriginal()
                        }
                    }
                }
            }
        }
        return (
            <div style={{ marginRight: "51px" }}>
                <Grid style={{ marginTop: "14px", marginBottom: '18px' }}>
                    <Grid.Column width={1}>
                        <FontAwesomeIcon icon="briefcase" pull="right" size="2x" />
                    </Grid.Column>
                    <Grid.Column width={9}>
                        <p className='header-name'> Business</p>
                    </Grid.Column>
                </Grid>
                <BusinessBtnGroup haveSelected={this.props.selectIndex === undefined ? false : true} checkAction={this.checkAction} />
                <SelectTable
                    data={data}
                    filterable
                    defaultSorted={["id": "created_at", "desc": false]}
                    columns={[
                        {
                            Header: 'Name',
                            accessor: 'name',
                            filterMethod: (filter, row) =>
                            _.includes(row[filter.id], filter.value)
                        },
                        {
                            Header: 'Brand',
                            accessor: 'brand',
                            filterMethod: (filter, row) =>
                            _.includes(row[filter.id], filter.value)
                        },
                        {
                            id: 'info',
                            Header: 'Information',
                            accessor: (row) => {
                                return {
                                address: row.address || 'No Address',
                                website: row.website || 'No Website',
                                phone: row.phone || 'No Phone'
                            }},
                            filterable: false,
                            Cell: (row) => {
                                return (
                                <div>
                                    Address: {row.value.address} <br/>
                                    Website: {row.value.website} <br/>
                                    Phone: {row.value.phone} <br/>
                                </div>
                            )}
                        },
                        {
                            Header: 'Apikey',
                            accessor: 'apikey',
                            filterable: false,
                            sortable: false
                        },
                        {
                            Header: 'Create Date',
                            accessor: 'created_at',
                            sortable: true
                        },
                        {
                            Header: '',
                            accessor: '',
                            filterable: false,
                            sortable: false,
                            resizable: false,
                            Cell: (row) => (
                                <div>
                                    <Button icon
                                        labelPosition='left'
                                        onClick={(e) => { this.clickPickPOI(e, row.index, row.original) }}
                                    ><Icon name='info' />Pick POI</Button>
                                </div>
                            )
                        }
                    ]}
                    {...selectTableProps}
                />
                <BusinessModal
                    isModalOpen={this.state.isBusinessModalOpen}
                    toggleModalOpen={this.toggleBusinessModal}
                    selectedBusiness={this.props.current}
                    selectIndex={this.props.selectIndex}
                    brandOption={this.props.brand}
                    businessAction={{createBusiness: this.props.createBusiness, modifyBusiness: this.props.modifyBusiness}}/>
                <PickPOIContainer />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    const business_data = state.data.business
    const selectIndex = state.business.selectIndex
    const select_business = state.business.selectBusiness
    const brand_data = state.data.brand
    const current = state.business.selectBusiness
    const data = []
    if (business_data.length === 0 || brand_data.length === 0)
        return ({ current, data, selectIndex })
    business_data.map((business) => {
        const brandIndex = brand_data.findIndex(x => x.id === business.brand_id)
        data.push({
            brand: brand_data[brandIndex].name,
            ...business
        })
        return data
    })
    return ({
        current: state.business.selectBusiness,
        brand: brand_data,
        selectIndex,
        data,
    })
}

const mapDispatchToProps = (dispatch) => ({
    fetchBusiness: () => dispatch(businessActions.fetchBusiness()),
    createBusiness: (data) => dispatch(businessActions.createBusiness(data)),
    modifyBusiness: (apikey, data) => dispatch(businessActions.modifyBusiness(apikey, data)),
    deleteBusiness: (apikey) => dispatch(businessActions.deleteBusiness(apikey)),
    selectBusiness: (index, business) => dispatch(businessActions.selectBusiness(index, business)),
    openPickModal: (apikey) => dispatch(businessActions.openPickModal(apikey))
})

const BusinessContainer = connect(mapStateToProps, mapDispatchToProps)(Business)

export default BusinessContainer
