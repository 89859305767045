import { connect } from 'react-redux'
import { createBrand, modifyBrand, deleteBrand } from '../../../actions/POI'
import BrandTable from '../../../components/POI/Brand/BrandTable'

const mapStateToProps = (state) => {
    const _categoryOption = []
    const _data = []
    const categoryRef = state.info.category_data
    for (var key in categoryRef) {
        let option = {
            text: categoryRef[key],
            value: key
        }
        _categoryOption.push(option)
    }
    state.info.brand_data.map((brand, index) => {
        let tokens = brand.category_ids.split(' ')
        let category_str = '';
        let category_ary = [];
        for (var key in tokens) {
            category_str +=  categoryRef[tokens[key]] + ", "
            category_ary.push(tokens[key]);
        }
        let data = {
            id: brand.id,
            name: brand.name,
            category: category_str.slice(0,-2), // Displayed On The Table
            category_ids: category_ary,
            price: brand.price,
            description: brand.description
        }
        _data.push(data)
        return true
    })
    return ({
        data: _data,
        categoryOption: _categoryOption
    })
}


const mapDispatchToProps = (dispatch) => ({
    createBrand: (data) => {
        dispatch(createBrand(data))
    },
    modifyBrand: (id, data) => {
        dispatch(modifyBrand(id, data))
    },
    deleteBrand: (id) => {
        dispatch(deleteBrand(id))
    }
})


const BrandTableContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BrandTable)

export default BrandTableContainer