import React from 'react';
import IdleTimer from 'react-idle-timer'

const IdleMonitor = ({ timeOut, logout }) => {
    return (
        <IdleTimer
            element={document}
            onIdle={() => logout()}
            debounce={250}
            timeout={timeOut * 1000} />
    )
}

export default IdleMonitor