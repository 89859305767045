import React, { Component } from 'react'
import { Grid, Header, Segment } from 'semantic-ui-react'
import "semantic-ui-css/semantic.min.css"

class Information extends Component {
    render() {
        const { info } = this.props;
        return(
            <Segment className="segment-none-border">
                <Header style={{ fontWeight: "bolder" }} size="medium">Information</Header>
                <Grid style={{ lineHeight: "10px" }}>
                <Grid.Row>
                    <Grid.Column><li>Total POI: {info.total_poi}</li></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column><li>POI with brand: {info.poi_with_brand}</li></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column><li>POI with building: {info.poi_with_build}</li></Grid.Column>
                </Grid.Row>
                </Grid>
            </Segment>
        );
    }
}

export default Information