import actions from '../constant/actions';
import roles from '../constant/roles';

const initialState = { username: '', apikey: '', role: roles.BUSINESS_ADMIN, poi_list: [], tokenExpiry: undefined, tokenPromise: undefined, isFetchingToken: undefined };

export function admin(state = initialState, action) {
  switch (action.type) {
    case actions.ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        username: action.user.username,
        apikey: action.user.apikey,
        role: action.user.role,
        tokenExpiry: action.user.tokenExpiry
      }
    case actions.REMEMBERED_ADMIN:
      return {
        ...state,
        username: action.user.username,
        apikey: action.user.apikey,
        role: action.user.role,
        tokenExpiry: action.user.tokenExpiry
      }
    case actions.ADMIN_UPDATE_POI_LIST:
      return {
        ...state,
        poi_list: action.payload
      }
    case actions.ADMIN_REFRESHING_TOKEN:
      return {
        ...state,
        isFetchingToken: true,
        tokenPromise: action.tokenPromise,
      }
    case actions.ADMIN_TOKEN_REFRESHED:
      return {
        ...state,
        isFetchingToken: false,
        tokenPromise: undefined,
        tokenExpiry: action.token.tokenExpiry,
      }
    case actions.ADMIN_LOGOUT:
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default admin
