import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Divider, Menu } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import '../../public/styles/General.css';

import POITitle from './POITitle'
import Overall from './Overall'
import Setting from './Setting'
import Brand from './Brand'
import Building from './Building'

// Inner Routes
const routes = [
    {
        name: "Overall",
        path: "/poi",
        exact: true,
        main: () => <Overall />
    },
    {
        name: "Setting",
        path: "/poi/POI",
        main: () => <Setting />
    },
    {
        name: "Brand",
        path: "/poi/Brand",
        main: () => <Brand />
    },
    {
        name: "Building",
        path: "/poi/Building",
        main: () => <Building />
    },
];

class POI extends Component {
    state = { activeItem: 'Overall' } // set first menu is Overall
    handleItemClick = (e, { name }) => this.setState({ activeItem: name })
  
    render() {
        const { activeItem } = this.state

        return(
            <div id="POI">
                <POITitle />
                <Divider />
                <Menu tabular>
                    {routes.map((route, index) => (
                        <Menu.Item 
                            as={Link} 
                            to={route.path}
                            name={route.name} 
                            active={activeItem === route.name} 
                            key={route.name} 
                            onClick={this.handleItemClick}
                        >
                        </Menu.Item>
                    ))}
                </Menu>
            </div>
        )
    }
}

export default POI
