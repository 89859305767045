import React, { Component } from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'
import SearchBox from 'react-google-maps/lib/components/places/SearchBox'
import _ from 'lodash'

/* global google */
const InitialMap = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        ref={props.onMapMounted}
        defaultZoom={12}
        center={props.center}
        onIdle={props.onMapIdle}
        onClick={props.onMapClick}
    >
        <SearchBox
            ref={props.onSearchBoxMounted}
            controlPosition={1}
            bounds={props.bounds}
            onPlacesChanged={props.onPlacesChanged}
        >
            <input
                type='text'
                placeholder='Search for places'
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `240px`,
                    height: `39px`,
                    marginTop: `10px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `13px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                }}
            />
        </SearchBox>
        {
            props.markers.map((marker, index) => {
                return (
                    <Marker
                        draggable={true}
                        key={index}
                        position={marker.position}
                        onDragEnd={(updatedMarker) => props.onDragEnd(updatedMarker)}
                        onClick={(updatedMarker) => props.onMarkerClick(updatedMarker)}
                    >
                        {props.windowIsOpen && <InfoWindow
                            onCloseClick={props.infoWindow}
                        >
                            <div style={{ backgroundColor: `white`, opacity: 0.75, padding: `12px` }}>
                                <div style={{ fontSize: `16px`, fontColor: `#08233B` }}>
                                    {marker.name}
                                </div>
                            </div>
                        </InfoWindow>}
                    </Marker>
                )
            })
        }
    </GoogleMap>
))

class MapComponent extends Component {
    constructor(props) {
        super(props)
        this.mapMounted = {}
        this.searchBoxMounted = {}
        this.state = {
            activeMarker: true,
            bounds: null,
            center: {
                lat: this.props.markers.position.lat || 25.105497,
                lng: this.props.markers.position.lng || 121.597366
            },
            infoWindowIsOpen: false,
            markers: [this.props.markers]
        }
    }
    handleBoundsChange = () => {
        this.setState({
            bounds: this.mapMounted.getBounds(),
            center: this.mapMounted.getCenter()
        })
    }
    handlePlacesChange = () => {
        const places = this.searchBoxMounted.getPlaces();
        const bounds = new google.maps.LatLngBounds();
        places.forEach(place => {
            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport)
            } else {
                bounds.extend(place.geometry.location)
            }
        });
        const nextMarkers = places.map(place => ({
            name: place.name,
            position: place.geometry.location,
        }));
        const nextCenter = _.get(nextMarkers, '0.position', this.state.center);
        this.setState({
            activeMarker: false,
            infoWindowIsOpen: false,
            center: nextCenter,
            markers: nextMarkers,
        })
    }
    handleDragEnd = (updatedMarker) => {
        this.handleUpdatedMarker(updatedMarker)
    }
    handleMarkerClick = (updatedMarker) => {
        this.handleUpdatedMarker(updatedMarker)
        if (this.state.infoWindowIsOpen === false) {
            this.handleInfoWindow()
        }
    }
    handleMapClick = (e) => {
        const { position } = this.state.markers[0]
        if (position.lat === "" && position.lng === "") this.handleUpdatedMarker(e)
    }
    handleInfoWindow = () => {
        this.setState({
            infoWindowIsOpen: !this.state.infoWindowIsOpen
        })
    }
    // Only handle bounds change on idle map to prevent drag lag
    mapIdle = () => {
        this.handleBoundsChange()
    }
    handleUpdatedMarker(updatedMarker) {
        const { latLng } = updatedMarker
        const new_lat = latLng.lat()
        const new_lng = latLng.lng()
        const newMarker = {
            name: this.state.markers[0].name,
            position: {
                lat: new_lat,
                lng: new_lng
            }
        }
        this.setState({
            activeMarker: true,
        })
        this.props.mapMarkerUpdate(newMarker)
    }
    static getDerivedStateFromProps(props, state) {
        if ((state.activeMarker !== false) && (props.markers.position !== state.markers[0].position)) {
            const { name, position } = props.markers
            const newMarker = {
                name: name,
                position: {
                    lat: +position.lat,
                    lng: +position.lng
                }
            }
            return { markers: [newMarker] }
        } else {
            return null
        }
    }
    render() {
        return (
            <InitialMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA9RGpaGR_NkLI4M8Jct2Gy2Lv6q2phGBQ&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                center={this.state.center}
                markers={this.state.markers}
                bounds={this.state.bounds}
                windowIsOpen={this.state.infoWindowIsOpen}
                onMapMounted={(ref) => this.mapMounted = ref}
                onSearchBoxMounted={(ref) => this.searchBoxMounted = ref}
                onMapIdle={this.mapIdle}
                onDragEnd={this.handleDragEnd}
                onMapClick={this.handleMapClick}
                onMarkerClick={this.handleMarkerClick}
                onPlacesChanged={this.handlePlacesChange}
                infoWindow={this.handleInfoWindow}
            />
        );
    }
}

export default MapComponent