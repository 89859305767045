import React, { Component } from 'react'
import POITableContainer from '../../../containers/POI/Setting/POITable'
import { SettingInfoContainer } from '../../../containers/POI/Information'
import { Grid } from 'semantic-ui-react'
import "react-table/react-table.css"

class Setting extends Component{
    render() {
        return (
            <Grid className="tap-content" columns={2}>
                <Grid.Column widescreen={3}>
                    <SettingInfoContainer />
                </Grid.Column>
                <Grid.Column widescreen={13}>
                    <POITableContainer />
                </Grid.Column>
            </Grid>
        )
    }
}
export default Setting
