import React, { Component } from 'react'
import { Header, Segment, Popup, Button, Divider, Dropdown } from 'semantic-ui-react'
import { distance, time, time_header_accessor, device, geo } from '../../../constant/filters'

const distanceOptions = [
    {
        text: distance.FIVE_KM,
        value: distance.FIVE_KM
    }, {
        text: distance.TEN_KM,
        value: distance.TEN_KM
    }, {
        text: distance.FIFTEEN_KM,
        value: distance.FIFTEEN_KM
    }, {
        text: distance.TWENTY_KM,
        value: distance.TWENTY_KM
    }, {
        text: distance.CLEAR,
        value: distance.CLEAR
    }
]

const timeOptions = [
    {
        text: time_header_accessor[time.HOUR].Header,
        value: time.HOUR
    }, {
        text: time_header_accessor[time.DAY].Header,
        value: time.DAY
    }, {
        text: time_header_accessor[time.WEEK].Header,
        value: time.WEEK
    }, {
        text: time_header_accessor[time.MONTH].Header,
        value: time.MONTH
    }, {
        text: time_header_accessor[time.DATE].Header,
        value: time.DATE
    }
]

const deviceOptions = [
    {
        text: device.ALL,
        value: device.ALL
    },  {
        text: device.IOS,
        value: device.IOS
    },  {
        text: device.ANDROID,
        value: device.ANDROID
    }
]
const geoOptions = [
    {
        text: geo.COUNTRY,
        value: geo.COUNTRY
    },  {
        text: geo.CITY,
        value: geo.CITY
    },  {
        text: geo.DISTRICT,
        value: geo.DISTRICT
    },

]
const initialSettings = () => ({
    specificTarget: [],
    distance: distance.CLEAR,
    time: time.DATE,
    device: device.ALL,
})

export class GeoAdvancedFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAdvancedFilterOpen: false
        }
        this.advancedFilterSettings = initialSettings()
        this.toggleAdvancedFilter = this.toggleAdvancedFilter.bind(this)
        this.handleDropdownChange = this.handleDropdownChange.bind(this)
        this.onApplyClick = this.onApplyClick.bind(this)
    }
    toggleAdvancedFilter() {
        this.setState({isAdvancedFilterOpen: !this.state.isAdvancedFilterOpen})
        this.advancedFilterSettings = initialSettings()
    }
    onApplyClick() {
        let time_step = 'day'
        if (this.advancedFilterSettings.time === time.HOUR) {
            time_step = 'hour'
        }

        this.props.fetchAdvancedStatistic({
            apikey: this.props.apikey,
            is_similar: this.props.isSimilarBrandOpen,
            days: this.props.days,
            time_step,
            settings: this.advancedFilterSettings
        })
        this.toggleAdvancedFilter()
    }
    handleDropdownChange(data) {
        const name = data.name
        const value = data.value
        this.advancedFilterSettings[name] = value
    }
    render() {
        const { geoList } = this.props
        return(
            <Segment>
            <Header>Advanced: </Header>
            <Popup
                trigger={<Button color='green' icon='search' content='Open Filter' />}
                content={<div><Segment.Group>
                                    <Segment>
                                        <Header size='small'>Advanced</Header>
                                    </Segment>
                                    <Segment>
                                        Geo:
                                        <Dropdown multiple search selection options={geoList} placeholder='Filter' name='specificTarget' onChange={(event, data) => this.handleDropdownChange(data)} />
                                    </Segment>
                                    <Segment>
                                        <Dropdown placeholder='Distance' name='distance' options={distanceOptions} onChange={(event, data) => this.handleDropdownChange(data)} />
                                    </Segment>
                                    <Segment>
                                        <Dropdown placeholder='Time' name='time' options={timeOptions} onChange={(event, data) => this.handleDropdownChange(data)} />
                                        <Divider/>
                                        <Dropdown placeholder='Device' name='device' options={deviceOptions} onChange={(event, data) => this.handleDropdownChange(data)} />
                                    </Segment>
                                </Segment.Group>
                                <Segment textAlign="center" basic>
                                    <Button onClick={this.toggleAdvancedFilter}>Close</Button>
                                    <Button primary onClick={this.onApplyClick}>Apply</Button>
                                </Segment></div>}
                on='click'
                open={this.state.isAdvancedFilterOpen}
                onOpen={this.toggleAdvancedFilter}
                position='top right'
            />
        </Segment>
        )
    }
}

export class DeviceAdvancedFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAdvancedFilterOpen: false
        }
        this.advancedFilterSettings = initialSettings()
        this.toggleAdvancedFilter = this.toggleAdvancedFilter.bind(this)
        this.handleDropdownChange = this.handleDropdownChange.bind(this)
        this.onApplyClick = this.onApplyClick.bind(this)
    }
    toggleAdvancedFilter() {
        this.setState({isAdvancedFilterOpen: !this.state.isAdvancedFilterOpen})
        this.advancedFilterSettings = initialSettings()
    }
    onApplyClick() {
        let time_step = 'day'
        if (this.advancedFilterSettings.time === time.HOUR) {
            time_step = 'hour'
        }

        this.props.fetchAdvancedStatistic({
            apikey: this.props.apikey,
            is_similar: this.props.isSimilarBrandOpen,
            days: this.props.days,
            time_step,
            settings: this.advancedFilterSettings
        })
        this.toggleAdvancedFilter()
    }
    handleDropdownChange(data) {
        const name = data.name
        const value = data.value
        this.advancedFilterSettings[name] = value
    }
    render() {
        return(
            <Segment>
            <Header>Advanced: </Header>
            <Popup
                trigger={<Button color='green' icon='search' content='Open Filter' />}
                content={<div><Segment.Group>
                                    <Segment>
                                        <Header size='small'>Advanced</Header>
                                    </Segment>
                                    <Segment>
                                        <Dropdown placeholder='Device' name='device' options={deviceOptions} onChange={(event, data) => this.handleDropdownChange(data)} />
                                    </Segment>
                                    <Segment>
                                        <Dropdown placeholder='Time' name='time' options={timeOptions} onChange={(event, data) => this.handleDropdownChange(data)} />
                                        <Divider/>
                                        <Dropdown placeholder='Geo' name='geo' options={geoOptions} onChange={(event, data) => this.handleDropdownChange(data)} />
                                    </Segment>
                                </Segment.Group>
                                <Segment textAlign="center" basic>
                                    <Button onClick={this.toggleAdvancedFilter}>Close</Button>
                                    <Button primary onClick={this.onApplyClick}>Apply</Button>
                                </Segment></div>}
                on='click'
                open={this.state.isAdvancedFilterOpen}
                onOpen={this.toggleAdvancedFilter}
                position='top right'
            />
        </Segment>
        )
    }
}

export class PickedPOIAdvancedFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAdvancedFilterOpen: false
        }
        this.advancedFilterSettings = initialSettings()
        this.toggleAdvancedFilter = this.toggleAdvancedFilter.bind(this)
        this.handleDropdownChange = this.handleDropdownChange.bind(this)
        this.onApplyClick = this.onApplyClick.bind(this)
    }
    toggleAdvancedFilter() {
        this.setState({isAdvancedFilterOpen: !this.state.isAdvancedFilterOpen})
        this.advancedFilterSettings = initialSettings()
    }
    onApplyClick() {
        this.props.fetchAdvancedByPOIList({
            apikey: this.props.apikey,
            poi_list: this.props.POIList,
            days: this.props.days,
            settings: this.advancedFilterSettings
        })
        this.toggleAdvancedFilter()
    }
    handleDropdownChange(data) {
        const name = data.name
        const value = data.value
        this.advancedFilterSettings[name] = value
    }
    render() {
        const { POIList, } = this.props
        return(
            <Segment>
            <Header>Advanced: </Header>
            <Popup
                trigger={<Button color='green' icon='search' content='Open Filter' />}
                content={<div><Segment.Group>
                                <Segment>
                                    <Header size='small'>Advanced</Header>
                                </Segment>
                                <Segment>
                                    POI:
                                    <Dropdown multiple search selection options={POIList} placeholder='Filter' name='specificTarget' onChange={(event, data) => this.handleDropdownChange(data)}/>
                                </Segment>
                                <Segment>
                                        <Dropdown placeholder='Time' name='time' options={timeOptions.slice(1,5)} onChange={(event, data) => this.handleDropdownChange(data)} />
                                        <Divider/>
                                        <Dropdown placeholder='Geo' name='geo' options={geoOptions} onChange={(event, data) => this.handleDropdownChange(data)} />
                                        <Divider/>
                                        <Dropdown placeholder='Device' name='device' options={deviceOptions} onChange={(event, data) => this.handleDropdownChange(data)} />
                                    </Segment>
                            </Segment.Group>
                            <Segment textAlign="center" basic>
                                <Button onClick={this.toggleAdvancedFilter}>Close</Button>
                                <Button primary onClick={this.onApplyClick}>Apply</Button>
                            </Segment></div>}
                on='click'
                open={this.state.isAdvancedFilterOpen}
                onOpen={this.toggleAdvancedFilter}
                position='top right'
            />
        </Segment>
        )
    }
}
