import React, { Component } from 'react'
import { Segment, Header } from 'semantic-ui-react'
import { compose, withProps, withStateHandlers } from 'recompose'
import { connect } from 'react-redux'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'

class Distributed extends Component {
    render() {
        const { markers } = this.props
        return(
            <Segment className="segment-none-border">
                <Header style={{ fontWeight: "bolder" }} size='medium'>POI Distributed</Header>
                <MapComponent markers={markers}/>
            </Segment>
        )
    }
}

const MapComponent = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCcnAdtkD6UlWN6Y2XWeZuxU_d8KQOdIx0&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `600px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withStateHandlers(() => ({
        isOpen: false,
        infoIndex: '0'
    }), {
        onToggleOpen: ({ isOpen }) => () => ({
            isOpen: !isOpen
        }),
        showInfo: ({ infoIndex, isOpen }) => (index) => ({
            isOpen: !isOpen,
            infoIndex: index
        })
    }),
    withScriptjs,
    withGoogleMap,
  )((props) =>
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{lat:25.105497, lng:121.597366}}
    >
    {
        props.markers.map((marker, index) => (
            <Marker
            key={index}
            position={{lat:marker.lat,lng:marker.lng}}
            onClick={() => {props.showInfo(index)}}
            >
                { props.infoIndex === index && props.isOpen  && <InfoWindow
                    onCloseClick={props.onToggleOpen}
                    options={{closeBoxURL: ``, enableEventPropagation: true}}
                    >
                        <div style={{backgroundColor:`white`,opacity:0.75,padding:`12px`}}>
                            <div style={{fontSize:`16px`,fontColor:`#08233B`}}>
                                {marker.name}
                            </div>
                        </div>
                    </InfoWindow>}
            </Marker>
        ))
    }
    </GoogleMap>
  )

const mapStateToProps = (state) => {
    const building = state.data.building
    const markers = []
    for (let i in building) {
        markers.push({name:building[i].name,lat:building[i].lat,lng:building[i].lng})
    }
    return({
        markers
    })
}


export const DistributedContainer = connect(
    mapStateToProps
)(Distributed)

export default DistributedContainer
