import actions from '../../constant/actions'
import POIServices from '../../services/POI'
import ReportServices from '../../services/Report'
import { updateReportData } from '../Report/index'

// Updating the information Component, including `Overall` and `Setting`
const updateInfo = (info) => ({
    type: actions.UPDATE_INFO,
    payload: info
})

const updatePOI = (data) => ({
    type: actions.UPDATE_POI,
    payload: data
})

const updateBrand = (data) => ({
    type: actions.UPDATE_BRAND,
    payload: data
})

const updateBuilding = (data) => ({
    type: actions.UPDATE_BUILDING,
    payload: data
})

const updateCategory = (data) => ({
    type: actions.UPDATE_CATEGORY,
    payload: data
})
const updateLocation = (data) => ({
    type: actions.UPDATE_LOCATION,
    payload: data
})

export const initialUpdate = ({ apikey, days, poi_list }) => {
    return dispatch => {
        dispatch({ type: actions.PAGE_LOADING })
        const brandFetched = POIServices.fetchBrand()
            .then(result => dispatch(updateBrand(result)))
        const infoFetched = POIServices.fetchInfo()
            .then(result => dispatch(updateInfo(result)))
        const buildingFetched = POIServices.fetchBuilding()
            .then(result => dispatch(updateBuilding(result)))
        const categoryFetched =
            POIServices.fetchCategory()
                .then(result => dispatch(updateCategory(result)))
        const POIFetched = POIServices.fetchPOI()
            .then(result => dispatch(updatePOI(result)))
        const locationFetched = POIServices.fetchLocation()
            .then(result => dispatch(updateLocation(result)))
        const reportFetched = ReportServices.fetchByPOIList({ apikey, days, poi_list })
            .then(result => dispatch(updateReportData(result)))

        Promise.all([brandFetched, infoFetched, buildingFetched, categoryFetched, POIFetched, locationFetched, reportFetched]).then(() => dispatch({ type: actions.PAGE_LOADED }))
            .catch((error) => {
                if (error.status === 401) {
                    dispatch({ type: actions.ADMIN_SESSION_EXPIRED })
                }
                else {
                    dispatch({ type: actions.PAGE_LOADED })
                }
            })
    }
}

// Create action API
export const createPOI = (data) => {
    return dispatch => {
        POIServices.createPOI(data)
            .then(
                response => POIServices.fetchPOI()
                    .then(result => dispatch(updatePOI(result)))
            )
    }
}

export const createBrand = (data) => {
    return dispatch => {
        POIServices.createBrand(data)
            .then(
                response => POIServices.fetchBrand()
                    .then(result => dispatch(updateBrand(result)))
            )
    }
}

export const createBuilding = (data) => {
    return dispatch => {
        POIServices.createBuilding(data)
            .then(
                response => POIServices.fetchBuilding()
                    .then(result => dispatch(updateBuilding(result)))
            )
    }
}

// Modify action API
export const modifyPOI = (id, data) => {
    return dispatch => {
        POIServices.modifyPOI(id, data)
            .then(
                response => POIServices.fetchPOI()
                    .then(result => dispatch(updatePOI(result)))
            )
    }
}

export const modifyBrand = (id, data) => {
    return dispatch => {
        POIServices.modifyBrand(id, data)
            .then(
                response => POIServices.fetchBrand()
                    .then(result => dispatch(updateBrand(result)))
            )
    }
}

export const modifyBuilding = (id, data) => {
    return dispatch => {
        POIServices.modifyBuilding(id, data)
            .then(
                response => POIServices.fetchBuilding()
                    .then(result => dispatch(updateBuilding(result)))
            )
    }
}

export const deletePOI = (id) => {
    return dispatch => {
        POIServices.deletePOI(id)
            .then(
                response => POIServices.fetchPOI()
                    .then(result => dispatch(updatePOI(result)))
            )
    }
}

export const deleteBuilding = (id) => {
    return dispatch => {
        POIServices.deleteBuilding(id)
            .then(
                response => POIServices.fetchBuilding()
                    .then(result => dispatch(updateBuilding(result)))
            )
    }
}

export const deleteBrand = (id) => {
    return dispatch => {
        POIServices.deleteBrand(id)
            .then(
                response => POIServices.fetchBrand()
                    .then(result => dispatch(updateBrand(result)))
            )
    }
}
