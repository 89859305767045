import React, { Component } from 'react';
import { MemoryRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import 'semantic-ui-css/semantic.min.css';
import routes from '../constant/routes'

import { adminActions } from '../actions/App'
import { LoginPage } from './LoginPage';
import { HomePage } from './HomePage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faMapMarkedAlt, faInfoCircle, faUserCircle, faBriefcase, faArrowCircleDown, faPlus, faPencilAlt, faTrashAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import IdleMonitorContainer from '../containers/HomePage/IdleMonitor'


library.add(faHome, faMapMarkedAlt, faInfoCircle, faUserCircle, faBriefcase, faArrowCircleDown, faPlus, faPencilAlt, faTrashAlt, faFileAlt)

class App extends Component {

    render() {
        const rememberMe = new Cookies().get('admin_remembered');
        const { isLogin } = this.props;
        const homePage = routes.find(route => route.name === 'About')

        if (rememberMe === 'true' && !isLogin) {
            this.props.dispatch(adminActions.rememberedAdmin())
        }

        return (
            <Router initialEntries={[homePage.path]}>
                <div>
                    {
                        isLogin ?
                            <div>
                                {rememberMe === 'false' && <IdleMonitorContainer
                                    timeOut={900}
                                />}
                                <HomePage />
                            </div>
                            :
                            <LoginPage />
                    }
                </div>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state,
        isLogin: state.app.isLogin,
    };
}

const connectedApp = connect(mapStateToProps)(App);
export default connectedApp;
export { connectedApp as App };
