import React, { Component } from 'react'
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { Segment, Header, Form, Button, Modal, Dropdown, Label, Grid } from 'semantic-ui-react';
import "semantic-ui-css/semantic.min.css"
import _ from 'lodash'
import { compareAlphaNumeric } from '../../../util'
import Pagination from "../../Pagination";
import "../../../public/styles/Pagination.css"
import POIBtnGroup from '../POIBtnGroup';



class POITable extends Component {
    constructor(props) {
        super(props)


        this.selectedBrand = -1          // Used to index the category
        this.selectedBuilding = -1      // Used to generate the name
        this.selectedFloor = -1
        this.selectedPOI = {}       // Selected to be modified or removed

        this.isModalOpen = false;   // modal controller

        this.state = {
            block: '',
            counter: '',
            floor: '',
            name: '',
            brand_id: 0,
            building_id: 0
        }

        const { floorOption } = this.props;
        this.floorOption = floorOption;

        // Function Binding(make `this` work in callback function)

        // The Data Manipulation Function
        this.checkAction = this.checkAction.bind(this)
        this.Create = this.Create.bind(this)
        this.Modify = this.Modify.bind(this)
        this.Delete = this.Delete.bind(this)

        // The Handling Function
        this.handleAddition = this.handleAddition.bind(this);
        this.handleDropwdownChange = this.handleDropdownChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    checkAction(action) {
        switch (action) {
            case 'Create':
                this.Create();
                break;
            case 'Modify':
                this.Modify();
                break;
            case 'Delete':
                this.Delete();
                break;
            default:
                return
        }
    }

    Create() {
        console.log('Here')
        this.selectedPOI = {}
        this.toggleModal();
    }

    Modify() {
        if (this.selectedPOI.id == null) {
            return;
        }
        this.toggleModal()
        const { block, counter, floor, name, brand_id, building_id } = this.selectedPOI
        this.selectedBrand = this.props.brandOption.findIndex(x => x.value === brand_id)
        this.selectedBuilding = this.props.buildingOption.findIndex(x => x.value === building_id)
        this.selectedFloor = this.props.floorOption.findIndex(x => x.value === floor)
        this.setState({
            block, counter, floor, name, brand_id, building_id
        })
    }
    Delete() {
        if (this.selectedPOI.id == null) {
            return;
        }
        this.props.deletePOI(this.selectedPOI.id)
        this.selectedPOI = {}
    }
    toggleModal() {
        this.isModalOpen = !this.isModalOpen;
        this.forceUpdate()
    }
    handleDropdownChange(data) {
        const { name, value } = data
        this.setState({ [name]: value });

        // For displaying the categories of the brand and the name
        if (name === 'brand_id') {
            this.selectedBrand = data.options.findIndex(x => x.value === data.value)
        } else if (name === 'building_id') {
            this.selectedBuilding = data.options.findIndex(x => x.value === parseInt(data.value, 10))
        }
        else if (name === 'floor') {
            this.selectedFloor = data.options.findIndex(x => x.value === parseInt(data.value, 10))
        }
        this.forceUpdate()
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    handleSubmit(e) {
        e.preventDefault();

        if (this.state.floor === null || this.state.brand_id === 0 || this.state.building === 0) {
            return;
        }

        if (this.selectedPOI.id === undefined) {   // Create
            this.props.createPOI(this.state)
        }
        else {   // Modify
            this.props.modifyPOI(this.selectedPOI.id, this.state)
        }
        this.handleClose()
    }
    handleClose() {

        this.selectedBuilding = -1
        this.selectedBrand = -1
        this.selectedFloor = -1

        this.setState({
            block: '',
            counter: '',
            floor: '',
            name: '',
            brand_id: 0,
            building_id: 0,
        })
        this.toggleModal()
    }

    handleAddition(e, { value }) {
        if (value.startsWith('B') || value.endsWith('F')) {
            let option = {
                text: value,
                value: value
            }
            this.floorOption.push(option);
            this.floorOption.sort(compareAlphaNumeric);
        }
        else return;
    }
    toggleTableClick = (state, rowInfo) => {
        if (rowInfo !== undefined) {
            if (rowInfo.index === this.selectedPOI.index) {
                return {
                    onClick: () => {
                        this.selectedPOI = {};
                        this.forceUpdate()
                    }, style: {
                        background: '#EFEFEF'
                    }
                }
            } else {
                return {
                    onClick: () => {
                        this.selectedPOI = this.props.data[rowInfo.index];
                        this.selectedPOI.index = rowInfo.index
                        this.forceUpdate()
                    }, style: {
                        background: 'white'
                    }
                }
            }
        } else {
            return {}
        }
    }
    render() {
        const { data, buildingOption, brandOption, categoryOption } = this.props
        // data manipulation btn group style
        let dataManipulation_btnGroup = this.selectedPOI.id == null ? false : true;

        return (
            <div style={{ marginRight: "51px" }}>
                <POIBtnGroup haveSelected={dataManipulation_btnGroup} checkAction={this.checkAction} />
                <Modal
                    open={this.isModalOpen}
                    onClose={this.handleClose}
                    size='large'
                >
                    <Modal.Content>
                        <Form>
                            <Header size='large'>POI Setting</Header>
                            <Form.Field>
                                <Label>POI name</Label>
                                <Form.Input placeholder='POI Name' name='name'
                                    defaultValue={this.selectedPOI.name || ''}
                                    onChange={this.handleChange} />
                            </Form.Field>
                            <Header size='large'>Building</Header>
                            <Form.Field>
                                <Label>Building Name or Address*</Label>
                                <Dropdown
                                    placeholder='Type keywords to select building'
                                    search selection
                                    options={buildingOption}
                                    name='building_id'
                                    defaultValue={this.selectedPOI.building_id || ''}
                                    onChange={(event, data) => this.handleDropdownChange(data)} />
                            </Form.Field>
                            <Form.Field>
                                <Label>Session</Label>
                                <Grid columns={3} divided>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Dropdown
                                                options={this.floorOption}
                                                placeholder='Floor'
                                                name='floor'
                                                search
                                                selection
                                                fluid
                                                allowAdditions
                                                additionLabel='Custom Floor : '
                                                defaultValue={this.selectedPOI.floor || ''}
                                                onAddItem={this.handleAddition}
                                                onChange={(event, data) => this.handleDropdownChange(data)}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Input placeholder='Block' name='block' defaultValue={this.selectedPOI.block || ''} onChange={this.handleChange} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Input placeholder='Counter' name='counter' defaultValue={this.selectedPOI.counter || ''} onChange={this.handleChange} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form.Field>
                            <Header size='large'>Brand</Header>
                            <Form.Field>
                                <Label>Brand Name*</Label>
                                <Dropdown
                                    placeholder='Type keywords to select brand'
                                    search selection
                                    options={brandOption}
                                    name='brand_id'
                                    defaultValue={this.selectedPOI.brand_id || ''}
                                    onChange={(event, data) => this.handleDropdownChange(data)} />
                            </Form.Field>
                            <Form.Field>
                                {
                                    (() => {
                                        if (this.selectedBrand === -1) {
                                            return <Segment inverted color='grey'>Category(auto generate)</Segment>
                                        } else {
                                            return <Segment inverted color='black'>{categoryOption[this.selectedBrand]}</Segment>
                                        }
                                    })()
                                }
                            </Form.Field>
                            <Button type="button" onClick={this.toggleModal}>Close</Button>
                            <Button primary type="button" onClick={this.handleSubmit} >Save</Button>
                        </Form>
                    </Modal.Content>
                </Modal>
                <ReactTable
                    getTrProps={this.toggleTableClick}
                    PaginationComponent={Pagination}
                    data={data}
                    filterable
                    columns={[
                        {
                            Header: "Name",
                            accessor: "name",
                            filterMethod: (filter, row) =>
                                _.includes(String(row[filter.id]).toLowerCase(), filter.value.toLowerCase())
                        },
                        {
                            Header: "Brand",
                            accessor: "brand",
                            filterMethod: (filter, row) =>
                                _.includes(String(row[filter.id]).toLowerCase(), filter.value.toLowerCase())
                        }
                    ]}
                    defaultPageSize={20}
                    className="-highlight"
                />
            </div>
        )
    }
}
export default POITable
