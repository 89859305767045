import React from 'react'
import ReactTable from 'react-table'
import { Menu } from 'semantic-ui-react'
import { targeting_type, time, targeting_type_header_accessor, time_header_accessor } from '../../../constant/filters'
import { Loader, Dimmer  } from 'semantic-ui-react'
import Pagination from "../../Pagination";
import "../../../public/styles/Pagination.css"

const LoadingPage = () => (
  <Dimmer active>
    <Loader>Loading</Loader>
  </Dimmer>
)

export const GeoDataTab = ({activeItem, isLoading, onMenuItemClick, targetingTypeFilter, timeFilter, table_data}) => (
    isLoading? <LoadingPage/> : <div>
        <Menu tabular>
            <Menu.Item
                name = {targeting_type_header_accessor[targeting_type.COUNTRY].Header}
                active = {activeItem === targeting_type.COUNTRY}
                onClick={() => onMenuItemClick(targeting_type.COUNTRY)}/>
            <Menu.Item
                name = {targeting_type_header_accessor[targeting_type.CITY].Header}
                active = {activeItem === targeting_type.CITY}
                onClick={() => onMenuItemClick(targeting_type.CITY)}/>
            <Menu.Item
                name = {targeting_type_header_accessor[targeting_type.DISTRICT].Header}
                active = {activeItem === targeting_type.DISTRICT}
                onClick={() => onMenuItemClick(targeting_type.DISTRICT)}/>
        </Menu>
        <ReactTable
                  data={table_data}
                  columns =
                  {(() => {
                      let filter_columns = [
                          {
                              Header: 'Total Visitors',
                              accessor: 'totalVisitors'
                          }, {
                              Header: 'New Visitors',
                              accessor: 'newVisitors'
                          }, {
                              Header: 'returningVisitors',
                              accessor: 'returningVisitors'
                          }, {
                              Header: 'Avg. Frequency',
                              accessor: 'avgFrequency'
                          }, {
                              Header: 'Bouncing Rate',
                              accessor: 'bouncingRate'
                          }, {
                              Header: 'Avg. Stay-time',
                              accessor: 'avgStayTime'
                          }
                      ]
                      if (timeFilter.Header === time_header_accessor[time.DATE].Header) {
                          filter_columns.unshift(timeFilter, targetingTypeFilter)
                      } else {
                          filter_columns.unshift(targetingTypeFilter, timeFilter)
                      }
                      return filter_columns
                  })()}
            />
    </div>
)
export const DeviceDataTab = ({activeItem, isLoading, onMenuItemClick, targetingTypeFilter, timeFilter, table_data}) => (
    isLoading? <LoadingPage/> : <div>
        <Menu tabular>
            <Menu.Item
            name = {targeting_type_header_accessor[targeting_type.DEVICE].Header}
            active = {activeItem === targeting_type.DEVICE}
            onClick={() => onMenuItemClick(targeting_type.DEVICE)}/>
        </Menu>
        <ReactTable
                  data={table_data}
                  columns =
                  {(() => {
                      let filter_columns = [
                          {
                              Header: 'Total Visitors',
                              accessor: 'totalVisitors'
                          }, {
                              Header: 'New Visitors',
                              accessor: 'newVisitors'
                          }, {
                              Header: 'returningVisitors',
                              accessor: 'returningVisitors'
                          }, {
                              Header: 'Avg. Frequency',
                              accessor: 'avgFrequency'
                          }, {
                              Header: 'Bouncing Rate',
                              accessor: 'bouncingRate'
                          }, {
                              Header: 'Avg. Stay-time',
                              accessor: 'avgStayTime'
                          }
                      ]
                      if (timeFilter.Header === time_header_accessor[time.DATE].Header) {
                          filter_columns.unshift(timeFilter, targetingTypeFilter)
                      } else {
                          filter_columns.unshift(targetingTypeFilter, timeFilter)
                      }
                      return filter_columns
                  })()}
            />
    </div>
)

export const PickedPOIDataTab = ({isLoading, targetingTypeFilter, timeFilter, table_data}) => (
    isLoading? <LoadingPage/> : <div>
        <ReactTable
                  PaginationComponent={Pagination}
                  data={table_data}
                  columns =
                  {(() => {
                      let filter_columns = [
                          {
                              Header: 'Total Visitors',
                              accessor: 'totalVisitors'
                          },
                          {
                              Header: 'Avg. Stay-time',
                              accessor: 'avgStayTime'
                          },
                          {
                              Header: 'Device Type',
                              accessor: 'device'
                          }
                      ]
                      if (timeFilter.Header === time_header_accessor[time.DATE].Header) {
                          filter_columns.unshift(timeFilter, targetingTypeFilter)
                      } else {
                          filter_columns.unshift(targetingTypeFilter, timeFilter)
                      }
                      return filter_columns
                  })()}
            />
    </div>
)

export const CustomDataTab = ({activeItem, isLoading, onMenuItemClick, targetingTypeFilter, timeFilter, table_data}) => (
    isLoading ? <LoadingPage/> : <div>
        <Menu tabular>
            <Menu Item
                name={targeting_type_header_accessor[targeting_type.CUSTOM].Header}
                active={activeItem === targeting_type.CUSTOM}
                onClick={() => onMenuItemClick(targeting_type.CUSTOM)}
            />
        </Menu>
        <ReactTable
            data={table_data}
            columns={
                [{
                    Header: 'POI',
                    accessor: 'poi_id'
                }, {
                    Header: 'POI name',
                    accessor: 'name'
                }, {
                    Header: 'Brand',
                    accessor: 'brand'
                }, {
                    Header: 'Category',
                    accessor: 'category'
                }, {
                    Header: 'Building',
                    accessor: 'building'
                }, {
                    Header: 'Floor',
                    accessor: 'floor'
                }, {
                    Header: 'device',
                    accessor: 'device'
                }, {
                    Header: 'Visits',
                    accessor: 'visit'
                }, {
                    Header: 'Stay time (avg)',
                    accessor: 'avg_stay'
                }]}
        />
    </div>
)
