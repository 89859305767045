import { connect } from 'react-redux'
import SettingInfo from '../../components/POI/Setting/Information'
import OverallInfo from '../../components/POI/Overall/Information'

const mapStateToProps = (state) => ({
    info: state.info.overall
})

// const mapDispatchToProps = (dispatch) => ({
//     fetchInfo: () => dispatch(fetchInfo())
// })

export const SettingInfoContainer = connect(
    mapStateToProps
)(SettingInfo)

export const OverallInfoContainer = connect(
    mapStateToProps
)(OverallInfo)

