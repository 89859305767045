import React, { Component } from 'react'
import { Segment, Grid, Header } from 'semantic-ui-react'
import "semantic-ui-css/semantic.min.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class Information extends Component {
    render() {
        const { info } = this.props;
        return (
            <Segment className="segment-none-border">
                <Header style={{ fontWeight: "bolder" }} size="medium">Information</Header>
                <Grid style={{ lineHeight: "10px" }}>
                    <Grid.Row>
                        <Grid.Column><li>Total POI: {info.total_poi}</li></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column><li>Total Building: {info.total_building}</li></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column><li>Total Brands: {info.total_brand}</li></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column><li>Default Price: {info.default_price}</li></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <li>Location List: {info.location_list}    <FontAwesomeIcon color="#00C7C2" icon="arrow-circle-down" size="lg" /></li>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <li>Category List: {info.category_list}    <FontAwesomeIcon color="#00C7C2" icon="arrow-circle-down" size="lg" /></li>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }
}

export default Information