const actions = {


    PAGE_LOADED: 'PAGE_LOADED',
    PAGE_LOADING: 'PAGE_LOADING',

    // Login/Logout
    ADMIN_UPDATE_POI_LIST: 'ADMIN_UPDATE_POI_LIST',
    ADMIN_LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',
    ADMIN_LOGIN_FAILURE: 'ADMIN_LOGIN_FAILURE',
    ADMIN_LOGOUT: 'ADMIN_LOGOUT',
    ADMIN_TOKEN_REFRESHED: 'ADMIN_TOKEN_REFRESHED',
    ADMIN_REFRESHING_TOKEN: 'ADMIN_REFRESHING_TOKEN',
    ADMIN_SESSION_EXPIRED: 'ADMIN_SESSION_EXPIRED',
    REMEMBERED_ADMIN: 'REMEMBERED_ADMIN',

    // Report/Filter
    FILTER_DATE_RANGE: 'FILTER_DATE_RANGE',
    TOGGLE_SIMILAR_BRAND: 'TOGGLE_SIMILAR_BRAND',
    SET_ADVANCED_FILTER: 'SET_ADVANCED_FILTER',
    SET_FILTER_API_KEY: 'SET_FILTER_API_KEY',
    SWITCH_TARGETING_TYPE: 'SWITCH_TARGETING_TYPE',
    RESET_FILTER: 'RESET_FILTER',

    // Report/Targeting
    UPDATE_REPORT_OVERVIEW_INFO: 'UPDATE_REPORT_OVERVIEW_INFO',
    UPDATE_REPORT_OVERVIEW_DAILY_VISITORS: 'UPDATE_REPORT_OVERVIEW_DAILY_VISITORS',
    UPDATE_REPORT_DATA: 'UPDATE_REPORT_DATA',
    UPDATE_LOCATION_TABLE: 'UPDATE_LOCATION_TABLE',
    UPDATE_BUSINESS_LIST: 'UPDATE_BUSINESS_LIST',
    UPDATE_GEO_LIST: 'UPDATE_GEO_LIST',
    UPDATE_TARGETING_TABLE: 'UPDATE_TARGETING_TABLE',
    UPDATE_TARGETING_POI_SELECTED_BRAND: 'UPDATE_TARGETING_POI_SELECTED_BRAND',
    UPDATE_TARGETING_POI_SELECTED_CATEGORY: 'UPDATE_TARGETING_POI_SELECTED_CATEGORY',
    UPDATE_DEMOGRAPHICS: 'UPDATE_DEMOGRAPHICS',
    UPDATE_CUSTOM_POI: 'UPDATE_CUSTOM_POI',
    UPDATE_REPORT_FAIL: "UPDATE_REPORT_FAIL",


    // POI
    // Update Information after fetching
    UPDATE_INFO: 'UPDATE_INFO',
    UPDATE_POI: 'UPDATE_POI',
    UPDATE_BRAND: 'UPDATE_BRAND',
    UPDATE_BUILDING: 'UPDATE_BUILDING',
    UPDATE_CATEGORY: 'UPDATE_CATEGORY',
    UPDATE_LOCATION: 'UPDATE_LOCATION',

    // Admin
    CREATE_ADMIN: 'CREATE_ADMIN',
    MODIFY_ADMIN: 'MODIFY_ADMIN',
    DELETE_ADMIN: 'DELETE_ADMIN',
    UPDATE_ADMIN: 'UPDATE_ADMIN',

    // Business
    CREATE_BUSINESS: 'CREATE_BUSINESS',
    MODIFY_BUSINESS: 'MODIFY_BUSINESS',
    DELETE_BUSINESS: 'DELETE_BUSINESS',
    UPDATE_BUSINESS: 'UPDATED_BUSINESS',
    BUSINESS_SELECT: 'BUSINESS_SELECT',
    BUSINESS_MODAL_OPEN: 'BUSINESS_MODAL_OPEN',
    BUSINESS_MODAL_CLOSE: 'BUSINESS_MODAL_CLOSE',
    BUSINESS_TOGGLE_PICKPOI: 'BUSINESS_TOGGLE_PICKPOI',
    BUSINESS_UPDATE_PICKPOI: 'BUSINESS_UPDATE_PICKPOI',
    BUSINESS_MODIFY_PICKPOI: 'BUSINESS_MODIFY_PICKPOI',
    BUSINESS_GET_PICKPOI: 'BUSINESS_GET_PICKPOI',
    BUSINESS_SET_PICKPOI: 'BUSINESS_SET_PICKPOI',

    // POI/Setting/
    CREATE_POI: 'CREATE_POI',
    MODIFY_POI: 'MODIFY_POI',
    DELETE_POI: 'DELETE_POI',

    // POI/Brand
    CREATE_BRAND: 'CREATE_BRAND',
    MODIFY_BRAND: 'MODIFY_BRAND',
    DELETE_BRAND: 'DELETE_BRAND',

    // POI/Building/
    CREATE_BUILDING: 'CREATE_BUILDING',
    MODIFY_BUILDING: 'MODIFY_BUILDING',
    DELETE_BUILDING: 'DELETE_BUILDING',


}

export default actions
