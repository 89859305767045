import { connect } from 'react-redux'
import { adminActions } from '../../actions/App'
import { initialUpdate } from '../../actions/POI'
import { switchTargetingType, resetFilter } from '../../actions/Report'
import SideMenu from '../../components/Home/SideMenu'

const mapStateToProps = (state) => {

    const POIList = []
    for (let i in state.admin.poi_list) {
        POIList.push({
            text: `${i}. ${state.data.poi[i].name}`,
            value: i.toString()
        })
    }

    return ({
        isLoading: state.app.isLoading,
        apikey: state.admin.apikey,
        days: state.filter.days,
        role: state.admin.role,
        POIList,
    })
}

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(adminActions.logout())
    },
    resetFilter: (apikey) => {
        dispatch(resetFilter(apikey))
    },
    switchTargetingType: (targetingType) => {
        dispatch(switchTargetingType(targetingType))
    },
    initialUpdate: (parameters) => {
        dispatch(initialUpdate(parameters))
    }
})

export const SideMenuContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SideMenu)

export default SideMenuContainer
