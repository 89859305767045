import React, { Component } from 'react'
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { Grid, Dropdown, Input, Form, Header, Button, Modal } from 'semantic-ui-react';
import "semantic-ui-css/semantic.min.css"
import _ from 'lodash'
import Pagination from "../../Pagination";
import "../../../public/styles/Pagination.css";
import POIBtnGroup from '../POIBtnGroup';
import MapComponent from './MapComponent';

class BuildingTable extends Component {
    constructor(props) {
        super(props)

        this.selectedBuilding = {}
        this.cityOption = []
        this.districtOption = []
        this.country = ''
        this.city = null
        this.district = null
        this.detail = ''

        this.isModalOpen = false;

        this.state = {
            address: '',
            lat: 0.0,
            lng: 0.0,
            location_id: '',
            name: '',
        }

        this.checkAction = this.checkAction.bind(this)
        this.Create = this.Create.bind(this)
        this.Modify = this.Modify.bind(this)
        this.Delete = this.Delete.bind(this)

        this.handleChange = this.handleChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleMapMarkerUpdate = this.handleMapMarkerUpdate.bind(this)
    }
    checkAction(action) {
        switch (action) {
            case 'Create':
                this.Create();
                break;
            case 'Modify':
                this.Modify();
                break;
            case 'Delete':
                this.Delete();
                break;
            default:
                return
        }
    }
    Create() {
        this.setState({
            name: '',
            lat: '',
            lng: ''
        })
        this.selectedBuilding = {};
        this.toggleModal();
    }
    Modify() {
        if (this.selectedBuilding.id == null) {
            return
        }
        this.toggleModal();
        const { name, address, lat, lng, country, city, district, detail } = this.selectedBuilding
        // this.handleChagne({name: 'name', value: name})
        this.handleDropdownChange({ name: 'country', value: country })
        this.handleDropdownChange({ name: 'city', value: city })
        this.handleDropdownChange({ name: 'district', value: district })
        // handleChange
        this.detail = detail;
        this.setState({
            address, name, lat, lng
        })
    }
    Delete() {
        if (this.selectedBuilding.id == null) {
            return
        }
        this.props.deleteBuilding(this.selectedBuilding.id)
        this.selectedBuilding = {};
    }
    handleDropdownChange(data) {
        let name = data.name
        let value = parseInt(data.value, 10)
        let location = this.props.location
        // After country selection, displaying the city option
        if (name === 'country') {
            this.country = value
            let _cityOption = [];
            for (let index in location) {
                if (location[index].children_id !== null) {
                    _cityOption.push({
                        text: location[index].name,
                        value: location[index].id
                    })
                }
            }
            this.cityOption = _cityOption;
            // After city selection, displaying the district option
        } else if (name === 'city') {
            let _districtOption = [];
            let index = location.findIndex(x => x.id === value)
            let ids = location[index].children_id.split(',')
            for (let index in ids) {
                let id = parseInt(ids[index], 10)
                _districtOption.push({
                    text: location[location.findIndex(x => x.id === id)].name,
                    value: id
                })
            }
            this.city = {
                name: location[index].name,
                id: value
            }
            this.districtOption = _districtOption;
        } else if (name === 'district') {
            let index = location.findIndex(x => x.id === value)
            let district_name = ''
            if (index !== -1) {
                district_name = location[index].name
            }
            this.district = {
                name: district_name,
                id: value
            }
        }
        this.forceUpdate()
    }
    handleChange(e) {
        const { name, value } = e.target;
        if (name === 'detail') {
            this.detail = value
        } else {
            this.setState({ [name]: value });
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        if (this.country === '' || this.city === null || this.district === null) {
            return
        }
        if (this.state.lat === 0 || this.state.lng === 0) {
            return
        }
        let _location_id = this.country + ' ' + this.city.id + ' ' + this.district.id
        let _address = '台灣 ' + this.city.name + ' ' + this.district.name + this.detail

        this.setState({ location_id: _location_id }, () => {
            this.setState({ address: _address }, () => {
                if (this.selectedBuilding.id == null)   // Create
                    this.props.createBuilding(this.state)
                else    // Modify
                    this.props.modifyBuilding(this.selectedBuilding.id, this.state)
                this.handleClose();
            })
        })
    }
    handleClose() {
        this.cityOption = []
        this.districtOption = []
        this.country = ''
        this.city = null
        this.district = null
        this.detail = ''
        this.setState({
            address: '',
            lat: 0.0,
            lng: 0.0,
            location_id: '',
            name: '',
        })
        this.toggleModal();
    }
    handleMapMarkerUpdate(marker) {
        const { name, position } = marker
        const lat = position.lat
        const lng = position.lng
        this.state.name === '' ?
            this.setState({
                name, lat, lng
            }) :
            this.setState({
                lat, lng
            })
    }
    toggleModal(e) {
        this.isModalOpen = !this.isModalOpen;
        this.forceUpdate()
    }
    toggleTableClick = (state, rowInfo) => {
        if (rowInfo !== undefined) {
            if (rowInfo.index === this.selectedBuilding.index) {
                return {
                    onClick: () => {
                        this.selectedBuilding = {};
                        this.forceUpdate()
                    }, style: {
                        background: '#EFEFEF'
                    }
                }
            } else {
                return {
                    onClick: () => {
                        this.selectedBuilding = this.props.data[rowInfo.index];
                        this.selectedBuilding.index = rowInfo.index
                        this.forceUpdate()
                    }, style: {
                        background: 'white'
                    }
                }
            }
        } else {
            return {}
        }
    }
    render() {
        const { data, countryOption } = this.props
        const cityOption = this.cityOption;
        const districtOption = this.districtOption;
        // data manipulation btn group style
        let dataManipulation_btnGroup = this.selectedBuilding.id == null ? false : true;

        return (
            <div style={{ marginRight: "51px" }}>
                <POIBtnGroup haveSelected={dataManipulation_btnGroup} checkAction={this.checkAction} />
                <Modal
                    open={this.isModalOpen}
                    onClose={this.handleClose}
                    size='large'
                >
                    <Modal.Content>
                        <Form>
                            <Header size='large'>Building</Header>
                            <Form.Field>
                                <Input placeholder="Building Name" type="text" name="name" value={this.state.name || ''} onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <Input placeholder="Latitude*" type="text" name="lat" value={this.state.lat || ''} onChange={this.handleChange} />
                                </Form.Field>
                                <Form.Field>
                                    <Input placeholder="Longitude*" type="text" name="lng" value={this.state.lng || ''} onChange={this.handleChange} />
                                </Form.Field>
                            </Form.Group>
                            <MapComponent
                                markers={{
                                    name: this.state.name,
                                    position: { lat: this.state.lat, lng: this.state.lng }
                                }}
                                mapMarkerUpdate={this.handleMapMarkerUpdate}
                            />
                            <Header size='large'>Address*</Header>
                            <Form.Field>
                                <Grid columns={3} divided>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Dropdown
                                                placeholder="Country"
                                                search selection
                                                options={countryOption}
                                                name="country"
                                                //defaultValue={this.selectedBuilding.country || ''}
                                                onChange={(event, data) => this.handleDropdownChange(data)} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Dropdown
                                                placeholder="City/Region"
                                                search selection
                                                options={cityOption}
                                                name="city"
                                                //defaultValue={this.selectedBuilding.city || ''}
                                                onChange={(event, data) => this.handleDropdownChange(data)} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Dropdown
                                                placeholder="District"
                                                search selection
                                                options={districtOption}
                                                name="district"
                                                //defaultValue={this.selectedBuilding.district || ''}
                                                onChange={(event, data) => this.handleDropdownChange(data)} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form.Field>
                            <Form.Field>
                                <Input placeholder="Address Detail" type="text" name="detail" defaultValue={this.selectedBuilding.detail || ''} onChange={this.handleChange} />
                            </Form.Field>
                            <Button type="button" onClick={this.toggleModal}>Close</Button>
                            <Button primary type="button" onClick={this.handleSubmit} >Save</Button>
                        </Form>
                    </Modal.Content>
                </Modal>
                <ReactTable
                    getTrProps={this.toggleTableClick}
                    PaginationComponent={Pagination}
                    data={data}
                    filterable
                    columns={[
                        {
                            Header: "Building Name",
                            accessor: "name",
                            filterMethod: (filter, row) => (
                                _.includes(String(row[filter.id]).toLowerCase(), filter.value.toLowerCase())
                            )
                        },
                        {
                            Header: "Address",
                            accessor: "address",
                            filterMethod: (filter, row) => (
                                _.includes(String(row[filter.id]).toLowerCase(), filter.value.toLowerCase())
                            )
                        }
                    ]}
                    defaultPageSize={25}
                    className="-highlight"
                />
            </div>
        )
    }
}


export default BuildingTable
