import actions from '../../constant/actions'
import { targeting_type } from '../../constant/filters'
import ReportServices from '../../services/Report'
import POIServices from '../../services/POI'
import BusinessServices from '../../services/Business';

// Updating the inforamtion Component, including `Overall` and `Setting`
export const filterDateRange = (dateRange) => ({
    type: actions.FILTER_DATE_RANGE,
    filter: dateRange
})

export const toggleSimilarBrand = () => ({
    type: actions.TOGGLE_SIMILAR_BRAND
})

export const switchTargetingType = (targetingType) => ({
    type: actions.SWITCH_TARGETING_TYPE,
    filter: targetingType
})

export const setFilterApiKey = (apikey) => ({
    type: actions.SET_FILTER_API_KEY,
    filter: apikey
})

export const setAdvancedFilter = (settings) => ({
    type: actions.SET_ADVANCED_FILTER,
    filter: settings
})

export const resetFilter = (apikey) => ({
    type: actions.RESET_FILTER,
    filter: apikey
})

export const setTargetingPOISelectedBrand = (selectedBrand) => ({
    type: actions.UPDATE_TARGETING_POI_SELECTED_BRAND,
    filter: selectedBrand
})
export const setTargetingPOISelectedCategory = (selectedCategory) => ({
    type: actions.UPDATE_TARGETING_POI_SELECTED_CATEGORY,
    filter: selectedCategory
})

export const updateOverviewInfo = (result) => ({
    type: actions.UPDATE_REPORT_OVERVIEW_INFO,
    payload: result
})

export const updateDailyVisitors = (result) => ({
    type: actions.UPDATE_REPORT_OVERVIEW_DAILY_VISITORS,
    payload: result
})

export const updateBusinessList = (result) => ({
    type: actions.UPDATE_BUSINESS_LIST,
    payload: result
})

export const updateGeoList = (result) => ({
    type: actions.UPDATE_GEO_LIST,
    payload: result
})

export const updateLocationTable = (result) => ({
    type: actions.UPDATE_LOCATION_TABLE,
    payload: result
})

export const updateCustomReport = (result) => ({
    type: actions.UPDATE_CUSTOM_REPORT,
    payload: result
})

export const updateReportData = (result) => ({
    type: actions.UPDATE_REPORT_DATA,
    payload: result
})

export const fetchOverview = (apikey) => {
    return dispatch => {
        dispatch({ type: actions.PAGE_LOADING })
        const p = ReportServices.fetchOverviewInfo(apikey)
            .then(result => { dispatch(updateOverviewInfo(result)) })
        Promise.resolve(p).then(() => dispatch({ type: actions.PAGE_LOADED }))
            .catch((error) => {
                if (error.status === 401) {
                    dispatch({ type: actions.ADMIN_SESSION_EXPIRED })
                }
                else {
                    dispatch({ type: actions.PAGE_LOADED })
                }
            })
    }
}

export const fetchBusinessList = () => {
    return dispatch => {
        BusinessServices.fetchBusiness()
            .then(result => {
                const businessList = []
                result.map((business) => {
                    businessList.push({
                        text: business.name,
                        value: business.apikey
                    })
                    return businessList
                })
                return dispatch(updateBusinessList(businessList))
            })
    }
}

export const fetchGeoList = (apikey) => {
    return dispatch => {
        POIServices.fetchLocation()
            .then(result => {
                const locationTable = {}
                const geoList = {}
                locationTable['TW'] = '臺灣'
                geoList[targeting_type.COUNTRY] = [{
                    text: '臺灣', value: 'TW'
                }]
                geoList[targeting_type.CITY] = []
                geoList[targeting_type.DISTRICT] = []

                result.map(function (location) {
                    if (location.target_type === 'Country') {

                    }
                    else if (location.target_type === 'City')
                        geoList[targeting_type.CITY].push({
                            text: location.name,
                            value: location.id.toString()
                        })
                    else if (location.target_type === 'District') {
                        const parentIndex = result.findIndex(x => { return x.id === location.parent_id })
                        let parentName = ''
                        if (parentIndex !== -1) {
                            parentName = result[parentIndex].name
                        }
                        geoList[targeting_type.DISTRICT].push({
                            text: parentName + location.name,
                            value: location.id.toString()
                        })
                    }
                    locationTable[location.id] = location.name
                    return locationTable
                })
                dispatch(updateLocationTable(locationTable))
                dispatch(updateGeoList(geoList))
            })
    }
}

export const fetchStatistic = (parameters) => {
    return dispatch => {
        dispatch({ type: actions.PAGE_LOADING })
        const p = ReportServices.fetchStatistic(parameters)
            .then(result => {
                dispatch(updateTargetingTable(result))
            })
        Promise.resolve(p).then(() => dispatch({ type: actions.PAGE_LOADED }))
            .catch((error) => {
                if (error.status === 401) {
                    dispatch({ type: actions.ADMIN_SESSION_EXPIRED })
                }
                else {
                    dispatch({ type: actions.PAGE_LOADED })
                }
            })
    }
}

export const fetchReportData = (parameters) => {
    return dispatch => {
        ReportServices.fetchByPOIList(parameters)
            .then(
                result => {
                    dispatch(updateReportData(result))
                }
            )
    }
}

export const updateTargetingTable = (result) => ({
    type: actions.UPDATE_TARGETING_TABLE,
    payload: result
})

export const fetchByPOIList = (parameters) => {
    return dispatch => {
        dispatch({ type: actions.PAGE_LOADING })
        const p = ReportServices.fetchByPOIList(parameters)
            .then(result => {
                dispatch(updateTargetingTable(result))
            })
        Promise.resolve(p).then(() => dispatch({ type: actions.PAGE_LOADED }))
            .catch((error) => {
                if (error.status === 401) {
                    dispatch({ type: actions.ADMIN_SESSION_EXPIRED })
                }
                else {
                    dispatch({ type: actions.PAGE_LOADED })
                }
            })
    }
}

export const fetchPOICustomReport = (apikey, period) => {
    return dispatch => {
        dispatch({ type: actions.PAGE_LOADING })
        // const p = ReportServices.fetchCustomStatistic(apikey, period)
        //     .then(result => {
        //         dispatch({ type: actions.UPDATE_CUSTOM_POI, payload: result })
        //         dispatch({ type: actions.PAGE_LOADED })
        //     })
        //     .catch(error => {
        //         // error handling
        //         dispatch({ type: actions.PAGE_LOADED })
        //     })
    }
}
