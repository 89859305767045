import React from 'react'
import { Grid, Dropdown, } from 'semantic-ui-react'
import { PieChart, Pie, Tooltip } from 'recharts'
import 'semantic-ui-css/semantic.min.css'
import { prettyNumber } from '../../../../util/DataBuilder'
import { piechart_colors } from '../../../../constant/colors'
import { ResponsiveContainer } from 'recharts'
import '../../../../public/styles/General.css'

import { connect } from 'react-redux'
import { setTargetingPOISelectedBrand, setTargetingPOISelectedCategory } from '../../../../actions/Report'

import { TargetingPieChartBrand, TargetingPieChartCategory } from './PieChartWithoutFilter'

// add multiple color to piechart , colors: from {piechart_colors}
const addPiechartColor = (data, colors, tailColor) => {
    let datatmp = data.map(item => { return { ...item } }) // (deep)copy data
    const result = datatmp.map((item, index) => {
        item["fill"] = colors[index % colors.length];
        return item;
    })

    // 當最後一個顏色和第一個顏色相同時(扣除掉只有一個piechart segment的情況), 使用tailColor
    if ((result.length > 1) && (result[result.length - 1]["fill"] === result[0]["fill"])) {
        result[result.length - 1]["fill"] = tailColor[0]
    }
    return result;
}

// generate {showSelectedItem, avg_staytime_afterFilter, value_afterFilter}
// filterType: "brand" or "category"
// selectedType: selectedBrand or selectedCategory
// type_statistic: brand_statistic or category_statistic
const filterData = (filterType, selectedType, type_statistic) => {
    let avg_staytime_afterFilter;
    let value_afterFilter;
    let showSelectedItem; // show in frontend

    let isShowing = (selectedType === "") ? false : true;
    if (isShowing) {
        if (filterType === 'brand') {
            const brand_statistic = type_statistic;
            let brand_statistic_afterFilter = brand_statistic.filter(element => element.brand_id === selectedType)[0];
            // console.log("[select] brand_statistic_afterFilter: ", brand_statistic_afterFilter);
            avg_staytime_afterFilter = brand_statistic_afterFilter.avg_staytime;
            value_afterFilter = brand_statistic_afterFilter.value; // visit
            showSelectedItem = brand_statistic_afterFilter.name;
        } else if (filterType === 'category') {
            const category_statistic = type_statistic;
            let category_statistic_afterFilter = category_statistic.filter(element => element.category_key === selectedType)[0];
            // console.log("[select] category_statistic_afterFilter", category_statistic_afterFilter);
            avg_staytime_afterFilter = category_statistic_afterFilter.avg_staytime;
            value_afterFilter = category_statistic_afterFilter.value; // visit
            showSelectedItem = category_statistic_afterFilter.name;
        }
        return { showSelectedItem, avg_staytime_afterFilter, value_afterFilter };
    }
}

// generate {poi_statistic_afterFilter_arr}
const filterPOIStatisticArr = (filterType, selectedType, type_statistic) => {
    let poi_statistic_afterFilter_arr; // filter piechar data

    let isShowing = (selectedType === "") ? false : true;
    if (isShowing) {
        if (filterType === 'brand') {
            const brand_statistic = type_statistic;
            let brand_statistic_afterFilter = brand_statistic.filter(element => element.brand_id === selectedType)[0];
            //console.log("[select] brand_statistic_afterFilter: ", brand_statistic_afterFilter);
            poi_statistic_afterFilter_arr = brand_statistic_afterFilter["have_poi_ids"]
        } else if (filterType === 'category') {
            const category_statistic = type_statistic;
            let category_statistic_afterFilter = category_statistic.filter(element => element.category_key === selectedType)[0];
            //console.log("[select] category_statistic_afterFilter", category_statistic_afterFilter);
            poi_statistic_afterFilter_arr = category_statistic_afterFilter["have_poi_ids"]
        }
        return poi_statistic_afterFilter_arr;
    }
}

const renderVisitorPieChartLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name, fill, visit, percent, ...props }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text x={x} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {name}: {prettyNumber(visit)} ({(percent * 100).toFixed(2)}%)
        </text>
    );
};

const renderStayTimePieChartLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name, fill, staytime, percent, ...props }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text x={x} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {name}:{prettyNumber(staytime)} ({(percent * 100).toFixed(2)}%)
        </text>
    );
};


// piechart: staytime
const TargetingPieChartStaytime = ({ poi_statistic }) => {
    return (
        <div className="gray-bg">
            <div>
                <div className="piechart-header">POI stay time [seconds]</div>
            </div>
            <ResponsiveContainer minWidth={400} minHeight={400}>
                <PieChart height={400}>
                    <Pie data={addPiechartColor(poi_statistic, piechart_colors.STAYTIME, piechart_colors.TAIL)}
                        cx={'52%'} cy={200}
                        outerRadius={150}
                        fill="#FF9F33"
                        label={renderStayTimePieChartLabel}
                        dataKey='staytime' />
                    <Tooltip formatter={(value, name, props) => { return Number(value).toFixed(2); }} />
                </PieChart>
            </ResponsiveContainer>
        </div>
    )
}

// piechart: visitor
const TargetingPieChartVisitor = ({ poi_statistic }) => (
    <div className="gray-bg">
        <div>
            <div className="piechart-header">POI visitor [visitor count]</div>
        </div>
        <ResponsiveContainer minWidth={400} minHeight={400}>
            <PieChart height={400}>
                <Pie data={addPiechartColor(poi_statistic, piechart_colors.VISITOR, piechart_colors.TAIL)}
                    cx={'52%'} cy={200}
                    outerRadius={150}
                    fill="#0047b3"
                    label={renderVisitorPieChartLabel}
                    dataKey='visit' />
                <Tooltip formatter={(value, name, props) => { return Number(value).toFixed(2); }} />
            </PieChart>
        </ResponsiveContainer>
    </div>
)

class TargetingBrandFilter extends React.Component {
    render() {
        const {
            brand_statistic,
            selectedBrand, onSelectedBrandChange,
        } = this.props;
        const formattedBrand_statistic = brand_statistic.map(item => ({
            text: `${item.name}  ${item.value}`,
            value: item.brand_id
        }));

        let isSelected = (selectedBrand === "") ? false : true;
        return (
            <Grid style={{ lineHeight: "10px", height: "600px" }} columns={3}>
                <Grid.Row>
                    <Grid.Column width={2}> {/* col_1: filter, statistic */}
                        <h3 className="ui header">Brand Filter</h3>
                        <Dropdown
                            className="dropdown-rounded"
                            placeholder='Select brand...'
                            search
                            selection
                            options={formattedBrand_statistic}
                            onChange={(e, { value }) => {
                                onSelectedBrandChange(value);
                            }}
                        />
                        {isSelected ?
                            <TargetingAfterFilterStatistic
                                filterType={"brand"}
                                selectedType={selectedBrand}
                                type_statistic={brand_statistic}
                            />
                            : null
                        }
                    </Grid.Column>
                    <Grid.Column width={7}> {/* col_2: filter piechart of Staytime */}
                        {isSelected ?
                            <TargetingPieChartStaytime
                                poi_statistic={filterPOIStatisticArr("brand", selectedBrand, brand_statistic)}
                            />
                            : null
                        }
                    </Grid.Column>
                    <Grid.Column width={7}> {/* col_3: filter piechart of Visitor */}
                        {isSelected ?
                            <TargetingPieChartVisitor
                                poi_statistic={filterPOIStatisticArr("brand", selectedBrand, brand_statistic)}
                            />
                            : null
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

class TargetingCategoryFilter extends React.Component {
    render() {
        const {
            // data
            category_statistic,
            // from actions
            selectedCategory, onSelectedCategoryChange,
        } = this.props;
        // format category_statistic: for category
        const formattedCategory_statistic = category_statistic.map(item => ({
            text: `${item.name}  ${item.value}`,
            value: item.category_key
        }));

        let isSelected = (selectedCategory === "") ? false : true;
        return (
            <Grid style={{ lineHeight: "10px", height: "600px" }} columns={3}>
                <Grid.Row>
                    <Grid.Column width={2}> {/* col_1: filter, statistic */}
                        <h3 className="ui header">Category Filter</h3>
                        <Dropdown
                            className="dropdown-rounded"
                            placeholder='Select category...'
                            search
                            selection
                            options={formattedCategory_statistic}
                            onChange={(e, { value }) => {
                                onSelectedCategoryChange(value);
                            }}
                        />
                        {isSelected ?
                            <TargetingAfterFilterStatistic
                                filterType={"category"}
                                selectedType={selectedCategory}
                                type_statistic={category_statistic}
                            />
                            : null
                        }
                    </Grid.Column>
                    <Grid.Column width={7}> {/* col_2: filter piechart of Staytime */}
                        {isSelected ?
                            <TargetingPieChartStaytime
                                poi_statistic={filterPOIStatisticArr("category", selectedCategory, category_statistic)}
                            />
                            : null
                        }
                    </Grid.Column>
                    <Grid.Column width={7}> {/* col_3: filter piechart of Visitor */}
                        {isSelected ?
                            <TargetingPieChartVisitor
                                poi_statistic={filterPOIStatisticArr("category", selectedCategory, category_statistic)}
                            />
                            : null
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

class TargetingAfterFilterStatistic extends React.Component {
    render() {
        const { filterType, selectedType, type_statistic } = this.props;
        let { showSelectedItem, avg_staytime_afterFilter, value_afterFilter } = filterData(filterType, selectedType, type_statistic);

        return (
            <div className="piechart-selectedname">
                <p> {showSelectedItem} </p>
                <li>Avg Stay Time: {prettyNumber(avg_staytime_afterFilter)}</li>
                <li>Total Visitor: {prettyNumber(value_afterFilter)}</li>
            </div>
        )
    }
}

export class TargetingPieChartArea extends React.Component {
    render() {
        const {
            brand_statistic, category_statistic,
            isSelectedBusinessAndDate,
            selectedBrand, selectedCategory,
            onSelectedBrandChange, onSelectedCategoryChange
        } = this.props;
        return (
            isSelectedBusinessAndDate ?
                <div>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <TargetingPieChartBrand
                                    brandStatistic={brand_statistic}
                                    labelNumber={5}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <TargetingPieChartCategory
                                    categoryStatistic={category_statistic}
                                    labelNumber={5}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <TargetingCategoryFilter
                        category_statistic={category_statistic}
                        brand_statistic={brand_statistic}
                        onSelectedBrandChange={(value) => onSelectedBrandChange(value)}
                        onSelectedCategoryChange={(value) => onSelectedCategoryChange(value)}
                        selectedBrand={selectedBrand}
                        selectedCategory={selectedCategory}
                    />
                    <TargetingBrandFilter
                        category_statistic={category_statistic}
                        brand_statistic={brand_statistic}
                        onSelectedBrandChange={(value) => onSelectedBrandChange(value)}
                        onSelectedCategoryChange={(value) => onSelectedCategoryChange(value)}
                        selectedBrand={selectedBrand}
                        selectedCategory={selectedCategory}
                    />
                </div>
                : <h3 className="ui header">Please select business and date!</h3>
        )
    }
}

const mapStateToProps = (state) => {
    const selectedBrand = state.filter.selected_brand;
    const selectedCategory = state.filter.selected_category;
    const apikey = state.filter.apikey;
    const dateRange = state.filter.dateRange;
    const isSelectedBusinessAndDate = (apikey !== undefined && dateRange !== undefined) ? true : false;

    return ({
        selectedBrand: selectedBrand,
        selectedCategory: selectedCategory,
        isSelectedBusinessAndDate: isSelectedBusinessAndDate
    })
}

const mapDispatchToProps = (dispatch) => ({
    onSelectedBrandChange: (value) => {
        dispatch(setTargetingPOISelectedBrand(value))
    },
    onSelectedCategoryChange: (value) => {
        dispatch(setTargetingPOISelectedCategory(value))
    }
})

const TargetingPieChartAreaContainer = connect(mapStateToProps, mapDispatchToProps)(TargetingPieChartArea)
export default TargetingPieChartAreaContainer
