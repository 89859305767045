import React, { Component } from 'react'
import { Grid, Divider, Segment, Header } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ImageSlider from './ImageSlider'

class About extends Component {
    render() {
        return (
            <div>
                {/* About header */}
                <Grid style={{ marginTop: "14px" }}>
                    <Grid.Column width={1}>
                        <FontAwesomeIcon icon="info-circle" pull="right" size="lg" />
                    </Grid.Column>
                    <Grid.Column width={9}>
                        <p className='header-name'> About </p>
                    </Grid.Column>
                </Grid>
                <Divider />

                <div className="tap-content">
                    <Grid divided='vertically'>
                        {/* Overview about */}
                        <Grid.Row >
                            <Grid.Column width={7}>
                                <OverviewAboutSeg />
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <div className="gray-bg slider-container">
                                    <ImageSlider type={"overview"} />
                                </div>
                            </Grid.Column>
                        </Grid.Row>

                        {/* Report about */}
                        <Grid.Row >
                            <Grid.Column width={7}>
                                <ReportAboutSeg />
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <div className="gray-bg slider-container">
                                    <ImageSlider type={"report"} />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div >

            </div >
        )
    }
}

const OverviewAboutSeg = () =>
    <Segment className="segment-none-border">
        <Grid style={{ lineHeight: "20px" }}>
            <Header style={{ fontWeight: "bolder" }} size="medium">Overview</Header>
            <Grid.Row>
                <Grid.Column>
                    顯示最近30天內「司圖科技」所擁有的新/舊訪客數量，以及訪客手機系統iOS/Android數量。<br />
                    訪客需停留30秒以上，才會記錄至數據中。
                                            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    右上曲線圖：訪客數量趨勢，如(圖一)顯示2019/06/28總訪客數為280人，其中有5位新訪客，275位舊訪客<br />
                    左下圓餅圖：新舊訪客數量與比例，如(圖二)<br />
                    右下圓餅圖：訪客所使用的手機作業系統數量與比例，如(圖三)
                                            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <div className="filter-label">名詞解釋</div>
                    <li>Total Visits：停留30秒以上的參訪次數</li>
                    <li>Returning Visitor：舊訪客，曾經接觸過的訪客</li>
                    <li>New Visitor：新訪客</li>
                    <li>Unique Vistor：停留30秒以上，不重複計算的訪客人數</li>
                    <li>Avg Frequency：平均每訪客參訪次數（參訪次數/不重複計算的訪客人數）</li>
                    <li>Avg Stay Time：平均停留時間（總累積停留時間/總參訪次數）</li>
                    <li>Bouncing Rate：跳出率（停留時間少於30秒的參訪次數/總參訪次數)</li>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>

const ReportAboutSeg = () =>
    <Segment className="segment-none-border">
        <Grid style={{ lineHeight: "20px" }}>
            <Header style={{ fontWeight: "bolder" }} size="medium">Report</Header>
            <Grid.Row>
                <Grid.Column>
                    屬於您的訪客數據都會在Report頁面中呈現，可在Period選擇時間區間，選擇完後按下Apply刷新資料。
                                            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <div className="filter-label">Global</div>
                    查看您擁有的POI點位所在的地理位置
                                            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <div className="filter-label">Pie</div>
                    依據上方選擇的時間區間<br />
                    Brand圓餅圖：對各個店家感興趣之訪客人數與比例，如(圖一)<br />
                    Category圓餅圖：對不同類別感興趣之訪客人數與比例，如(圖二)<br />
                    Category Filter：可選擇想看的類別，顯示類別中店家的訪客人數與平均停留秒數，如(圖三)<br />
                    Brand Filter：可選擇想看的品牌，顯示品牌各分店的訪客人數與平均停留秒數，如(圖四)<br />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <div className="filter-label">Trend</div>
                    可以搜尋點選至多10個POI，以曲線圖呈現不同店家的訪客人數、加總人數以及平均人數，觀察訪客數量趨勢，且將滑鼠移至曲線上可查看每天數據，瞭解日期與數據之間的關係，如(圖五)
                                            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <div className="filter-label">POI</div>
                    以列表呈現您所擁有的品牌POI與其訪客數、平均停留時間。
                                            </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>


export default About;

