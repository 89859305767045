import actions from '../../constant/actions'
import AdminServices from '../../services/Admin'
import BusinessServices from '../../services/Business'

const updateAdmin = (data) => ({
    type: actions.UPDATE_ADMIN,
    payload: data
})
const updateBusiness = (data) => ({
    type: actions.UPDATE_BUSINESS,
    payload: data
})

export const fetchAdmin = () => {
    return dispatch => {
        BusinessServices.fetchBusiness()
            .then(result => dispatch(updateBusiness(result)))
        AdminServices.fetchAdmin()
            .then(result => dispatch(updateAdmin(result)))
    }
}

export const createAdmin = (email, data) => {
    return dispatch => {
        AdminServices.createAdmin(email, data)
            .then(response => AdminServices.fetchAdmin()
                .then(result => dispatch(updateAdmin(result))))
    }
}

export const modifyAdmin = (email, data) => {
    return dispatch => {
        AdminServices.modifyAdmin(email, data)
            .then(response => AdminServices.fetchAdmin()
                .then(result => dispatch(updateAdmin(result))))
    }
}

export const deleteAdmin = (email) => {
    return dispatch => {
        AdminServices.deleteAdmin(email)
            .then(response =>AdminServices.fetchAdmin()
                .then(result => dispatch(updateAdmin(result))))
    }
}


