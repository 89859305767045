import LoginServices from '../services/Login';
import actions from '../constant/actions'

export const adminActions = {
    login,
    logout,
    requestToken,
    rememberedAdmin
};

function login(username, password) {
    return dispatch => {
        LoginServices.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    return user
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function success(user) { return { type: actions.ADMIN_LOGIN_SUCCESS, user } }
    function failure(error) { return { type: actions.ADMIN_LOGIN_FAILURE, error } }
}

function rememberedAdmin() {
    return dispatch => {
        LoginServices.rememberedAdmin()
            .then(
                user => {
                    dispatch(success(user));
                    return user
                }
            )
    }
    function success(user) { return { type: actions.REMEMBERED_ADMIN, user } }
}


function logout() {
    return dispatch => {
        LoginServices.logout()
            .then(
                response => {
                    dispatch(success(response));
                    return response
                }
            )
    }
    function success(response) { return { type: actions.ADMIN_LOGOUT, response } }
}

function requestToken(dispatch) {

    const tokenPromise = LoginServices.requestToken()
        .then(token => {
            dispatch(success(token));
            return token.tokenExpiry ? Promise.resolve(token.tokenExpiry) : Promise.reject(token);
        })

    function success(token) { return { type: actions.ADMIN_TOKEN_REFRESHED, token } }

    dispatch({
        type: actions.ADMIN_REFRESHING_TOKEN,
        tokenPromise
    })

    return tokenPromise
}



