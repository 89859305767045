import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'
import 'semantic-ui-css/semantic.min.css'
import { fetchBusinessList, setFilterApiKey } from '../../actions/Report'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ReportTitle extends Component {
    constructor(props) {
        super(props)
        this.props.fetchBusinessList()
    }
    render() {
        const { reportTitleType } = this.props
        return (
            <Grid style={{ marginTop: "14px" }}>
                <Grid.Column width={1}>
                    <FontAwesomeIcon icon="file-alt" pull="right" size="2x" />
                </Grid.Column>
                <Grid.Column width={9}>
                    <p className='header-name'> {reportTitleType} </p>
                </Grid.Column>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    role: state.admin.role,
    businessList: state.filter.businessList,
    apikey: state.filter.apikey
})
const mapDispatchToProps = (dispatch) => ({
    fetchBusinessList: () => {
        dispatch(fetchBusinessList())
    },
    setFilterApiKey: (apikey) => {
        dispatch(setFilterApiKey(apikey))
    }
})

const ReportTitleContainer = connect(mapStateToProps, mapDispatchToProps)(ReportTitle)

export default ReportTitleContainer

export { ReportTitleContainer as ReportTitle }
