import React, { Component } from 'react'
import { PieChart, Pie, Tooltip } from 'recharts'
import 'semantic-ui-css/semantic.min.css'
import { piechart_colors } from '../../../../constant/colors'
import { ResponsiveContainer } from 'recharts'
import '../../../../public/styles/General.css'

const addPiechartColor = (data, colors, tailColor) => {
    let datatmp = data.map(item => { return { ...item } }) // (deep)copy data
    const result = datatmp.map((item, index) => {
        item["fill"] = colors[index % colors.length];
        return item;
    })

    // 當最後一個顏色和第一個顏色相同時(扣除掉只有一個piechart segment的情況), 使用tailColor
    if ((result.length > 1) && (result[result.length - 1]["fill"] === result[0]["fill"])) {
        result[result.length - 1]["fill"] = tailColor[0]
    }
    return result;
}

const findCutOff = (_data, labelNumber) => {
    const valueArr = []
    _data.forEach(data => valueArr.push(data.value))

    return valueArr.sort((a, b) => b - a)[labelNumber - 1]
}


export class TargetingPieChartBrand extends Component {

    constructor(props) {
        super(props)
        this.state = {
            brandStatistic: props.brandStatistic,
        }
        this.cutOff = false
        this.labelNumber = props.labelNumber
    }

    componentDidMount() {
        this.cutOff = findCutOff(this.state.brandStatistic, this.labelNumber)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.brandStatistic !== state.brandStatistic) {
            return { brandStatistic: props.brandStatistic }
        } else {
            return null
        }
    }

    renderTopFiveLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name, fill, value, percent, ...props }) => {
        const RADIAN = Math.PI / 180;
        const radius = outerRadius + 20;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        if (value >= this.cutOff) {
            return (
                <text x={x} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {name}:{value} ({(percent * 100).toFixed(2)}%)
                </text>
            );
        } else { return '' }

    }


    render() {
        const { brandStatistic } = this.state

        return (
            <div className="gray-bg">
                <div>
                    <div className="piechart-header">Brand [visitor count]</div>
                </div>
                <ResponsiveContainer minWidth={600} minHeight={400}>
                    <PieChart height={400}>
                        <Pie
                            data={addPiechartColor(brandStatistic, piechart_colors.BRAND, piechart_colors.TAIL)}
                            cx={'50%'}
                            cy={200}
                            outerRadius={150}
                            label={this.renderTopFiveLabel}
                            labelLine={false}
                            dataKey='value'
                        />
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </div >
        )
    }

}

export class TargetingPieChartCategory extends Component {

    constructor(props) {
        super(props)
        this.state = {
            categoryStatistic: props.categoryStatistic,
        }
        this.cutOff = false
        this.labelNumber = props.labelNumber
    }

    componentDidMount() {
        this.cutOff = findCutOff(this.state.categoryStatistic, this.labelNumber)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.categoryStatistic !== state.categoryStatistic) {
            return { categoryStatistic: props.categoryStatistic }
        } else {
            return null
        }
    }

    renderTopFiveLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name, fill, value, percent }) => {

        const RADIAN = Math.PI / 180;
        const radius = outerRadius + 20;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        if (value >= this.cutOff) {
            return (
                <text x={x} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {name}:{value} ({(percent * 100).toFixed(2)}%)
                </text>
            );
        } else { return '' }
    }

    render() {
        const { categoryStatistic } = this.state

        return (
            <div className="gray-bg">
                <div>
                    <div className="piechart-header">Category [visitor count]</div>
                </div>
                <ResponsiveContainer minWidth={600} minHeight={400}>
                    <PieChart height={400}>
                        <Pie
                            data={addPiechartColor(categoryStatistic, piechart_colors.CATEGORY, piechart_colors.TAIL)}
                            cx={'50%'}
                            cy={200}
                            outerRadius={150}
                            fill="#8884d8"
                            label={this.renderTopFiveLabel}
                            labelLine={false}
                            dataKey='value'
                        />
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        )
    }
}

