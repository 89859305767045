import DataBuilder from './DataBuilder'
import { time, device, report_type } from '../constant/filters'
import moment from 'moment'

const timeToMomentKeyword = {}
timeToMomentKeyword[time.DATE] = 'YYYY-MM-DD'
timeToMomentKeyword[time.MONTH] = 'MM'
timeToMomentKeyword[time.WEEK] = 'dddd'
timeToMomentKeyword[time.DAY] = 'Do'
timeToMomentKeyword[time.HOUR] = 'HH:00'

export default class DeviceDataBuilder extends DataBuilder {
    constructor(props) {
        super(props)
        // table
        this.generateTableData = this.generateTableData.bind(this)
        // piechart
        this.generateTargetingData = this.generateTargetingData.bind(this)
        this.generateTimeData = this.generateTimeData.bind(this)
        // trendchart
        this.generateTrendChartData = this.generateTrendChartData.bind(this)
    }
    generateTrendChartData(days) {
        const pre_data1 = this.groupByTime({data:this.data, momentKeyword:timeToMomentKeyword[time.DATE]})
        const pre_data2 = this.groupByTarget({data:pre_data1, reportType:report_type.DEVICE_TIME})
        const result = []
        for (let i = 0; i < days; i++) {
            const date = moment().subtract(days-i, 'days').format('YYYY-MM-DD')
            if (pre_data2[date] === undefined)
                result.push({name: date, visitors: 0})
            else {
                const android_visitors = pre_data2[date]['all']['Android']['total']
                const ios_visitors = pre_data2[date]['all']['iOS']['total']
                result.push({ name: date, visitors:android_visitors + ios_visitors })
            }
        }
        return result
    }
    generateTargetingData() {
        const pre_data1 = this.groupByTime({data:this.data})
        const pre_data2 = this.groupByTarget({data:pre_data1, targetType:this.targetingType, reportType:report_type.GEO_TIME})
        const result = []
        const geo_data = pre_data2['all']
        let android_visitors = 0
        let ios_visitors = 0
        for(let geo in geo_data) {
            android_visitors += geo_data[geo]['Android']['total']
            ios_visitors += geo_data[geo]['iOS']['total']
        }
        result.push({name: 'Android', value: android_visitors})
        result.push({name: 'iOS', value: ios_visitors})
        return result
    }
    generateTimeData() {
        if (this.time === time.DATE) return []
        const pre_data1 = this.groupByTime({data:this.data, momentKeyword:timeToMomentKeyword[this.time]})
        const pre_data2 = this.groupByTarget({data:pre_data1, reportType:report_type.DEVICE_TIME})
        const result = []
        for (let time in pre_data2) {
            const time_data = pre_data2[time]
            const android_visitors =  time_data['all']['Android']['total']
            const ios_visitors = time_data['all']['iOS']['total']
            result.push({name: time, value: android_visitors + ios_visitors})
        }
        return result
    }
    generateTableData() {
        const pre_data1 = this.groupByTime({data:this.data, momentKeyword:timeToMomentKeyword[this.time]})
        const pre_data2 = this.groupByTarget({data:pre_data1, reportType:report_type.DEVICE_TIME})
        const pre_data3 = this.filterByDevice({data:pre_data2})
        const result = []
        for (let time in pre_data3) {
            const geo_data = pre_data3[time]
            for (let key in geo_data['all']) {
                if (this.device === device.ALL || this.device === key) {
                    let temp_data = {
                        totalVisitors:geo_data['all'][key]['total'],
                        newVisitors:geo_data['all'][key]['new'],
                        returningVisitors: geo_data['all'][key]['return'],
                        avgFrequency: geo_data['all'][key]['frequency'] / this.divider,
                        bouncingRate: geo_data['all'][key]['bounce_rate'] / this.divider,
                        avgStayTime: geo_data['all'][key]['stay_time'] / this.divider
                    }
                    temp_data[this.target_accessor] = key
                    temp_data[this.time_accessor] = time
                    result.push(temp_data)
                }
            }
        }
        return result
    }
}
