// Basic Filter

export const targeting_type = {
    COUNTRY: 0,
    CITY: 1,
    DISTRICT: 2,
    DEVICE: 3,
    POI: 4,
    CUSTOM: 5
}

export const report_type = {
    GEO_TIME: 'GEO_TIME',
    DEVICE_TIME: 'DEVICE_TIME',
    POI_TIME: 'POI_TIME',
    GEO_DEVICE: 'GEO_DEVICE',
}

export const date_range = {
    YESTERDAY : 'Yesterday',
    LASTWEEK : 'Last Week',
    LAST30DAYS : 'Last 30 Days',
    LASTMONTH : 'Last Month',
    LAST3MONTHS : 'Last 3 Months',
    LAST6MONTHS : 'Last 6 Months',
    YEARTODATE : 'Year to Date',
    LASTYEAR : 'Last Year'
}

// Advanced Filter

export const distance = {
    FIVE_KM: '5km',
    TEN_KM: '10km',
    FIFTEEN_KM: '15km',
    TWENTY_KM: '20km',
    CLEAR: 'Clear',
}

export const time = {
    HOUR: 0,
    DAY: 1,
    WEEK: 2,
    MONTH: 3,
    DATE: 4
}

export const device = {
    ALL: 'All',
    IOS: 'iOS',
    ANDROID: 'Android'
}

export const geo = {
    COUNTRY: 'Country',
    CITY: 'City',
    DISTRICT: 'District'
}

// Header: used to display
// accessor: the name of the variable connected the data from the database

export const targeting_type_header_accessor = [
    { Header: 'Country', accessor: 'country' },
    { Header: 'City', accessor: 'city' },
    { Header: 'District', accessor: 'district' },
    { Header: 'Device', accessor: 'device' },
    { Header: 'POI', accessor: 'poi'},
    { Header: 'Custom POI', accessor: 'custom'}
]


export const time_header_accessor = [
    { Header: 'Hour', accessor: 'hour' },
    { Header: 'Day', accessor: 'day' },
    { Header: 'Week', accessor: 'week' },
    { Header: 'Month', accessor: 'month' },
    { Header: 'Date', accessor: 'date' }
]

