import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Dropdown } from 'semantic-ui-react'

import { businessActions } from '../../actions/Business'

import 'semantic-ui-css/semantic.min.css'


class PickPOIPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filter_brand: undefined,
            selected: []
        }

        this.searchPOIWithBrand = this.searchPOIWithBrand.bind(this)
        this.addPOI = this.addPOI.bind(this)
        this.setFilterBrand = this.setFilterBrand.bind(this)
        this.selectPOI = this.selectPOI.bind(this)
        this.searchOption = this.searchOption.bind(this)
    }

    searchPOIWithBrand(poi_option) {
        const target_brand = this.state.filter_brand
        const filter_brand = (poi, brand_id) => {
            if (!brand_id) return true
            if (poi.brand_id === brand_id) return true
            else return false
        }
        const poi_elements = []
        poi_option.forEach(
            (poi) => {
                if (filter_brand(poi, target_brand)) {
                    poi_elements.push(poi)
                }
            }
        )
        return poi_elements
    }
    addPOI(e) {
        let new_list = this.props.poi_list
        this.state.selected.forEach(poi_id => {
            new_list.push(poi_id)
        })
        new_list.sort((a, b) => { return a - b })
        new_list = _.uniq(new_list)
        this.props.modifyPickPOI(new_list)
    }
    setFilterBrand(e, { value }) {
        this.setState({ filter_brand: value })
    }
    selectPOI(e, { value }) {
        this.setState({ selected: value })
    }
    searchOption(options, query) {
        const filter_option = this.searchPOIWithBrand(options)

        return filter_option
    }
    render() {
        const { brand_option, poi_option } = this.props
        const { filter_brand } = this.state
        return (
            <div>
                <Dropdown id='brand_filter' options={brand_option}
                    selection
                    placeholder='Filter by Brand name'
                    onChange={this.setFilterBrand}
                    value={filter_brand}
                />
                <Button basic size='tiny' color='blue' onClick={(e) => { this.setFilterBrand(e, { value: undefined }) }}>Clear</Button>
                <br />
                <Dropdown
                    placeholder='type something to trigger filter'
                    fluid
                    search={this.searchOption}
                    selection multiple
                    options={poi_option}
                    onChange={this.selectPOI}
                >
                </Dropdown>
                <br />
                <Button icon='plus' content='Add'
                    onClick={(e) => { this.addPOI(e) }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const { poi, brand } = state.data
    const { poi_list } = state.business
    const brand_option = []
    const poi_option = []
    brand.forEach(
        brand => {
            brand_option.push({
                text: brand.name,
                value: brand.id
            })
        }
    )
    poi.forEach(
        poi => {
            poi_option.push({
                text: poi.name,
                value: poi.id,
                brand_id: poi.brand_id
            })
        }
    )

    return {
        poi,
        brand,
        brand_option,
        poi_option,
        poi_list,
        ...ownProps
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        modifyPickPOI: (poi_list) => { dispatch(businessActions.modifyPickPOI(poi_list)) }
    }
}

const PickPOIPickerContainer = connect(mapStateToProps, mapDispatchToProps)(PickPOIPicker)
export { PickPOIPickerContainer }
