const POIServices = {
    // Fetching service
    fetchInfo: () => fetchInfo(),
    fetchPOI: () => fetchPOI(),
    fetchBrand: () => fetchBrand(),
    fetchBuilding: () => fetchBuilding(),
    fetchCategory: () => fetchCategory(),
    fetchLocation: () => fetchLocation(),
    // Creating service
    createPOI: (data) => createPOI(data),
    createBrand: (data) => createBrand(data),
    createBuilding: (data) => createBuilding(data),
    // Modifying service
    modifyPOI: (id, data) => modifyPOI(id, data),
    modifyBrand: (id, data) => modifyBrand(id, data),
    modifyBuilding: (id, data) => modifyBuilding(id, data),
    // Deleting service
    deletePOI: (id) => deletePOI(id),
    deleteBrand: (id) => deleteBrand(id),
    deleteBuilding: (id) => deleteBuilding(id)
}
export default POIServices

/* general API prefix & request options */
const API_PREFIX = '/yanjing/api';
const generalOption = (action, data) => {
    return {
        method: action,
        credentials: 'include'
    }
};

const fetchInfo = () => {
    let requestOption = generalOption('GET');
    return fetch(API_PREFIX + '/poi/overall', requestOption)
        .then(response => {
            return response.json()
        })
}

const fetchPOI = () => {
    let requestOption = generalOption('GET');
    return fetch(API_PREFIX + '/poi/', requestOption)
        .then(response => {
            return response.json()
        })
}

const fetchBrand = () => {
    let requestOption = generalOption('GET');
    return fetch(API_PREFIX + '/poi/brand/', requestOption)
        .then(response => {
            return response.json()
        })
}

const fetchBuilding = () => {
    let requestOption = generalOption('GET');
    return fetch(API_PREFIX + '/poi/building/', requestOption)
        .then(response => {
            return response.json()
        })
}

const fetchCategory = () => {
    let requestOption = generalOption('GET');
    return fetch(API_PREFIX + '/poi/category/', requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response)
            }
            return response.json()
        })
        .then(result => {
            let categories = []
            result.map((item, index) => {
                categories[item.id] = item.category
                return true
            })
            return categories
        }
        )
}
const fetchLocation = () => {
    let requestOption = generalOption('GET');
    return fetch(API_PREFIX + '/poi/location/', requestOption)
        .then(response => {
            return response.json()
        })
}


const createPOI = (data) => {
    let requestOption = generalOption('POST', data);
    requestOption.headers = { 'Content-Type': 'application/json' }
    requestOption.body = JSON.stringify(data)
    return fetch(API_PREFIX + `/poi/`, requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response.json();
        })
}

const createBrand = (data) => {
    let requestOption = generalOption('POST', data);
    requestOption.headers = { 'Content-Type': 'application/json' }
    requestOption.body = JSON.stringify(data)
    return fetch(API_PREFIX + '/poi/brand/', requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response.json();
        })
}

const createBuilding = (data) => {
    let requestOption = generalOption('POST', data);
    requestOption.headers = { 'Content-Type': 'application/json' }
    requestOption.body = JSON.stringify(data)
    return fetch(API_PREFIX + '/poi/building/', requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response.json();
        })
}

const modifyPOI = (id, data) => {
    const requestOption = generalOption('PUT', data);
    requestOption.headers = { 'Content-Type': 'application/json' }
    requestOption.body = JSON.stringify(data)
    return fetch(API_PREFIX + '/poi/' + id, requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response)
            }
            return response.json()
        })
}

const modifyBrand = (id, data) => {
    const requestOption = generalOption('PUT', data);
    requestOption.headers = { 'Content-Type': 'application/json' }
    requestOption.body = JSON.stringify(data)
    return fetch(API_PREFIX + '/poi/brand/' + id, requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response)
            }
            return response.json()
        })
}

const modifyBuilding = async (id, data) => {

    const requestOption = generalOption('PUT', data);
    requestOption.headers = { 'Content-Type': 'application/json' }
    requestOption.body = JSON.stringify(data)

    return fetch(API_PREFIX + '/poi/building/' + id, requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response)
            }
            return response.json()
        })
}


const deletePOI = (id) => {
    const requestOption = generalOption('PUT');
    return fetch(API_PREFIX + '/poi/delete/' + id, requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response.json();
        })
}

const deleteBrand = (id) => {
    const requestOption = generalOption('PUT');
    return fetch(API_PREFIX + '/poi/brand/delete/' + id, requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response.json();
        })
}

const deleteBuilding = (id) => {
    const requestOption = generalOption('PUT')
    return fetch(API_PREFIX + '/poi/building/delete/' + id, requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response.json();
        })
}
