import actions from '../../constant/actions'
import BusinessServices from '../../services/Business'
import POIServices from '../../services/POI'

export const businessActions = {
    fetchBusiness,
    createBusiness,
    modifyBusiness,
    deleteBusiness,
    selectBusiness,
    openBusinessModal,
    closeBusinessModal,
    getPickPOI,
    setPickPOI,
    openPickModal,
    closePickModal,
    modifyPickPOI,
    updatePickPOI
}

function openBusinessModal() {
    return { type: actions.BUSINESS_MODAL_OPEN }
}

function closeBusinessModal() {
    return { type: actions.BUSINESS_MODAL_CLOSE }
}

function openPickModal(apikey) {
    return dispatch => {
        dispatch(getPickPOI(apikey))
        dispatch(togglePickModal())
    }
}

function closePickModal() {
    return dispatch => {
        dispatch(togglePickModal())
    }
}

function togglePickModal() {
    return { type: actions.BUSINESS_TOGGLE_PICKPOI }
}

function selectBusiness(index, business) {
    return {
        type: actions.BUSINESS_SELECT,
        index: index,
        payload: business
    }
}

/* modifyPickPOI is just modify ongoing list, and updatePickPOI will save changes to DB then update original_list */
function modifyPickPOI(poi_list) {
    return {
        type: actions.BUSINESS_MODIFY_PICKPOI,
        payload: poi_list
    }
}
function updatePickPOI(poi_list) {
    return {
        type: actions.BUSINESS_UPDATE_PICKPOI,
        payload: poi_list
    }
}

const updateBusiness = (data) => ({
    type: actions.UPDATE_BUSINESS,
    payload: data
})
const updateBrand = (data) => ({
    type: actions.UPDATE_BRAND,
    payload: data
})

function fetchBusiness() {
    return dispatch => {
        POIServices.fetchBrand()
            .then(result => dispatch(updateBrand(result)))
        BusinessServices.fetchBusiness()
            .then(result => dispatch(updateBusiness(result)))
    }
}

function createBusiness(data) {
    return dispatch => {
        BusinessServices.createBusiness(data)
            .then(response => BusinessServices.fetchBusiness()
                .then(result => dispatch(updateBusiness(result))))
    }
}

function modifyBusiness(apikey, data) {
    return dispatch => {
        BusinessServices.modifyBusiness(apikey, data)
            .then(response => BusinessServices.fetchBusiness()
                .then(result => dispatch(updateBusiness(result))))
    }
}

function deleteBusiness(apikey) {
    return dispatch => {
        BusinessServices.deleteBusiness(apikey)
            .then(response =>BusinessServices.fetchBusiness()
                .then(result => dispatch(updateBusiness(result))))
    }
}

function getPickPOI(apikey) {
    return dispatch => {
        BusinessServices.fetchPickedPOIList(apikey)
            .then(response => {
                dispatch(updatePickPOI(response))
            })
    }
}

function setPickPOI(apikey, poi_list) {
    return dispatch => {
        BusinessServices.setPickPOIList(apikey, poi_list)
            .then(response => dispatch(updatePickPOI(response)))
    }
}
