import actions from '../constant/actions';

const initialState = {
	admin: [],
	business: [],
	brand: [],
	building: [],
	poi: [],
	category: [],
	location: []
};

export function data(state = initialState, action) {
	switch (action.type) {
		case actions.UPDATE_ADMIN:
			return {
				...state,
				admin: action.payload
			}
		case actions.UPDATE_BUSINESS:
			return {
				...state,
				business: action.payload
			}
		case actions.UPDATE_BRAND:
			return {
				...state,
				brand: action.payload
			}
		case actions.UPDATE_BUILDING:
			return {
				...state,
				building: action.payload
			}
		case actions.UPDATE_POI:
			return {
				...state,
				poi: action.payload
			}
		case actions.UPDATE_CATEGORY:
			return {
				...state,
				category: action.payload
			}
		case actions.UPDATE_LOCATION:
			return {
				...state,
				location: action.payload

			}
		default:
		return state;
	}
}
export default data
