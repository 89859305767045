import React from 'react'
import { Grid } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const POITitle = () => (
    <Grid style={{ marginTop: "14px" }}>
        <Grid.Column width={1}>
            <FontAwesomeIcon icon="map-marked-alt" pull="right" size="lg" />
        </Grid.Column>
        <Grid.Column width={9}>
            <p className='header-name'> POI Setting</p>
        </Grid.Column>
    </Grid>
);

export default POITitle
