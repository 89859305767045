import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Form, Button, Header, Segment, Image, Container, List, Icon } from 'semantic-ui-react';
import { Message } from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import { adminActions } from '../actions/App';

import 'semantic-ui-css/semantic.min.css';
import '../public/styles/General.css';
import logo_locofolio from '../public/source/logo_locofolio.png';

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            remembered: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    handleRememberMe = (e) => {
        const { remembered } = this.state
        this.setState({ remembered: !remembered })
    }
    handleSubmit(e) {
        e.preventDefault();
        const { username, password, remembered } = this.state;
        const { dispatch } = this.props;
        if (username && password) {
            /* send login action */
            dispatch(adminActions.login(username, password));
        }

        const cookies = new Cookies();
        cookies.set('admin_remembered', remembered, { path: '/' })
    }

    render() {
        const { isLoginFail } = this.props;
        return (
            <div className='login-form'>
                <Segment vertical className='login-page'>
                    <Container>
                        <Grid columns={1} textAlign='center' style={{ minHeight: 916 }} verticalAlign='middle'>
                            <Grid.Row>
                                <Grid.Column style={{ maxWidth: 450 }}>
                                    <Image src={logo_locofolio} verticalAlign='middle' />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column style={{ maxWidth: 450 }}>
                                    <Form size='large' onSubmit={this.handleSubmit}>
                                        <Form.Input
                                            label='E-mail'
                                            name="username"
                                            onChange={this.handleChange}
                                        />
                                        <Form.Input
                                            label='Password'
                                            type='password'
                                            name='password'
                                            onChange={this.handleChange}
                                        />
                                        <Form.Group>
                                            <Form.Checkbox label='Remember me' onChange={this.handleRememberMe} />
                                            <p className="login-forget-pwd">Forget password?</p>
                                        </Form.Group>
                                        <Button className='btn-login'>Login</Button>

                                    </Form>
                                    {isLoginFail ?
                                        <Message content='Login failed!' color='green' /> : undefined
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>

                <Segment className="footer" vertical>
                    <Grid columns={1}>
                        <Grid.Row style={{ paddingBottom: '0.35em', paddingTop: '0em', paddingLeft: '0em', paddingRight: '0em' }}>
                            <Grid.Column width={9} style={{ paddingBottom: '0em', paddingTop: '2.5em', paddingLeft: '3em', paddingRight: '0em' }}>
                                <Header className="white" style={{ fontSize: "19px" }} as='h4' content='Contact Us' />
                                <List className="contact-content white">
                                    <List.Item style={{ fontSize: '0.5em' }}><p>SALES@SPATIAL-TOPOLOGY.COM</p></List.Item>
                                    <List.Item style={{ fontSize: '0.5em' }}><p>(+886) 2-2509-9905</p></List.Item>
                                    <List.Item style={{ fontSize: '0.5em' }}><p>10695 台灣台北市大安區光復南路556號9樓</p></List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={7} style={{ paddingBottom: '0em', paddingTop: '5em', paddingLeft: '22.5em', paddingRight: '0em' }}>
                                <List horizontal>
                                    <List.Item><Icon name='facebook' className='white' style={{ fontSize: '2.85em' }} /></List.Item>
                                    <List.Item><Icon name='github' className='white' style={{ fontSize: '2.85em' }} /></List.Item>
                                    <List.Item><Icon name='twitter' className='white' style={{ fontSize: '2.85em' }} /></List.Item>
                                    <List.Item><Icon name='wechat' className='white' style={{ fontSize: '2.85em' }} /></List.Item>
                                </List>
                                <p>Spatial Topology Technology 司圖科技® 2020</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state,
        isLoginFail: state.app.isLoginFail,
    }
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export default connectedLoginPage;
export { connectedLoginPage as LoginPage };
