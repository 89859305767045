import React, { Component } from 'react'
import { Tab } from 'semantic-ui-react'
import TargetingGlobalMap from './TargetingGlobalMap'
import TargetingTrendChart from './Chart/TargetingTrendChart'
import TargetingPieChartAreaContainer from './Chart/TargetingPieChartArea';
import { PickedPOIDataTab } from './DataTab'

export class FigureTab extends Component {
    constructor(props) {
        super(props)
        this.panes = [{
            menuItem: 'Global',
            render: () =>
                <Tab.Pane className="segment-none-border" style={{ paddingLeft: "0px" }}>
                    <TargetingGlobalMap
                        markers={this.props.globalmap_data}
                    />
                </Tab.Pane>
        }, {
            menuItem: 'Pie',
            render: () =>
                <Tab.Pane className="segment-none-border" style={{ paddingLeft: "0px" }}>
                    <TargetingPieChartAreaContainer
                        time_step={this.props.time_step}
                        category_statistic={this.props.category_statistic}
                        brand_statistic={this.props.brand_statistic}
                    />
                </Tab.Pane>
        }, {
            menuItem: 'Trend',
            render: () =>
                <Tab.Pane className="segment-none-border" style={{ paddingLeft: "0px" }}>
                    <TargetingTrendChart
                        data={this.props.trendchart_data}
                        table_data={this.props.table_data}
                    />
                </Tab.Pane>
        }, {
            menuItem: 'POI',
            render: () =>
                <Tab.Pane className="segment-none-border" style={{ paddingLeft: "0px" }}>
                    <PickedPOIDataTab
                        isLoading={this.props.isLoading}
                        table_data={this.props.table_data}
                        timeFilter={this.props.timeFilter}
                        targetingTypeFilter={this.props.targetingTypeFilter}
                    />
                </Tab.Pane>
        }]
    }
    render() {
        return <Tab style={{ marginLeft: "10px" }} panes={this.panes} />
    }
}
export default FigureTab
