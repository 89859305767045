import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import SideMenuContainer from '../containers/HomePage/SideMenu';
import RouteMap from './RouteMap';

const style = {}

export class HomePage extends Component {
    render() {
        return (
            <div>
                <SideMenuContainer />
                <div style={style.main}>
                    <RouteMap />
                </div>
            </div>
        );
    }
}

style.main = {
    marginLeft: 210
}
