// piechart hex color
// export const piechart_colors = {
//   BRAND : ['#33dbf5', '#00cc98', '#4ebe56', '#83ab00', '#b19100', '#dc6900', '#ff0000'],
//   CATEGORY : ['#003f88', '#5d4194', '#963d94', '#c63a87', '#ea4371', '#ff5c56', '#ff8035', '#ffa600'],
//   STAYTIME : ['#b5eba4', '#66d9a5', '#00c5b3', '#00aec7', '#0094d8', '#0077dd', '#0055ce', '#3e23a8'],
//   VISITOR : ['#e0a236', '#f28e54', '#f47f75', '#e77a93', '#cd7ca9', '#ab81b5', '#8a85b4', '#7185a8'] 
// }
export const piechart_colors = {
  BRAND : ['#99E1E5FF', '#F8B595FF', '#F2C6B4FF', '#FBAFAFFF'],
  CATEGORY : ['#F8B595FF', '#9692AFFF', '#ACD1DFFF', '#F8CD80FF'],
  STAYTIME : ['#D49CA5FF', '#F67280FF', '#C06C84FF', '#865B7CFF'],
  VISITOR : ['#5C8D89FF', '#74B49BFF', '#CCD77EFF', '#45B7B8FF'],
  TAIL: ["#C9CACAFF"]
}

// trendchart line hex color
export const trendchart_colors = ["#5C8D89FF", "#74B49BFF", "#CCD77EFF", "#45B7B8FF", "#C9CACAFF"]