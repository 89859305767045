import React from 'react';
import About from '../components/About/index'
import Admin from '../components/Admin'
import Business from '../components/Business'

import POI from '../components/POI'
import POISetting from '../components/POI/Setting'
import POIOverall from '../components/POI/Overall'
import POIBrand from '../components/POI/Brand'
import POIBuilding from '../components/POI/Building'

import Targeting from '../components/Report/Targeting'
import TargetingOverview from '../containers/Report/Targeting/Overview'

export const routes = [
    // Overview
    {
        path: "/poi",
        main: () => <POI />
    },
    {
        path: "/administration",
        main: () => <Admin />
    },
    {
        name: "About",
        path: "/about",
        main: () => <About />
    },
    {
        path: "/business",
        main: () => <Business />
    },
    // POI Setting
    {
        name: "Overall",
        path: "/poi",
        exact: true,
        main: () => <POIOverall />
    },
    {
        name: "Setting",
        path: "/poi/POI",
        main: () => <POISetting />
    },
    {
        name: "Brand",
        path: "/poi/Brand",
        main: () => <POIBrand />
    },
    {
        name: "Building",
        path: "/poi/Building",
        main: () => <POIBuilding />
    },
    // Report/Targeting
    {
        name: "Overview",
        path: "/report/Targeting/Overview",
        main: () => <TargetingOverview />
    },
    {
        name: "Targeting",
        path: "/report/Targeting/POI",
        main: () => <Targeting />
    },
    {
        name: "Targeting",
        path: "/report/Targeting/Geo",
        main: () => <Targeting />
    },
    {
        name: "Targeting",
        path: "/report/Targeting/Device",
        main: () => <Targeting />
    },
    {
        name: "Targeting",
        path: "/report/Targeting/Custom",
        main: () => <Targeting />
    }
];

export default routes

