import { queryParamFetch } from '../util'
const ReportServices = {
    fetchOverviewInfo: (apikey, days) => fetchOverviewInfo(apikey, days),
    fetchDailyVisitors: (apikey, days) => fetchDailyVisitors(apikey, days),
    fetchByPOIList: (apikey, poi_list, days) => fetchByPOIList(apikey, poi_list, days),
    fetchStatistic: (parameters) => fetchStatistic(parameters),
    fetchCustomStatistic: (apikey, period) => fetchCustomStatistic(apikey, period),
}

export default ReportServices

const API_PREFIX = "/yanjing/api";
const generalOption = (action, data) => {
    return {
        method: action,
        credentials: 'include',
    }
};

const fetchByPOIList = ({ apikey, poi_list, days }) => {
    let requestOption = generalOption('POST');
    requestOption.headers = { 'Content-Type': 'application/json' }
    requestOption.body = JSON.stringify({ apikey, poi_list })
    return fetch(API_PREFIX + '/report/business/' + days, requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response)
            }
            return response.json()
        })
}

const fetchOverviewInfo = (apikey) => {
    let requestOption = generalOption('GET');
    return fetch(API_PREFIX + '/report/' + apikey + '/', requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response)
            }
            return response.json()
        })
}

const fetchDailyVisitors = (apikey, days) => {
    let requestOption = generalOption('GET');
    return fetch(API_PREFIX + '/report/visit/' + apikey + '/' + days, requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response)
            }
            return response.json()
        })
}

const fetchStatistic = ({ apikey, days, is_similar, time_step }) => {
    const requestOption = generalOption('POST');
    requestOption.headers = { 'Content-Type': 'application/json' };
    requestOption.body = {};
    requestOption.queryParams = { is_similar, time_step };
    return queryParamFetch(API_PREFIX + '/report/statistic/' + apikey + '/' + days, requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response)
            }
            return response.json()
        })
}

const fetchCustomStatistic = (apikey, period) => {
    const requestOption = generalOption('GET');
    return fetch(API_PREFIX + '/report/custom_poi/' + apikey + '/' + period, requestOption)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response)
            }
            return response.json()
        })
}
