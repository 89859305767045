import React, { Component } from 'react'
import moment from 'moment'
import 'semantic-ui-css/semantic.min.css'
import { connect } from 'react-redux'
import { fetchReportData, filterDateRange, setAdvancedFilter, switchTargetingType, setFilterApiKey } from '../../../../actions/Report'
import { time, distance, device } from '../../../../constant/filters'
import { PickedPOIFilter } from '../Filter'
import { FigureTab } from '../FigureTab'

class PickedPOITargeting extends Component {
    render() {
        const {
            role,
            username,
            isLoading,
            apikey,
            days,
            targetingType,
            startDate,
            endDate,
            POIList,
            targetingTypeFilter,
            timeFilter,
            businessList,
            fetchReportData,
            globalmap_data,
            trendchart_data,
            table_data,
            category_statistic,
            brand_statistic,
            switchTargetingType,
            onDateRangeChange,
            setFilterApiKey,
        } = this.props
        return (
            <div className="tap-content">
                <PickedPOIFilter
                    apikey={apikey}
                    username={username}
                    role={role}
                    days={days}
                    POIList={POIList}
                    startDate={startDate}
                    endDate={endDate}
                    businessList={businessList}
                    fetchReportData={(parameters) => fetchReportData(parameters)}
                    onDateRangeChange={(value) => onDateRangeChange(value)}
                    setFilterApiKey={(apikey) => setFilterApiKey(apikey)}
                />
                <FigureTab
                    globalmap_data={globalmap_data}
                    trendchart_data={trendchart_data}
                    category_statistic={category_statistic}
                    brand_statistic={brand_statistic}
                    isLoading={isLoading}
                    table_data={table_data}
                    timeFilter={timeFilter}
                    activeItem={targetingType}
                    targetingTypeFilter={targetingTypeFilter}
                    onMenuItemClick={(targetingType) => switchTargetingType(targetingType)}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    const data = state.info.report_data
    const days = state.filter.days
    const building = state.data.building
    const poiTable = state.data.poi
    const brandTable = state.data.brand
    const categoryTable = state.data.category

    let apikey = state.admin.apikey
    if (state.filter.apikey !== undefined) {
        apikey = state.filter.apikey
    }

    const POIList = []
    for (let i in state.admin.poi_list) {
        POIList.push({
            text: `${i}. ${state.data.poi[i].name}`,
            value: i.toString()
        })
    }

    // Helper functions to generate data needed for poiList, pieCharts, trendCharts
    const handleTrendChartData = () => {

        let poiSet = new Set()
        for (let day in data) {
            for (let poi in data[day]) {
                poiSet.add(poi);
            }
        }
        poiSet = [...poiSet];

        const result = []
        for (let i = 0; i < days; i++) {
            const date = moment().subtract(days - i, 'days').format('YYYY-MM-DD')
            let _result = { name: date };
            if (data[date] !== undefined) {
                poiSet.forEach((poi) => {
                    if (data[date][poi] !== undefined) {
                        _result[`${poi}`] = data[date][poi]['visit'];
                    } else {
                        _result[`${poi}`] = 0;
                    }
                })
            } else {
                poiSet.forEach((poi) => {
                    _result[`${poi}`] = 0;
                })
            }
            result.push(_result)
        }

        return result
    }

    const handleCategoryPieChartData = () => {

        const poiWithCategoryData = []
        const allCategoryIds = new Set()
        for (let day in data) {
            for (let poi in data[day]) {
                let poiData = data[day][poi]
                const brandId = poiData['brand'];
                const brandIdIndex = brandTable.findIndex((brand) => brand.id === parseInt(brandId, 10));
                if (typeof brandTable[brandIdIndex] === -1) {
                    console.log("[new] Brand_id could not be found: ", brandId);
                } else {
                    const categoryIdArr = (brandTable[brandIdIndex].category_ids).split(/[ ]+/);
                    poiWithCategoryData.push({
                        poi_id: poi,
                        name: poiData.poi_name,
                        brand_id: poiData.brand,
                        visit: poiData.visit,
                        staytime: poiData.avg_staytime * poiData.visit,
                        category_ids: categoryIdArr
                    });
                    categoryIdArr.forEach(categoryId => {
                        allCategoryIds.add(categoryId)
                    });

                }
            }
        }

        const result = []
        for (let key of [...allCategoryIds]) {
            let tmp_have_poi_ids = []
            for (let x in poiWithCategoryData) {
                const categoryIdArr = poiWithCategoryData[x].category_ids; // eg. ["STOG5-1", "STOG5-4", "STOG5-5",...]
                if (Object.values(categoryIdArr).indexOf(key) > -1) {
                    const check_poi = poiWithCategoryData[x].poi_id;
                    const same_poi_index = tmp_have_poi_ids.findIndex(function (element, index) {
                        return element.poi === check_poi
                    });

                    if (same_poi_index !== -1) {
                        tmp_have_poi_ids[same_poi_index]["staytime"] += poiWithCategoryData[x].staytime
                        tmp_have_poi_ids[same_poi_index]["visit"] += poiWithCategoryData[x].visit
                    } else {
                        tmp_have_poi_ids.push({
                            poi: poiWithCategoryData[x].poi_id,
                            name: poiWithCategoryData[x].name,
                            staytime: poiWithCategoryData[x].staytime,
                            visit: poiWithCategoryData[x].visit
                        });
                    }
                }
            }

            let sum_staytime = 0;
            let sum_visit = 0;
            for (let j in tmp_have_poi_ids) {
                sum_staytime += tmp_have_poi_ids[j]["staytime"];
                sum_visit += tmp_have_poi_ids[j]["visit"];
            }

            result.push({
                category_key: key,
                have_poi_ids: tmp_have_poi_ids,
                name: categoryTable[key],
                avg_staytime: sum_staytime / sum_visit,
                value: sum_visit
            });
        }

        return result
    }

    const handleBrandPieChartData = () => {

        let result = [];

        for (let day in data) {
            for (let poi in data[day]) {
                let poiData = data[day][poi]
                const brandId = poiData['brand'];
                const brandIdIndex = brandTable.findIndex((brand) => brand.id === parseInt(brandId, 10));
                const sameBrand_id_index = result.findIndex(function (element, index) {
                    return element.brand_id === brandId
                });

                if (sameBrand_id_index !== -1) { // 是重複的brand_id (因為會有多個poi是屬於同一種brand)
                    let same_poi_index = result[sameBrand_id_index]["have_poi_ids"].findIndex((element) => {
                        return element.poi === poi;
                    })
                    if (same_poi_index !== -1) { // found same poi id
                        let staytime = result[sameBrand_id_index]["have_poi_ids"][same_poi_index].staytime + poiData.avg_staytime * poiData.visit
                        let visit = result[sameBrand_id_index]["have_poi_ids"][same_poi_index].visit += poiData.visit
                        result[sameBrand_id_index]["have_poi_ids"][same_poi_index].staytime = staytime
                        result[sameBrand_id_index]["have_poi_ids"][same_poi_index].visit = visit
                    }
                    else {
                        result[sameBrand_id_index]["have_poi_ids"].push({
                            poi: poi,
                            name: poiData.poi_name,
                            staytime: poiData.avg_staytime * poiData.visit,
                            visit: poiData.visit
                        });
                    }

                    result[sameBrand_id_index]["avg_staytime"] = (result[sameBrand_id_index]["avg_staytime"] * result[sameBrand_id_index]["value"] + poiData.avg_staytime * poiData.visit) / (result[sameBrand_id_index]["value"] + poiData.visit);
                    result[sameBrand_id_index]["value"] = result[sameBrand_id_index]["value"] + poiData.visit;
                } else {
                    result.push({
                        brand_id: brandId,
                        have_poi_ids: [{
                            poi: poi,
                            name: poiData.poi_name,
                            staytime: poiData.avg_staytime * poiData.visit,
                            visit: poiData.visit
                        }],
                        name: (brandIdIndex === -1) ? "notFound" : brandTable[brandIdIndex].name,
                        avg_staytime: poiData.avg_staytime,
                        value: poiData.visit
                    });
                }
            }
        }
        return result;
    }

    const handleTableData = () => {
        const result = []

        for (let day in data) {
            for (let poi in data[day]) {
                let poiData = data[day][poi]
                const poi_index = poiTable.findIndex((poi_row) => poi_row.brand_id === poiData['brand'] && poi_row.name === poiData['poi_name'])
                const poi_id = poiTable[poi_index]['id']

                const _result = {}
                _result['avgStayTime'] = poiData['avg_staytime'].toFixed(2)
                _result['date'] = day
                _result['poi'] = `${poi_id}. ${poiData['poi_name']}`
                _result['poi_id'] = poi_id
                _result['totalVisitors'] = poiData['visit']
                _result['device'] = poiData['device']
                result.push(_result)
            }
        }
        return result
    }

    const handleMapData = () => {
        const result = []
        for (let i in building) {
            result.push({ name: building[i].name, lat: building[i].lat, lng: building[i].lng })
        }
        return result
    }

    const globalmap_data = handleMapData()
    const table_data = handleTableData()
    const trendchart_data = handleTrendChartData()
    const category_statistic = handleCategoryPieChartData()
    const brand_statistic = handleBrandPieChartData()

    return ({
        apikey,
        username: state.admin.username,
        role: state.admin.role,
        targetingType: state.filter.targetingType,
        days,
        globalmap_data,
        trendchart_data,
        table_data,
        category_statistic,
        brand_statistic,
        POIList,
        isLoading: state.app.isLoading,
        time_step: state.filter.advancedFilter.time,
        startDate: state.filter.startDate,
        endDate: state.filter.endDate,
        dateRange: state.filter.dateRange,
        targetingTypeFilter: state.targeting_table.targetingType,
        timeFilter: state.targeting_table.timeFilter,
        businessList: state.filter.businessList,
    })
}

const mapDispatchToProps = (dispatch) => ({
    fetchReportData: ({ apikey, poi_list, days }) => {
        dispatch(setAdvancedFilter({
            specificTarget: [],
            distance: distance.CLEAR,
            time: time.DATE,
            device: device.ALL,
        }))
        dispatch(fetchReportData({ apikey, poi_list, days }))
    },
    switchTargetingType: (targetingType) => {
        dispatch(switchTargetingType(targetingType))
    },
    onDateRangeChange: (value) => {
        dispatch(filterDateRange(value))
    },
    setFilterApiKey: (apikey) => {
        dispatch(setFilterApiKey(apikey))
    }
})

const PickedPOITargetingContainer = connect(mapStateToProps, mapDispatchToProps)(PickedPOITargeting)

export default PickedPOITargetingContainer