import React, { Component } from 'react'
import 'semantic-ui-css/semantic.min.css'
import { Divider } from 'semantic-ui-react'
import { connect } from 'react-redux'

import ReportTitle from '../ReportTitle'
import { targeting_type } from '../../../constant/filters'
import GeoTargeting from './Geo'
import DeviceTargeting from './Device'
import PickedPOITargeting from './PickedPOI'
import CustomReport from './CustomReport'

class Targeting extends Component {
    render() {
        const { targetingType } = this.props
        return (
            <div>
                <ReportTitle reportTitleType="Report" />
                <Divider />
                {(() => {
                    switch (targetingType) {
                        case targeting_type.DEVICE:
                            return <DeviceTargeting />
                        case targeting_type.POI:
                            return <PickedPOITargeting />
                        case targeting_type.CUSTOM:
                            return <CustomReport />
                        default:
                            return <GeoTargeting />
                    }
                })()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        targetingType: state.filter.targetingType,
    })
}

const TargetingContainer = connect(mapStateToProps)(Targeting)

export default TargetingContainer

export { TargetingContainer as Targeting }
