import { connect } from 'react-redux'
import { createPOI, modifyPOI, deletePOI } from '../../../actions/POI'
import POITable from '../../../components/POI/Setting/POITable'
import { compareAlphaNumeric } from '../../../util'

const mapStateToProps = (state) => {
    const _data = [];
    const _brandOption = [];
    const _buildingOption = [];
    const _categoryOption = [];
    const _floorOption = [];
    var _floorOptionKeys = [];

    // The references of the brands for `POI`
    let brandRef = [];
    // The references of the categories for `Brand`
    let categoryRef = state.info.category_data

    // Generate brandOption
    state.info.brand_data.map((brand, index) => {
        let category_ids = brand.category_ids.split(' ')
        let category_str = ''

        // Convert the categoryIDs to categoryName
        for (var key in category_ids) {
            category_str += categoryRef[category_ids[key]] + ", "
        }
        let option = {
            text: brand.name,
            value: brand.id,
        }
        // Convert the brandID to brandName
        brandRef[brand.id] = brand.name
        _categoryOption.push(category_str.slice(0,-2))
        _brandOption.push(option)
        return true
    })
    // Generate buildingOption
    state.info.building_data.map((building, index) => {
        let option = {
            text: building.address,
            value: building.id
        }
        _buildingOption.push(option)
        return true
    })

    // Generate floorOption
    state.info.poi_data.map((poi, index) => {
        if(_floorOptionKeys.includes(poi.floor)){
            return true;
        }
        else{
            let option = {
                text: poi.floor,
                value: poi.floor
            }
            _floorOptionKeys.push(poi.floor);
            _floorOption.push(option);
        }
        return true
    })
    _floorOption.sort(compareAlphaNumeric);

    // Generate POI Data
    state.info.poi_data.map((poi, index) => {
        let data = {
            id: poi.id,
            name: poi.name,
            brand: brandRef[poi.brand_id],  // Displayed on the `POI` table
            brand_id: poi.brand_id,
            building_id: poi.building_id,
            price: poi.price,
            floor: poi.floor,
            block: poi.block,
            counter: poi.counter
        }
        _data.push(data)
        return true
    })


    return ({
        data: _data,
        buildingOption: _buildingOption,
        brandOption: _brandOption,
        categoryOption: _categoryOption,
        floorOption: _floorOption
    })

}


const mapDispatchToProps = (dispatch) => ({
    createPOI: (data) => {
        dispatch(createPOI(data))
    },
    modifyPOI: (id, data) => {
        dispatch(modifyPOI(id, data))
    },
    deletePOI: (id) => {
        dispatch(deletePOI(id))
    }

})


export const POITableContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(POITable)

export default POITableContainer
