import React from 'react'
import { compose, withProps, withStateHandlers } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'

const TargetingGlobalMap = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCcnAdtkD6UlWN6Y2XWeZuxU_d8KQOdIx0&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withStateHandlers(() => ({
        isOpen: false,
        infoIndex: '0'
    }), {
        onToggleOpen: ({ isOpen }) => () => ({
            isOpen: !isOpen
        }),
        showInfo: ({ infoIndex, isOpen }) => (index) => ({
            isOpen: !isOpen,
            infoIndex: index
        })
    }),
    withScriptjs,
    withGoogleMap,
  )((props) =>
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{lat:25.105497, lng:121.597366}}
    >
    {
        props.markers.map((marker, index) => (
            <Marker
            key={index}
            position={{lat:marker.lat,lng:marker.lng}}
            onClick={() => {props.showInfo(index)}}
            >
                { props.infoIndex === index && props.isOpen  && <InfoWindow
                    onCloseClick={props.onToggleOpen}
                    options={{closeBoxURL: ``, enableEventPropagation: true}}
                    >
                        <div style={{backgroundColor:`white`,opacity:0.75,padding:`12px`}}>
                            <div style={{fontSize:`16px`,fontColor:`#08233B`}}>
                                {marker.name}
                            </div>
                        </div>
                    </InfoWindow>}
            </Marker>
        ))
    }
    </GoogleMap>
  )

export default TargetingGlobalMap
