import React, { Component } from 'react'
import 'semantic-ui-css/semantic.min.css'
import { connect } from 'react-redux'
import { Button, Dropdown, Label } from 'semantic-ui-react'

import { fetchPOICustomReport } from '../../../actions/Report'
import { CustomDataTab } from './DataTab'
import { date_range } from '../../../constant/filters'


class CustomTargeting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            period: 7
        }
        this.setPeriod = this.setPeriod.bind(this);
        this.updateStatistic = this.updateStatistic.bind(this)
    }
    setPeriod(event, { value }) {
        this.setState({ period: value })
    }
    updateStatistic() {
        let apikey = this.props.apikey
        let period = this.state.period
        if (apikey && period) {
            this.props.fetchStatistic(apikey, period)
        }
    }
    render() {
        const {
            isLoading,
            periodOption,
            table_data,
        } = this.props;
        return (
            <div>
                <Label>Date</Label>
                <Dropdown placeholder={'Select Period'} selection options={periodOption} onChange={this.setPeriod} />
                <Button onClick={this.updateStatistic}>Update</Button>
                <CustomDataTab
                    isLoading={isLoading}
                    table_data={table_data}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const report_data = state.custom_table.data

    let apikey = state.admin.apikey
    if (state.filter.apikey !== undefined) {
        apikey = state.filter.apikey
    }

    let periodOption = [
        {
            text: date_range.LASTWEEK,
            value: 7
        },
        {
            text: date_range.LASTMONTH,
            value: 30
        },
        {
            text: date_range.LAST3MONTHS,
            value: 90
        }
    ]

    return ({
        apikey: apikey,
        periodOption: periodOption,
        table_data: report_data,
        isLoading: state.app.isLoading,
    })
}

const mapDispatchToProps = (dispatch) => ({
    fetchStatistic: (apikey, period) => {
        dispatch(fetchPOICustomReport(apikey, period))
    }
})

const CustomTargetingContainer = connect(mapStateToProps, mapDispatchToProps)(CustomTargeting)
export default CustomTargetingContainer
