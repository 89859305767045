import React from 'react';
import '../../public/styles/ImageSlider.css'
import { Slide } from 'react-slideshow-image';
import overview_1 from '../../public/source/about_images/overview_1.png'
import overview_2 from '../../public/source/about_images/overview_2.png'
import overview_3 from '../../public/source/about_images/overview_3.png'
import report_1 from '../../public/source/about_images/report_1.png'
import report_2 from '../../public/source/about_images/report_2.png'
import report_3 from '../../public/source/about_images/report_3.png'
import report_4 from '../../public/source/about_images/report_4.png'
import report_5 from '../../public/source/about_images/report_5.png'

const overviewImgs = [
    {
        'img': overview_1, 'description': '(圖一) 訪客數量趨勢，將滑鼠移至曲線上可查看每天的數據', 'pie': false
    },
    {
        'img': overview_2, 'description': '(圖二) 新舊訪客數量與比例', 'pie': true
    },
    {
        'img': overview_3, 'description': '(圖三) 訪客所使用的手機作業系統數量與比例', 'pie': true
    }
]

const reportImgs = [
    {
        'img': report_1, 'description': '(圖一) 訪客數量比例，將滑鼠移至圖表上方可顯示該品牌名稱與參訪人數', 'pie': true
    },
    {
        'img': report_2, 'description': '(圖二) 對不同類別感興趣之訪客人數與比例', 'pie': true
    },
    {
        'img': report_3, 'description': '(圖三) 可選擇想看的類別，顯示類別中店家的平均停留秒數', 'pie': true
    },
    {
        'img': report_4, 'description': '(圖四) 可選擇想看的品牌，顯示品牌各分店的訪客人數', 'pie': true
    },
    {
        'img': report_5, 'description': '(圖五) 選擇至多10個POI並訪客數量趨勢', 'pie': false
    }

]

const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    arrows: true,
    autoplay: false,
}

const ImageSlider = ({ type }) => {
    let targetImgs = []
    switch (type) {
        case 'overview':
            targetImgs = overviewImgs
            break;
        case 'report':
            targetImgs = reportImgs
            break;
        default:
            return
    }
    return (
        <div>
            <Slide {...properties}>
                {
                    targetImgs.map((each, index) =>
                        <div className="each-slide">
                            <div className="image-container">
                                <div className={each.pie ? "about-pie-image" : "about-image"} key={index} style={{ 'backgroundImage': `url(${each.img})` }}>
                                </div>
                            </div>
                            <div className="image-text">
                                <span>{each.description}</span>
                            </div>
                        </div>
                    )
                }
            </Slide>
        </div>
    )
}

export default ImageSlider