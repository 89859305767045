//The File is supporting the reducer to `Information Components`
import actions from '../../constant/actions'

const overall = {
    total_poi: 0,
    total_building: 0,
    total_brand: 0,
    price: 0,
    location_leng: 0,
    category_leng: 0,
    poi_with_brand: 100,
    poi_with_build: 100
}
const initialInfo = {
    overall,
    brand_data: [],
    building_data: [],
    poi_data: [],
    category_data: [],
    location_data: [],
    report_data: []
};

const info = (state = initialInfo, action) => {
    switch (action.type) {
        case actions.UPDATE_INFO:
            return {
                ...state,
                overall: action.payload
            };
        case actions.UPDATE_BRAND:
            return {
                ...state,
                brand_data: action.payload
            }
        case actions.UPDATE_BUILDING:
            return {
                ...state,
                building_data: action.payload
            }
        case actions.UPDATE_CATEGORY:
            return {
                ...state,
                category_data: action.payload
            }
        case actions.UPDATE_POI:
            return {
                ...state,
                poi_data: action.payload
            }
        case actions.UPDATE_LOCATION:
            return {
                ...state,
                location_data: action.payload
            }
        case actions.UPDATE_REPORT_DATA:
            return {
                ...state,
                report_data: action.payload
            }
        default:
            return state;
    }
}

export default info
