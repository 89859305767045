import React, { Component } from 'react'
import BuildingTableContainer  from '../../../containers/POI/Building/BuildingTable'
import { Grid } from 'semantic-ui-react'
import "react-table/react-table.css"

class Building extends Component{
    render() {
        return (
            <Grid className="tap-content" columns={2}>
                <Grid.Column widescreen={16}>
                    <BuildingTableContainer />
                </Grid.Column>
            </Grid>
        )
    }
}
export default Building
