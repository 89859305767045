import React from 'react'
import btn_add_grey from '../../public/source/btn_sliceFile/btn-add-grey.png'
import btn_edit_green from '../../public/source/btn_sliceFile/btn-edit-green.png'
import btn_delete_green from '../../public/source/btn_sliceFile/btn-delet-green.png'
import btn_edit_grey from '../../public/source/btn_sliceFile/btn-edit-grey.png'
import btn_delete_grey from '../../public/source/btn_sliceFile/btn-delet-grey.png'
import btn_add_green from '../../public/source/btn_sliceFile/btn-add-green.png'

const BusinessBtnGroup = ({ checkAction, haveSelected }) => {
    const BtnGroup = haveSelected ?
        (
            <div style={{ marginBottom: "12px" }}>
                <span style={{ marginRight: "5px" }}><img src={btn_add_green} onClick={() => { checkAction('Create') }} alt="btn_add_green" /></span>
                <span style={{ marginRight: "5px" }}><img src={btn_edit_green} onClick={() => { checkAction('Modify') }} alt="btn_edit_green" /></span>
                <span style={{ marginRight: "5px" }}><img src={btn_delete_green} onClick={() => { checkAction('Delete') }} alt="btn_delete_green" /></span>
            </div>
        ) : (
            <div style={{ marginBottom: "12px" }}>
                <span style={{ marginRight: "5px" }}><img src={btn_add_green} onClick={() => { checkAction('Create') }} alt="btn_add_green" /></span>
                <span style={{ marginRight: "5px" }}><img src={btn_edit_grey} onClick={() => { checkAction('Modify') }} alt="btn_edit_grey" /></span>
                <span style={{ marginRight: "5px" }}><img src={btn_delete_grey} onClick={() => { checkAction('Delete') }} alt="btn_delete_grey" /></span>
            </div>
        )

    return BtnGroup
}

export default BusinessBtnGroup
