import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Icon, Modal } from 'semantic-ui-react'
import { Divider, List } from 'semantic-ui-react'

import 'semantic-ui-css/semantic.min.css'

import { PickPOIPickerContainer } from './PickPOIPicker'
import { businessActions } from '../../actions/Business'


class PickPOIModal extends Component {
    constructor(props) {
        super(props)
        this.state = {}

        // bind function
        this.handleClose = this.handleClose.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.isChange = this.isChange.bind(this)
        this.deleteListItem = this.deleteListItem.bind(this)
    }
    handleClose() {
        const { current } = this.props
        this.props.modifyPickPOIList(this.props.original_list)
        this.props.closePickModal(current.apikey)
    }
    handleSave() {
        const poi_list = this.props.poi_list
        const apikey = this.props.current.apikey
        const data = {
            poi_id_list: poi_list
        }
        if (this.isChange()) {
            this.props.setPickPOI(apikey, data)
        }
        this.props.updatePickPOIList(poi_list)
        this.props.closePickModal()
    }
    // check if state.poi_list === props.poi_list
    isChange() {
        const { poi_list, original_list } = this.props
        if (poi_list.length !== original_list.length) return true
        if (poi_list === [] || original_list === []) return false
        if (poi_list === original_list) return false

        for (var i = 0; i < poi_list.length; ++i) {
            if (poi_list[i] !== original_list[i]) return true
        }
        return false
    }
    deleteListItem(poi_id) {
        let poi_list = this.props.poi_list
        poi_list = poi_list.filter(
            (value, index, arr) => {
                return value !== poi_id
            }
        )
        poi_list.sort((a, b) => { return (a - b) })
        //dispatch action
        this.props.modifyPickPOIList(poi_list)
    }
    render() {
        const { isPickOpen, poi, poi_list } = this.props
        const is_change = this.isChange()

        const poi_element = poi_list.map(poi_id => {
            const item = poi.find(element => element.id === poi_id)
            return (
                <List.Item>
                    {item.name}
                    <Button icon onClick={() => { this.deleteListItem(poi_id) }} size='mini' circular attached='right'>
                        <Icon name='delete' />
                    </Button>
                </List.Item>
            )
        })

        return (
            <Modal
                open={isPickOpen}
                onClose={this.handleClose}
                size='large'
            >
                <Modal.Header>
                    Pick POI<br />
                </Modal.Header>
                <Modal.Content scrolling>
                    POI Selector:
                    <PickPOIPickerContainer />
                    <Divider />
                    Picked POI:
                    <List bulleted>
                        {poi_element}
                    </List>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary content='Save' disabled={!is_change} onClick={this.handleSave} />
                    <Button content='Close' onClick={this.handleClose} />
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    const { isPickOpen, poi_list, original_list } = state.business
    const current = state.business.selectBusiness
    const poi = state.data.poi

    return {
        current,
        isPickOpen,
        poi,
        poi_list,
        original_list
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPickPOI: (apikey, poi_list) => dispatch(businessActions.setPickPOI(apikey, poi_list)),
    getPickPOI: (apikey) => dispatch(businessActions.getPickPOI(apikey)),
    updatePickPOIList: (poi_list) => dispatch(businessActions.updatePickPOI(poi_list)),
    modifyPickPOIList: (poi_list) => dispatch(businessActions.modifyPickPOI(poi_list)),
    closePickModal: () => dispatch(businessActions.closePickModal())
})

const PickPOIContainer = connect(mapStateToProps, mapDispatchToProps)(PickPOIModal)
export { PickPOIContainer }
