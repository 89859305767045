import React from 'react'
import { Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ComposedChart } from 'recharts'
import { Dropdown } from 'semantic-ui-react'
import { trendchart_colors } from '../../../../constant/colors'
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

const CustomToolTip = (props) => {
    if (!props.active || props.payload === null) {
        return null
    }
    const newPayload = props.payload.filter(prop => prop.value > 0)

    return <DefaultTooltipContent {...props} payload={newPayload} />
}

class Chart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            'data': this.props.data,
            'selectedPOI': this.props.selectedPOI,
        }
    }

    lineColor = (poi_cnt) => {
        return trendchart_colors[poi_cnt % 5];
    }

    static getDerivedStateFromProps(props, state) {
        if ((props.selectedPOI !== state.selectedPOI || props.data !== state.data) && state.selectedPOI !== []) {
            let datatmp = props.data.map(item => { return { ...item, 'total': 0 } })
            const data = datatmp.map((item) => {
                let _total = 0
                props.selectedPOI.forEach((poi) => {
                    _total += item[poi]
                })
                item['total'] = _total
                item['average'] = (_total / props.selectedPOI.length).toFixed(2)
                return item
            })
            return { selectedPOI: props.selectedPOI, data: data }
        } else {
            return null
        }
    }

    render() {
        const { table_data } = this.props;
        const { data, selectedPOI } = this.state
        return (
            <ComposedChart width={1000} height={400} data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }} >
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip
                    content={<CustomToolTip />}
                />
                <Legend />
                <Line
                    type="monotone"
                    dataKey='total'
                    name='Total'
                    stroke="#000000"
                    strokeDasharray="2 2 2 2"
                />
                <Line
                    type="monotone"
                    dataKey='average'
                    name='Average'
                    stroke="#000000"
                />
                {
                    (selectedPOI[0] === undefined) ? null
                        :
                        selectedPOI.map((item, index) => {
                            let findObj = table_data.find(element => {
                                return String(element.poi_id) === item
                            });
                            return (
                                <Line
                                    type="monotone"
                                    dataKey={item}
                                    name={findObj ? findObj.poi : undefined}
                                    stroke={this.lineColor(index)}
                                    key={index}
                                    dot={false}

                                />
                            )
                        })
                }
            </ComposedChart>
        )

    }
}


class TargetingTrendChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPOI: [],
            maxSelected: false,
            data: this.props.data
        };
    }

    static getDerivedStateFromProps = (props, state) => {
        if (props.data !== state.data) {
            return ({ data: props.data })
        } else {
            return null
        }
    }

    handleDropdownSelection = (event, { value }) => {
        if (value.length >= 10) {
            this.setState({ maxSelected: true, selectedPOI: value })
        } else {
            this.setState({ maxSelected: false, selectedPOI: value });
        }
    }

    generateDropdownOption = (data, table_data) => {
        let tmp_stateOptions = new Set()

        let stateOptions = []
        // push all keys into tmp_stateOptions
        data.forEach(item => {
            let keys = Object.keys(item); // all poi_id
            keys.forEach(val => tmp_stateOptions.add(val));
        });

        tmp_stateOptions.forEach((val) => {
            // find poi name in table_data
            let findObj = table_data.find(element => {
                return String(element.poi_id) === val
            });

            if (findObj !== undefined) {
                let poi_name = findObj["poi"]; // parse string
                let tmp = { "key": poi_name, "value": val, "text": poi_name };
                stateOptions.push(tmp);
            }
        })
        return stateOptions;
    }

    selectedDropdownOption = (selectedPOI, table_data) => {
        let tmp_stateOptions = new Set(selectedPOI)
        let stateOptions = []

        tmp_stateOptions.forEach((val) => {
            // find poi name in table_data
            let findObj = table_data.find(element => {
                return String(element.poi_id) === val
            });

            if (findObj !== undefined) {
                let poi_name = findObj["poi"]; // parse string
                let tmp = { "key": poi_name, "value": val, "text": poi_name };
                stateOptions.push(tmp);
            }
        })

        return stateOptions;

    }

    render() {
        const { table_data } = this.props;
        const { maxSelected, selectedPOI, data } = this.state
        const stateOptions = this.generateDropdownOption(data, table_data)
        const selectedOption = this.selectedDropdownOption(selectedPOI, table_data)
        return (
            <div>
                <Dropdown
                    fluid multiple search selection
                    placeholder='Select up to 10 POIs'
                    onChange={this.handleDropdownSelection}
                    options={maxSelected ? selectedOption : stateOptions}
                    noResultsMessage={maxSelected ? 'You have selected 10 POIs.' : 'No results found'}
                />
                <Chart
                    data={data}
                    table_data={table_data}
                    selectedPOI={selectedPOI}
                />

            </div>
        )
    }
}

export default TargetingTrendChart

