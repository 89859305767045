import React, { Component } from 'react'
import 'semantic-ui-css/semantic.min.css'
import { connect } from 'react-redux'

import { fetchGeoList, fetchStatistic, filterDateRange, toggleSimilarBrand, setAdvancedFilter, switchTargetingType } from '../../../../actions/Report'
import { time, distance, device } from '../../../../constant/filters'
import { Filter } from '../Filter'
import { FigureTab } from '../FigureTab'
import { DeviceAdvancedFilter } from '../AdvancedFilter'
import { DeviceDataTab } from '../DataTab'
import { DeviceDataBuilder } from '../../../../util'

class DeviceTargeting extends Component {
    constructor(props) {
        super(props)
        this.props.fetchGeoList()
        this.props.fetchStatistic({
            apikey: this.props.apikey,
            days: this.props.days,
            is_similar: this.props.isSimilarBrandOpen
        })
    }
    render() {
        const {
            // app
            isLoading,
            // filters
            apikey,
            days,
            targetingType,
            time_step,
            startDate,
            endDate,
            geoList,
            isSimilarBrandOpen,
            targetingTypeFilter,
            timeFilter,
            // datas
            globalmap_data,
            piechart_data,
            trendchart_data,
            table_data,
            // actions
            fetchStatistic,
            fetchAdvancedStatistic,
            switchTargetingType,
            onDateRangeChange,
            onSimilarBrandClick
        } = this.props
        return(
            <div>
                <Filter
                    apikey={apikey}
                    days={days}
                    isSimilarBrandOpen={isSimilarBrandOpen}
                    startDate={startDate}
                    endDate={endDate}
                    fetchStatistic={(parameters) => fetchStatistic(parameters)}
                    onDateRangeChange={(value) => onDateRangeChange(value)}
                    onSimilarBrandClick={() => onSimilarBrandClick()}
                />
                <FigureTab
                    time_step={time_step}
                    globalmap_data={globalmap_data}
                    piechart_data={piechart_data}
                    trendchart_data={trendchart_data}
                />
                <DeviceAdvancedFilter
                    apikey={apikey}
                    is_similar={isSimilarBrandOpen}
                    days={days}
                    geoList={geoList}
                    fetchAdvancedStatistic={(parameters) => fetchAdvancedStatistic(parameters)}
                />
                <DeviceDataTab
                    isLoading={isLoading}
                    table_data={table_data}
                    timeFilter={timeFilter}
                    activeItem={targetingType}
                    targetingTypeFilter={targetingTypeFilter}
                    onMenuItemClick={(targetingType) => switchTargetingType(targetingType)}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // ----- VARIABLE DECLARATION ------
    const targetingType = state.filter.targetingType
    const days = state.filter.days
    const building = state.data.building
    const builder = new DeviceDataBuilder({
        rawData: state.targeting_table.data,
        targetingType,
        days,
        locationTable: state.targeting_table.location_table,
        ...state.filter.advancedFilter
    })
    // ------ SET API KEY -------
    let apikey = state.admin.apikey
    if (state.filter.apikey !== undefined) {
        apikey = state.filter.apikey
    }
    // ------ GENERATE DATA -------
    const piechart_data = {
        time_data: builder.generateTimeData(),
        targeting_data: builder.generateTargetingData()
    }
    const trendchart_data = builder.generateTrendChartData(days)

    const globalmap_data = []
    for (let i in building) {
        globalmap_data.push({name:building[i].name,lat:building[i].lat,lng:building[i].lng})
    }
    const table_data = builder.generateTableData()
    // --------------------
    return ({
        apikey: apikey,
        targetingType,
        days,
        globalmap_data,
        piechart_data,
        trendchart_data,
        table_data,
        isLoading: state.app.isLoading,
        geoList: state.filter.geoList[targetingType],
        time_step: state.filter.advancedFilter.time,
        startDate: state.filter.startDate,
        endDate: state.filter.endDate,
        dateRange: state.filter.dateRange,
        isSimilarBrandOpen: state.filter.isSimilarBrandOpen,
        targetingTypeFilter: state.targeting_table.targetingType,
        timeFilter: state.targeting_table.timeFilter
    })
}

const mapDispatchToProps = (dispatch) => ({
    fetchGeoList: () => {
        dispatch(fetchGeoList())
    },
    fetchStatistic: ({apikey, days, is_similar}) => {
        dispatch(setAdvancedFilter({
            specificTarget: [],
            distance: distance.CLEAR,
            time: time.DATE,
            device: device.ALL,
        }))
        dispatch(fetchStatistic({apikey ,days, is_similar, time_step: 'day'}))
    },
    fetchAdvancedStatistic: ({settings, ...params}) => {
        dispatch(setAdvancedFilter(settings))
        dispatch(fetchStatistic(params))
    },
    switchTargetingType: (targetingType) => {
        dispatch(switchTargetingType(targetingType))
    },
    onDateRangeChange: (value) => {
        dispatch(filterDateRange(value))
    },
    onSimilarBrandClick: () => {
        dispatch(toggleSimilarBrand())
    },
})

const DeviceTargetingContainer = connect(mapStateToProps, mapDispatchToProps)(DeviceTargeting)
export default DeviceTargetingContainer
