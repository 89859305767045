import DemographicsDataBuilder from './DemographicsDataBuilder'
import GeoDataBuilder from './GeoDataBuilder'
import DeviceDataBuilder from './DeviceDataBuilder'

export { DemographicsDataBuilder };
export { GeoDataBuilder };
export { DeviceDataBuilder };

export function queryParamFetch(url, options = {}) {
    if (options.queryParams) {
        url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(options.queryParams);
        delete options.queryParams;
    }
    return fetch(url, options);
}

function queryParams(params) {
    return Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
}

export function compareAlphaNumeric(a, b) {
    var regexp_alphabet = /[^a-zA-Z]/g;
    var regexp_numeric = /[^0-9]/g;
    var a_alphabet = a.text.replace(regexp_alphabet, "");
    var b_alphabet = b.text.replace(regexp_alphabet, "");
    if (a_alphabet === b_alphabet) {
        var a_numeric = parseInt(a.text.replace(regexp_numeric, ""), 10);
        var b_numeric = parseInt(b.text.replace(regexp_numeric, ""), 10);
        return a_numeric === b_numeric ? 0 : a_numeric > b_numeric ? 1 : -1;
    } else {
        return a_alphabet > b_alphabet ? 1 : -1;
    }
}