import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Header, Label, Button, Dropdown, Divider, Icon, Modal, Grid } from 'semantic-ui-react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import _ from 'lodash'
import 'semantic-ui-css/semantic.min.css'
import { fetchAdmin, createAdmin, modifyAdmin, deleteAdmin } from '../../actions/Admin'
import roles from '../../constant/roles'

export class Admin extends Component {
    constructor(props) {
        super(props)
        this.props.fetchAdmin()
        this.state = {
            isModalOpen: false,
            isSelected: false,
        }

        this.mail = ''
        this.name = ''
        this.password = ''
        this.check = ''
        this.apikey = ''
        this.role_ids = ''

        this.Create = this.Create.bind(this)
        this.Modify = this.Modify.bind(this)
        this.Delete = this.Delete.bind(this)

        this.handleChange = this.handleChange.bind(this)
        this.handleDropdownChange = this.handleDropdownChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }
    Create() {
        this.setState({isModalOpen: true, isSelected: false})
        this.mail = ''
        this.name = ''
        this.password = ''
        this.check = ''
        this.apikey = ''
        this.role_ids = roles.BUSINESS_ADMIN
    }
    Modify() {
        if (!this.state.isSelected) return
        this.setState({isModalOpen: true})
        this.password = ''
        this.check = ''
    }
    Delete() {
        if (!this.state.isSelected) return
        this.props.deleteAdmin(this.mail)
    }
    handleChange(e) {
        const { name, value } = e.target;
        this[name] = value
    }
    handleDropdownChange(data) {
        const { value } = data;
        this.apikey = value
    }
    handleSubmit() {
        if (this.check === this.password) {
            if(this.state.isSelected) { // modify
                this.props.modifyAdmin(this.mail, {
                    name: this.name,
                    password: this.password,
                    apikey: this.apikey,
                    role_ids: this.role_ids
                })
            } else { // create
                this.props.createAdmin(this.mail, {
                    name: this.name,
                    password: this.password,
                    apikey: this.apikey,
                    role_ids: this.role_ids
                })
            }
            this.handleClose()
        }
    }
    handleClose() {
        this.mail = ''
        this.name = ''
        this.password = ''
        this.apikey = ''
        this.roles_id = ''
        this.setState({isModalOpen: false, isSelected: false})

    }
    render() {
        const { data, businessOption } = this.props
        return <div>
            <Grid>
                <Grid.Column>
                    <Icon name='align justify' size='large'/>
                </Grid.Column>
                <Grid.Column>
                    <Header>Administration</Header>
                </Grid.Column>
            </Grid>
            <Divider horizontal />
            <Button.Group floated='right'>
                <Button id="create_admin" icon={<Icon name='plus'/>} attached='right' onClick={this.Create} />
                <Button id="modify_admin" icon={<Icon name='write'/>} attached='right' onClick={this.Modify} />
                <Button id="delete_admin" icon={<Icon name='trash'/>} attached='right' onClick={this.Delete} />
            </Button.Group>
            <Modal
                businessOption={businessOption}
                    open={this.state.isModalOpen}
                    onClose={this.handleClose}
                    size='large'
                >
                <Modal.Content>
                    <Form onSubmit={this.handleSubmit}>
                        <Header size='large'>Administration</Header>
                        <Form.Field>
                            <Label>Email</Label>
                            <Input placeholder='Email' type='text' name='mail' defaultValue={this.mail} onChange={this.handleChange} />
                        </Form.Field>
                        <Form.Field>
                            <Label>Username</Label>
                            <Input placeholder='Username' type='text' name='name' defaultValue={this.name} onChange={this.handleChange}/>
                        </Form.Field>
                        <Form.Field>
                            <Label>Password</Label>
                            <Input placeholder='Password' type='password' name='password' onChange={this.handleChange}/>
                        </Form.Field>
                        <Form.Field>
                            <Label>Password Confirmation</Label>
                            <Input placeholder='Type password again' type='password' name='check' onChange={this.handleChange}/>
                        </Form.Field>
                        <Form.Field>
                            <Label>Business</Label>
                            <Dropdown
                                    placeholder='Type keywords to select business'
                                    search selection
                                    options={businessOption}
                                    name='business'
                                    onChange={(event, data) => this.handleDropdownChange(data)}/>
                        </Form.Field>
                        <Form.Field>
                            <Input value={ this.state.isSelected? this.role_ids : roles.BUSINESS_ADMIN}></Input>
                        </Form.Field>
                        <Form.Field>
                            <Button onClick={this.handleClose}>Close</Button>
                            {
                              this.state.isSelected? <Button primary type='submit'>Modify</Button> : <Button primary type='submit'>Register</Button>
                            }
                        </Form.Field>
                    </Form>
                </Modal.Content>
            </Modal>
            <ReactTable
                getTrProps={(state, rowInfo) => {
                    return {
                        onClick: () => {
                            this.setState({isSelected: true})
                            const admin = data[rowInfo.index]
                            const businessIndex = businessOption.findIndex(x => x.text === admin.business)
                            this.mail = admin.mail
                            this.name = admin.name
                            this.apikey = businessOption[businessIndex].value
                            this.role_ids = admin.role_ids
                        }
                    }
                }}
                data={data}
                filterable
                columns={[
                    {
                        Header: 'Email',
                        accessor: 'mail',
                        filterMethod: (filter, row) =>
                        _.includes(row[filter.id], filter.value)
                    },
                    {
                        Header: 'Name',
                        accessor: 'name',
                        filterMethod: (filter, row) =>
                        _.includes(row[filter.id], filter.value)
                    },
                    {
                        Header: 'Business',
                        accessor: 'business',
                        filterMethod: (filter, row) =>
                        _.includes(row[filter.id], filter.value)
                    },
                    {
                        Header: 'Role',
                        accessor: 'role_ids',
                        filterMethod: (filter, row) =>
                        _.includes(row[filter.id], filter.value)
                    }
                ]}
            />
        </div>
    }
}

const mapStateToProps = (state) => {
    const business_data = state.data.business
    const admin_data = state.data.admin
    const data = []
    const businessOption = []
    if (business_data.length === 0 || admin_data.length === 0)
        return ({data, businessOption})
    business_data.map((business) => {
        businessOption.push({
            text: business.name,
            value: business.apikey
        })
        return businessOption
    })
    admin_data.map((admin) => {
        const businessIndex = businessOption.findIndex(x => x.value === admin.business_apikey)
        data.push({
          mail: admin.email,
          name: admin.name,
          business: businessOption[businessIndex].text,
          role_ids: admin.role_ids
        })
        return data
    })
    return ({
        data,
        businessOption
    })
}

const mapDispatchToProps = (dispatch) => ({
    fetchAdmin: () => dispatch(fetchAdmin()),
    createAdmin: (email, data) => dispatch(createAdmin(email, data)),
    modifyAdmin: (email, data) => dispatch(modifyAdmin(email, data)),
    deleteAdmin: (email) => dispatch(deleteAdmin(email))
})

const AdminContainer = connect(mapStateToProps, mapDispatchToProps)(Admin)

export default AdminContainer
