import React from "react";
import btn_next_green from '../public/source/btn_sliceFile/btn-next-green.png';
import btn_previous_green from '../public/source/btn_sliceFile/btn-previous-green.png';

const defaultButton = props => <button {...props}>{props.children}</button>;

export default class Pagination extends React.Component {
  constructor(props) {
    super();

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages)
      });
    }

    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage = (page) => {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });

    this.props.onPageChange(page - 1);
  }

  clearPageValue = (event) => {
    switch (event.key) {
      case ('Backspace'):
        if (this.inputField.value.length === 1) this.inputField.value = ''
        break;
      case ('Delete'):
        this.inputField.value = ''
        break;
      default:
        return
    }
  }

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const activePage = this.props.page + 1; // on which page

    return (
      <div className="Table__pagination">
        <div className="Table__prevPageWrapper">
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              if (activePage === 1) return;
              this.changePage(activePage - 1);
            }}
            disabled={activePage === 1}
          >
            <img src={btn_previous_green} alt="btn_previous_green" />
          </PageButtonComponent>
        </div>

        <div>Page &nbsp;
          <input
            className="pagination-input"
            ref={input => this.inputField = input}
            onFocus={() => this.inputField.value = ''}
            onBlur={() => this.inputField.value = activePage}
            onKeyDown={(event) => this.clearPageValue(event)}
            value={activePage}
            onChange={(event) => {
              const inputPage = Number(event.target.value);

              if (isNaN(inputPage)) return;
              this.changePage(inputPage);
            }}
          />
          &nbsp; of &nbsp; {this.props.pages}
        </div>

        <div className="Table__nextPageWrapper">
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              if (activePage === this.props.pages) return;
              this.changePage(activePage + 1);
            }}
            disabled={activePage === this.props.pages}
          >
            <img src={btn_next_green} alt="btn_next_green" />
          </PageButtonComponent>
        </div>
      </div>
    );
  }
}
