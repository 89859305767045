import React from 'react'
import { Divider, Grid, Dropdown, Dimmer, Loader } from 'semantic-ui-react'
import { ResponsiveContainer } from 'recharts'
import { PieChart, Pie, Tooltip, Line, XAxis, YAxis, CartesianGrid, Legend, ComposedChart } from 'recharts'
import 'semantic-ui-css/semantic.min.css'
import ReportTitle from '../ReportTitle'
import { geo } from '../../../constant/filters'
import roles from '../../../constant/roles'
import { prettyNumber } from '../../../util/DataBuilder'


const LoadingPage = () => (
    <Dimmer active>
        <Loader>Loading</Loader>
    </Dimmer>
)

class Overview extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            demographicsRange: geo.CITY
        }
    }
    getReportData = (event, data) => {
        this.props.setFilterApiKey(data.value)
        // Showing data for the last 30 days as default
        this.props.fetchOverview(data.value)
    }

    componentDidMount() {
        const { role, apikey } = this.props
        if (role !== roles.SUPERUSER) {
            this.props.setFilterApiKey(apikey)
            this.props.fetchOverview(apikey)
        }
    }

    render() {
        const {
            isLoading, role, username,
            pie_chart_data, trend_chart_data, // data (old
            overview_data, overview_trend, // renew
            startDate, endDate, apikey, businessList, // parameters
        } = this.props
        return (
            isLoading ? <LoadingPage /> :
                <div>
                    <ReportTitle reportTitleType="Overview" />
                    <Divider />
                    <div className="tap-content">
                        <Grid style={{ marginBottom: "5px" }} columns={2}>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <label className="filter-label">Business: </label>
                                    {role === roles.SUPERUSER ?
                                        <Dropdown
                                            placeholder='Choose Business'
                                            search selection
                                            options={businessList}
                                            name='business'
                                            value={apikey}
                                            onChange={this.getReportData}
                                        />
                                        :
                                        <span style={{ fontSize: "15px" }} > {username} </span>
                                    }
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <label className="filter-label">Date:</label>
                                    <span style={{ fontSize: "15px" }} > {startDate + " ~ " + endDate} (last month)</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>

                    <div className="tap-content">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <Statistics data={overview_data} />
                                </Grid.Column>
                                <Grid.Column width={13}>
                                    <div>
                                        <OverviewTrendChart data={overview_trend} />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div >

                    <div className="tap-content">
                        <PieChartSegment data={overview_data} />
                    </div>
                </div>
        )
    }
}

const Statistics = ({ data }) => (
    <div className="overview-statistic">
        <li>Total Visits: {prettyNumber(data.total_visits)} visits</li>
        <li>Returning Visitor: {prettyNumber(data.visitors.return)} visitors</li>
        <li>New Visitor: {prettyNumber(data.visitors.new)} visitors</li>
        <li>Unique Visitor: {prettyNumber(data.visitors.total)} visitors </li>
        <li>Avg Frequency: {prettyNumber(data.frequency)} visits per visitor</li>
        <li>Avg Stay Time: {prettyNumber(data.avg_stay_time)} seconds per visitor</li>
        <li>Bouncing Rate: {prettyNumber(data.bounce_rate)}</li>
    </div>
)

const PieChartSegment = ({ data }) => (
    <Grid columns='equal'>
        <Grid.Row>
            <Grid.Column>
                <OverviewPieChartVisitor
                    visitors={[{ name: 'New Visitor', value: data.visitors.new, fill: '#5C8D89FF' }, { name: 'Returning Vistor', value: data.visitors.return, fill: '#74B49BFF' }]}
                />
            </Grid.Column>
            <Grid.Column>
                <OverviewPieChartDevice
                    device={[{ name: 'iOS', value: data.device.ios, fill: "#CCD77EFF" }, { name: 'Android', value: data.device.android, fill: '#45B7B8FF' }]}
                />
            </Grid.Column>
        </Grid.Row>
    </Grid>
)


// piechart: visitors
const OverviewPieChartVisitor = ({ visitors }) => (
    <div className="gray-bg">
        <div>
            <div className="piechart-header">Visitors [visitor count]</div>
        </div>
        <ResponsiveContainer minWidth={600} minHeight={400}>
            <PieChart height={400}>
                <Pie
                    data={visitors}
                    cx={'50%'}
                    cy={200}
                    outerRadius={150}
                    dataKey='value'
                    isAnimationActive={false}
                    label={({ name, value, percent }) => { return `${name}: ${value} (${(percent * 100).toFixed(2)}%)` }}
                />
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    </div >
)

// piechart: device
const OverviewPieChartDevice = ({ device }) => (
    <div className="gray-bg">
        <div>
            <div className="piechart-header">Device [visitor count]</div>
        </div>
        <ResponsiveContainer minWidth={600} minHeight={400}>
            <PieChart height={400}>
                <Pie
                    data={device}
                    cx={'50%'}
                    cy={200}
                    outerRadius={150}
                    dataKey='value'
                    isAnimationActive={false}
                    label={({ name, value, percent }) => { return `${name}: ${value} (${(percent * 100).toFixed(2)}%)` }}
                />
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    </div >
)

const OverviewTrendChart = ({ data }) => {
    return (
        <ResponsiveContainer minWidth={700} minHeight={425} width='100%' height='100%' >
            <ComposedChart data={data} margin={{ top: 10, right: 20, left: 20, bottom: 10 }} >
                <XAxis dataKey="date" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Line
                    type="monotone"
                    dataKey={(data) => { return data.android_all + data.ios_all}}
                    name='Total visitors'
                    stroke='#333333'
                />
                <Line
                    type="monotone"
                    dataKey={(data) => { return data.android_new + data.ios_new}}
                    name='New visitors'
                    stroke="#5C8D89FF"
                />
                <Line
                    type="monotone"
                    dataKey={(data) => { return data.android_return + data.ios_return}}
                    name='Return visitors'
                    stroke="#CCD77EFF"
                />
            </ComposedChart>
        </ResponsiveContainer >
    )
}


export default Overview

