import React, { Component } from 'react';
import { Menu, Dimmer, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { targeting_type } from '../../constant/filters'
import roles from '../../constant/roles'
import '../../public/styles/General.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo_locofolio_white from '../../public/source/logo_locofolio-white.png'

class SideMenu extends Component {
    constructor(props) {
        super(props)
        this.state = { activeItem: '' }
    }

    componentDidMount() {
        this.props.initialUpdate({
            apikey: this.props.apikey,
            days: this.props.days,
            poi_list: this.props.POIList
        });

    }

    onReportMenuClick = (targeting_type, menuItem) => {
        this.props.resetFilter()
        this.props.switchTargetingType(targeting_type)
        this.setState({ activeItem: menuItem });
    }

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    handleLogout = () => {
        this.props.logout()
        window.location.reload()
    }
    render() {
        const { activeItem } = this.state
        const { role, isLoading } = this.props



        return (
            isLoading ?
                <LoadingPage />
                :
                <Menu className="sideMenu" fixed='left' inverted vertical style={{ width: 210, paddingBottom: '1em' }}>
                    <Menu.Item name="Yanjing">
                        <h3 className="username">STOMAP DEMO APP</h3>
                        <p className="business-username">STOMAP 通用品牌</p>
                        <p className="sideMenu-logout" onClick={this.handleLogout}> LOGOUT </p>
                    </Menu.Item>
                    {
                        (() => {
                            if (role === roles.SUPERUSER) { // for superuser
                                return <MainSideMenu
                                    onReportMenuClick={(targetingType, menuItem, apikey) => this.onReportMenuClick(targetingType, menuItem, apikey)}
                                    activeItem={activeItem}
                                    handleItemClick={this.handleItemClick}
                                />
                            } else { // for business user
                                return <AnonymousMainSideMenu
                                    onReportMenuClick={(targetingType, menuItem, apikey) => this.onReportMenuClick(targetingType, menuItem, apikey)}
                                    activeItem={activeItem}
                                    handleItemClick={this.handleItemClick}
                                />
                            }
                        })()
                    }
                    <img className="sideMenu-footer-logo" src={logo_locofolio_white} alt="logo_locofolio_white" />
                </Menu>
        )
    }
}
export default SideMenu


const LoadingPage = () => (
    <Dimmer active>
        <Loader>Loading</Loader>
    </Dimmer>
)

// For business
const AnonymousMainSideMenu = ({ onReportMenuClick, activeItem, handleItemClick }) => (
    <div>
        <Menu.Item
            as={Link} name="Overview" active={activeItem === 'Overview'}
            onClick={() => onReportMenuClick(targeting_type.CITY, "Overview")} to='/report/Targeting/Overview'
        >
            Overview
            <FontAwesomeIcon icon="home" pull="right" />
        </Menu.Item>
        <Menu.Item
            as={Link} name="Report" active={activeItem === 'Report'}
            onClick={() => onReportMenuClick(targeting_type.POI, "Report")} to='/report/Targeting/POI'
        >
            Report
            <FontAwesomeIcon icon="file-alt" pull="right" />
        </Menu.Item>
        <Menu.Item
            as={Link} name="About" active={activeItem === 'About'}
            onClick={handleItemClick} to='/about'
        >
            About
            <FontAwesomeIcon icon="info-circle" pull="right" />
        </Menu.Item>

    </div>
)

// For Superuser
const MainSideMenu = ({ onReportMenuClick, activeItem, handleItemClick }) => (
    <div>
        <Menu.Item
            as={Link} name="Overview" active={activeItem === 'Overview'}
            onClick={() => onReportMenuClick(targeting_type.CITY, "Overview")} to='/report/Targeting/Overview'
        >
            Overview
            <FontAwesomeIcon icon="home" pull="right" />
        </Menu.Item>
        <Menu.Item
            as={Link} name="Report" active={activeItem === 'Report'}
            onClick={() => onReportMenuClick(targeting_type.POI, "Report")} to='/report/Targeting/POI'
        >
            Report
            <FontAwesomeIcon icon="file-alt" pull="right" />
        </Menu.Item>
        <Menu.Item
            as={Link} name="Administration" active={activeItem === 'Administration'}
            onClick={handleItemClick} to='/administration'
        >
            Administration
            <FontAwesomeIcon icon="user-circle" pull="right" />
        </Menu.Item>
        <Menu.Item
            as={Link} name="Business" active={activeItem === 'Business'}
            onClick={handleItemClick} to='/Business'
        >
            Business
            <FontAwesomeIcon icon="briefcase" pull="right" />
        </Menu.Item>
        <Menu.Item
            as={Link} name="POI" active={activeItem === 'POI'}
            onClick={handleItemClick} to='/poi'
        >
            POI Setting
            <FontAwesomeIcon icon="map-marked-alt" pull="right" />
        </Menu.Item>
        <Menu.Item
            as={Link} name="Geo" active={activeItem === 'Geo'}
            onClick={() => onReportMenuClick(targeting_type.CITY, "Geo")} to='/report/Targeting/Geo'
        >
            Targeting Geo
        </Menu.Item>
        <Menu.Item
            as={Link} name="Device" active={activeItem === 'Device'}
            onClick={() => onReportMenuClick(targeting_type.DEVICE, "Device")} to='/report/Targeting/Device'
        >
            Targeting Device
        </Menu.Item>
        <Menu.Item
            as={Link} name="CustomPOI" active={activeItem === 'CustomPOI'}
            onClick={() => onReportMenuClick(targeting_type.CUSTOM, "CustomPOI")} to='/report/Targeting/Custom'
        >
            Custom POI
        </Menu.Item>
        <Menu.Item
            as={Link} name="About" active={activeItem === 'About'}
            onClick={handleItemClick} to='/about'
        >
            About
            <FontAwesomeIcon icon="info-circle" pull="right" />
        </Menu.Item>
    </div>
)