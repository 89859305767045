import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { filterDateRange, fetchOverview, setFilterApiKey } from '../../../actions/Report'
import Overview from '../../../components/Report/Targeting/Overview'

const mapStateToProps = (state) => {
    const { visitors_list, visits_list } = state.report_overview
    const { android_uniq, ios_uniq } = state.report_overview.overall
    const trend_chart_data = []
    let apikey = state.admin.apikey
    if (state.filter.apikey !== undefined) {
        apikey = state.filter.apikey // super user
    }

    /* for Overview Sum */
    const android_return_visitor = _.sumBy(visitors_list, 'android_return')
    const android_new_visitor = _.sumBy(visitors_list, 'android_new')
    const ios_new_visitor = _.sumBy(visitors_list, 'ios_new')
    const ios_return_visitor = _.sumBy(visitors_list, 'ios_return')
    const android_all_uniq_visitor = android_new_visitor + android_return_visitor;
    const ios_all_uniq_visitor = ios_new_visitor + ios_return_visitor;
    const all_uniq_visitor = android_all_uniq_visitor + ios_all_uniq_visitor
    const all_new_visitor = android_new_visitor + ios_new_visitor
    const all_return_visitor = android_return_visitor + ios_return_visitor
    const all_count = _.sumBy(visits_list, 'all')
    const all_visits = _.sumBy(visits_list, 'visit')
    const all_no_visits = _.sumBy(visits_list, 'no_visit')
    const all_stay_time = _.sumBy(visits_list, 'stay')
    const avg_visit = all_visits / all_uniq_visitor
    const bounce_rate = all_no_visits / all_count
    const avg_stay = all_stay_time / all_visits

    /* Sort Trend data by Date */
    const sort_visitors_list = _.sortBy(visitors_list, [function(o) { return new Date(o.date)}])
    const sort_visits_list = _.sortBy(visits_list, [function(o) { return new Date(o.date)}])

    const overview_data = {
        visitors: {
            total: all_uniq_visitor,
            new: all_new_visitor,
            return: all_return_visitor
        },
        total_visits: all_visits,
        frequency: avg_visit,
        bounce_rate: bounce_rate,
        avg_stay_time: avg_stay,
        device: {
            android: android_all_uniq_visitor,
            ios: ios_all_uniq_visitor
        }
    }


    return ({
        startDate: state.filter.startDate,
        endDate: state.filter.endDate,
        dateRange: state.filter.dateRange,
        days: state.filter.days,
        overview_data: overview_data,
        overview_trend: sort_visitors_list,
        apikey,
        isLoading: state.app.isLoading,
        businessList: state.filter.businessList,
        role: state.admin.role,
        username: state.admin.username
    })
}

const mapDispatchToProps = (dispatch) => ({
    onDateRangeChange: (value) => {
        dispatch(filterDateRange(value))
    },
    fetchOverview: (apikey) => {
        dispatch(fetchOverview(apikey))
    },
    setFilterApiKey: (apikey) => {
        dispatch(setFilterApiKey(apikey))
    }
})

export const OverviewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Overview)

export default OverviewContainer