import React, { Component } from 'react'
import { Label, Grid, Button, Dropdown, Segment, Checkbox } from 'semantic-ui-react'
import { date_range } from '../../../constant/filters'
import roles from '../../../constant/roles'


export class Filter extends Component {
    constructor(props) {
        super(props)
        this.onApplyClick = this.onApplyClick.bind(this)
    }
    onApplyClick() {
        this.props.fetchStatistic({
            apikey: this.props.apikey,
            days: this.props.days,
            is_similar: this.props.isSimilarBrandOpen,
            time_step: 'day'
        })
    }
    render() {
        const { startDate, endDate, dateRange, onSimilarBrandClick, onDateRangeChange } = this.props
        return <div>
            <Segment.Group horizontal>
                <Segment>
                    <Grid>
                        <Grid.Row columns={2} >
                            <Grid.Column>
                                <Label horizontal>Period: </Label>
                                {startDate + " ~ " + endDate}
                            </Grid.Column>
                            <Grid.Column>
                                <Dropdown placeholder="Date Range" selection options={rangeFilter}
                                    value={dateRange}
                                    onChange={(e, { name, value }) => onDateRangeChange(value)} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Checkbox label="Similar Brand" onClick={onSimilarBrandClick} />
                    <Button primary floated="right" onClick={this.onApplyClick}>Apply</Button>
                </Segment>
            </Segment.Group>
        </div>
    }
}

export class PickedPOIFilter extends Component {
    onApplyClick = () => {
        this.props.fetchReportData({
            apikey: this.props.apikey,
            poi_list: this.props.POIList,
            days: this.props.days,
        })
    }
    componentDidMount() {
        const { role, apikey } = this.props
        if (role !== roles.SUPERUSER) {
            this.props.setFilterApiKey(apikey)
        }
        this.props.onDateRangeChange(date_range.LASTMONTH)
    }
    render() {
        const {
            startDate, endDate, dateRange, role, businessList, apikey, username,
            onDateRangeChange, setFilterApiKey
        } = this.props
        return (
            <div className="tap-content">
                <Grid style={{ marginBottom: "5px" }} columns={3}>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <label className="filter-label">Business: </label>
                            {role === roles.SUPERUSER ?
                                <Dropdown
                                    placeholder='Choose Business'
                                    search selection
                                    options={businessList}
                                    name='business'
                                    value={apikey}
                                    onChange={(event, data) => setFilterApiKey(data.value)}
                                />
                                :
                                <span style={{ fontSize: "15px" }} > {username} </span>
                            }
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <label className="filter-label">Period: </label>
                            <Dropdown
                                placeholder="Select date range"
                                selection options={rangeFilter}
                                defaultValue={date_range.LASTMONTH}
                                value={dateRange}
                                onChange={(e, { name, value }) => onDateRangeChange(value)}
                            />
                            <span style={{ fontSize: "15px" }} >  {startDate + " ~ " + endDate} </span>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Button className="btn-apply" onClick={this.onApplyClick}>Apply</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

// Target Type Value Mapping
const rangeFilter = [
    {
        text: date_range.YESTERDAY,
        value: date_range.YESTERDAY
    },
    {
        text: date_range.LASTWEEK,
        value: date_range.LASTWEEK
    },
    {
        text: date_range.LASTMONTH,
        value: date_range.LASTMONTH
    },
    {
        text: date_range.LAST3MONTHS,
        value: date_range.LAST3MONTHS
    },
]
const deprecated_rangeFilter = [
    {
        text: date_range.LAST6MONTHS,
        value: date_range.LAST6MONTHS
    },
    {
        text: date_range.YEARTODATE,
        value: date_range.YEARTODATE
    },
    {
        text: date_range.LASTYEAR,
        value: date_range.LASTYEAR
    }
]