import { geo } from '../../constant/filters'
import actions from '../../constant/actions'

const overall = {
    avg_stay_time: 0,
    bounce_rate: 0.0,
    frequency: 0.0,
    device: {},
    visitors: {}
}
const initialInfo = {
    overall,
    business: [],
    daily_visitors_list: [],
    daily_new_visitors_list: [],
    daily_return_visitors_list: [],
    geoFilter: geo.COUNTRY,
}

const overview = (state = initialInfo, action) => {
    switch (action.type) {
        case actions.UPDATE_REPORT_OVERVIEW_INFO:
            return {
                ...state,
                overall: action.payload.overall,
                visitors_list: action.payload.visitor,
                visits_list:action.payload.stay
            }
        case actions.UPDATE_REPORT_OVERVIEW_DAILY_VISITORS:
            return {
                ...state,
                visitors_list: action.payload.visitor,
                visits_list: action.payload.stay
            }
        default:
            return state;
    }
}

export default overview
