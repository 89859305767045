import actions from '../../constant/actions'
import { targeting_type, targeting_type_header_accessor, time, time_header_accessor } from '../../constant/filters'

const initialTargetingTable = {
    targetingType: {
        Header: targeting_type_header_accessor[targeting_type.CITY].Header,
        accessor: targeting_type_header_accessor[targeting_type.CITY].accessor
    },
    timeFilter: {
        Header: time_header_accessor[time.DATE].Header,
        accessor: time_header_accessor[time.DATE].accessor
    },
    location_table: {},
    data: []

}

const targeting_table = (state = initialTargetingTable, action) => {
    switch(action.type) {
        case actions.SWITCH_TARGETING_TYPE:
            return {
                ...state,
                targetingType: {
                    Header: targeting_type_header_accessor[action.filter].Header,
                    accessor: targeting_type_header_accessor[action.filter].accessor
                }
            }
        case actions.RESET_FILTER:
            return {
                ...state,
                ...initialTargetingTable
            }
        case actions.UPDATE_LOCATION_TABLE:
            return {
                ...state,
                location_table: action.payload
            }
        case actions.UPDATE_TARGETING_TABLE:
            return {
                ...state,
                data: action.payload
            }
        case actions.SET_ADVANCED_FILTER:
            return {
                ...state,
                timeFilter: {
                    Header: time_header_accessor[action.filter.time].Header,
                    accessor: time_header_accessor[action.filter.time].accessor
                }
            }
        default:
            return state;
    }

}

export default targeting_table
