import { connect } from 'react-redux'
import IdleMonitor from '../../components/Home/IdleMonitor'
import { adminActions } from '../../actions/App'

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(adminActions.logout())
    }
})

const IdleMonitorContainer = connect(null, mapDispatchToProps)(IdleMonitor)

export default IdleMonitorContainer
