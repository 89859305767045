import actions from '../constant/actions';

const initialState = { isLogin: false, isLoading: false, isLoginFail: undefined, isLoginExpired: false };

export function app(state = initialState, action) {
    switch (action.type) {
        case actions.ADMIN_LOGIN_SUCCESS:
            return {
                ...state,
                isLogin: true,
                isLoginFail: false
            };
        case actions.REMEMBERED_ADMIN:
            return {
                ...state,
                isLogin: true,
                isLoginFail: false
            };
        case actions.ADMIN_LOGIN_FAILURE:
            return {
                ...state,
                isLogin: false,
                isLoginFail: true
            };
        case actions.ADMIN_SESSION_EXPIRED:
            return {
                ...state,
                isLogin: true,
                isLoginFail: false,
                isLoginExpired: true
            }
        case actions.ADMIN_LOGOUT:
            return {
                ...state,
                isLogin: false,
                isLoginExpired: false,
                isLoginFail: undefined
            };
        case actions.PAGE_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case actions.PAGE_LOADED:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}

export default app;
